import { Component, ViewChild, OnInit} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DisplayPreferenceDirective, AdminService, BaseSizingModule, Currency, JobSizing, ModulePreferenceService, Preference, Project, Job, SizingData, ProcessCondition, ProcessInput, OutputGridRow, OutputItem, OutputGridRowMessageItem, User, UserProfileService, TranslatePipe, UnitsService, PreferenceService, TranslationService, SizingOutput, OutputGrid, ProjectsJobsService, GetSizingJobRequest, EnumerationComponent, DocGen, SpecSheetItem, DocGenService, MessagesService, TiRequestModel, TiDocumentInfo, Enumeration, Message, UnitConvert, UnitsConverter } from "sizing-shared-lib";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IGenericChanges } from '../../../../sizing-shared-lib/src/lib/modules/generic.changes.interface';
import { EasiHeatGen4Service } from '../services/easiheatgen4.services';
import { easiHeatGen4DocGenService } from '../services/easiheatGen4DocGen.service';
import { UnitType } from '../models/UnitType';
import { EasiheatProcessConditionsComponent } from '../easiheat-process-conditions/easiheat-process-conditions.component';
import { EasiheatSizingResultsComponent } from '../easiheat-sizing-results/easiheat-sizing-results.component';
import { ModulePreferenceModel } from '../models/ModulePreferencesModel';
import { convertToDefaultValueInPageUnits } from '../unit-converter/unitConverter'
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

import * as _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';
import { EasiheatGen4Output, EasiHeatGen4Units } from '../models/output.model';
import { EHSizingDataInput } from '../models/sizingInput';
import { isNull, isUndefined, isNullOrUndefined } from 'util';
import { EasiHeatGen4PricingUnit, EasiHeatGen4PricingInputs, EHPricingOptions, EHPricingSummaryOutput, EHUnitPricingOutput } from '../models/pricingOptions.model';
import { setInitialEnumerationValuesForPricing } from '../initial-enumeration-values/initialEnumerationValues';
import { PricingOptionsFormGroup } from '../models/form-groups/PricingOptionsFormGroup';
import { HeaderDetails } from '../models/specSheetHeaderDetails.interface.component';
import { SpecificationSheetValuesCollection } from '../models/specificationSheetValuesCollection.model';
import { LocaleService} from 'node_modules/angular-l10n';
import { ViewportScroller } from "@angular/common";
import { EasiHeatGen4EnergySavingResultsUnit, EnergySavingOutput } from '../models/energySavingOutput.model';
import { EnergySavingInput } from '../models/energySavingInput.model';
import { EnergySavingInputUnit } from '../models/energySavingInputUnit.model';
const swal: SweetAlert = _swal as any;

@Component({
  selector: 'easiheatgen4',
  templateUrl: './easiheatgen4.component.html',
  styleUrls: ['./easiheatgen4.component.scss'],
  providers: [EasiHeatGen4Service, easiHeatGen4DocGenService]
})
export class EasiHeatGen4Component extends BaseSizingModule implements OnInit, IGenericChanges {


  @ViewChild("pressureRef", { static: false }) pressureRef: DisplayPreferenceDirective;
  @ViewChild("temperatureRef", { static: false }) temperatureRef: DisplayPreferenceDirective;
  @ViewChild("volumetricFlowRef", { static: false }) volumetricFlowRef: DisplayPreferenceDirective;
  @ViewChild("loadRef", { static: false }) loadRef: DisplayPreferenceDirective;
  @ViewChild("massFlowUnitsRef", { static: false }) massFlowUnitsRef: DisplayPreferenceDirective;
  @ViewChild("volumeUnitsRef", { static: false }) volumeUnitsRef: DisplayPreferenceDirective;
  @ViewChild("energyUnitsRef", { static: false }) energyUnitsRef: DisplayPreferenceDirective;
  @ViewChild("emissionUnitsRef", { static: false }) emissionUnitsRef: DisplayPreferenceDirective;

  @ViewChild(EasiheatProcessConditionsComponent, { static: true }) processConditionsComponent: EasiheatProcessConditionsComponent;
  @ViewChild(EasiheatSizingResultsComponent, { static: true }) resultsComponent: EasiheatSizingResultsComponent;

  @BlockUI() blockUI: NgBlockUI;
  private blockUiTimeOut;
  readonly moduleGroupId: number = 16;
  public userIsUkBased: boolean = false;
  //readonly moduleId: number = 5;
  readonly ukOpCoId: number[] = [41, 42, 43, 44];
  readonly moduleName: string = "EasiheatGen4";
  showResults: boolean = false;
  public sizingModuleForm: FormGroup;
  public user: User;
  userPrefs: Preference[];
  isEasiHeatSizingDone: boolean = false;
  areProjectsAndJobsLoaded: boolean = false;
  project: Project = new Project();
  job: Job = new Job();
  existingProjectInfo = new Project();
  paramsSubscription: Subscription;
  private jobParams: boolean = false;
  private isThisAJob: boolean = false;
  public loadedJobSizingData: SizingData;
  private sizedUnitTypes: string[] = [];  //to drive units option enumeration lists
  private userCondensate: boolean = false;
  private currentCondensateRemoval: string;
  private specificationSheetValuesCollection: SpecificationSheetValuesCollection;
  allPreferenceUnit: { name: string, pref: DisplayPreferenceDirective }[] = [];

  specSheetLanguage: string;
  lengthPref: string;
  lengthPrefUnit: string;
  weightPref: string;
  weightPrefUnit: string;

  private manufacturerCurrencyId: number = 0;
  private sellingCurrencyId: number = 0;
  private userSellingCurrencyId: number = 0;

  public manufacturerCurrency: Currency;
  public sellingCurrency: Currency;


  public sellingCurrencySymbol: string;
  public currencyConversionRate: number = 0;

  private initialGlobalVariance: string;
  private initialDHWGasketCode: string;
  private initialHTGGasketCode: string;
  private currentGlobalVariance: string;
  private currentGasketCode: string;
  private resizeUnitIndex: number = null;
  initialPricingOptions: EHPricingOptions[] = [];
  ehPricing: EasiHeatGen4PricingInputs;
  ehPricingOptions: EHPricingOptions;
  ehPricingOutputData: EHPricingSummaryOutput;
  leftOptions: EHPricingOptions[] = [];
  rightOptions: EHPricingOptions[] = [];
  leftPricingOptionsValues: PricingOptionsFormGroup;
  rightPricingOptionsValues: PricingOptionsFormGroup;


  // User prefs.
  private hideAllPricingUserPref: boolean = false;
  private hideManufactureCostsUserPref: boolean = false;
  // Flags to hide/show pricing details on the UI.
  public hideAllPricing: boolean = false;
  public hideManufactureCosts: boolean = false;
  public hideHEmodelFromSizing: boolean = false;
  public selectedUnitType = { dhw: true, htgcc: false } as UnitType;

  theFormGroup: FormGroup; // to drive GenericChangesGuard
  hasUnsavedDataChanges: boolean; // to drive GenericChangesGuard
  modulePreferences: Preference[];
  modPref: ModulePreferenceModel = new ModulePreferenceModel();
  ehSizingDataInput: EHSizingDataInput = new EHSizingDataInput();
  apiSizingResponse: EasiheatGen4Output;
  apiPricingResponse: EHUnitPricingOutput[];
  apiEnergySavingResponse: EnergySavingOutput;

  public isValid: boolean;
  public isTouched: boolean;
  public invalid = [];

  public docGen: DocGen;

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectsJobsService: ProjectsJobsService,
    private fb: FormBuilder,
    private preferenceService: PreferenceService,
    private userProfileService: UserProfileService,
    private adminService: AdminService,
    private unitsService: UnitsService, // to bedziesz uzywal do konvertowania tak jak w gen3 linia 5013
    private modulePreferenceService: ModulePreferenceService,
    private sizingApi: EasiHeatGen4Service,
    private translatePipe: TranslatePipe,
    private translationService: TranslationService,
    private easiheatDocGenService: easiHeatGen4DocGenService,
    private docGenService: DocGenService,
    private messagesService: MessagesService,
    private localeService: LocaleService,
    private cdRef: ChangeDetectorRef,
    private scroller: ViewportScroller,
  ) {
    super();

    this.ehPricing = new EasiHeatGen4PricingInputs();
    this.ehPricingOptions = new EHPricingOptions();
    this.ehPricingOutputData = new EHPricingSummaryOutput();

  }

  ngOnInit(): void {
    this.messagesService.clearMessages();

    //Set properties required for Saving Correctly Project and Jobs
    this.jobStatusId = 1;
    this.moduleId = 13;
    this.productName = "EasiHeatGen4";


    //Create Sizing Module Form
    this.sizingModuleForm = this.fb.group({
      sizingResults: this.resultsComponent.createFormGroup(),
      processConditions: this.processConditionsComponent.createFormGroup(),
      specsheet: [null],
    });

    this.sizingModuleForm.get('specsheet').valueChanges.subscribe(val => this.updateSpecSheetHeaderDetailsChanged());

    this.createModulePreferencesModel();

    //Get User Preferences
    this.preferenceService.getUserPreferences().subscribe((prefs: Array<Preference>) => {

      this.userPrefs = prefs;
      if (this.pressureRef.preference.unitName !== this.userPrefs.find(m => m.name === "PressureUnit").unitName ||
        this.temperatureRef.preference.unitName !== this.userPrefs.find(m => m.name === "TemperatureUnit").unitName ||
        this.volumetricFlowRef.preference.unitName !== this.userPrefs.find(m => m.name === "VolumetricFlowUnit").unitName ||
        this.loadRef.preference.unitName !== this.userPrefs.find(m => m.name === "LoadUnit").unitName) {
        window.location.reload();
      }

      this.specSheetLanguage = this.userPrefs.find(m => m.name === "SpecLanguage").value;
      this.lengthPref = this.userPrefs.find(m => m.name === "LengthUnit").value;
      this.lengthPrefUnit = this.userPrefs.find(m => m.name === "LengthUnit").unitName;
      this.weightPref = this.userPrefs.find(m => m.name === "WeightUnit").value;
      this.weightPrefUnit = this.userPrefs.find(m => m.name === "WeightUnit").unitName;


      convertToDefaultValueInPageUnits("inletTemperature", 10, this.temperatureRef.preference.value, this.unitsService, this.sizingModuleForm);
      convertToDefaultValueInPageUnits("outletTemperature", 65, this.temperatureRef.preference.value, this.unitsService, this.sizingModuleForm);
      this.allPreferenceUnit = [{ name: 'pressure', pref: this.pressureRef }, { name: 'temperature', pref: this.temperatureRef }, { name: 'volumetricFlow', pref: this.volumetricFlowRef }, { name: 'load', pref: this.loadRef }];

    }, error => null,
      () => this.handleLoadingJob()
    );

    // Get the currency data.
    this.adminService.getCurrencyData().subscribe(data => {
      if (data && data.length > 0) {
        this.sellingCurrency = data.find(c => c.id === +this.modPref.EHgen4_SellingCurrency);
        this.manufacturerCurrency = data.find(c => c.id === +this.modPref.EHgen4_ManufacturerCurrency);

        this.processConditionsComponent.setCurrencySymbol(data.find(s => s.id === +this.modPref.EHgen4_SellingCurrency).symbol);
        this.resultsComponent.setCurrencySymbol(data.find(s => s.id === +this.modPref.EHgen4_SellingCurrency).symbol);

        if (this.sellingCurrency && this.manufacturerCurrency) {
          this.currencyConversionRate = +((this.manufacturerCurrency.rateToGbp * (1 / this.sellingCurrency.rateToGbp)).toFixed(5));
        }
      }

      this.sizingModuleForm.markAsPristine();
      this.sizingModuleForm.markAsUntouched();
    });

    this.adminService.getUserModuleAccessDataByModuleGroupId(this.moduleGroupId).subscribe((accessLevel: number) => {
      if (accessLevel) {
        switch (accessLevel) {
          // Two stars
          case 0.66:
            // Only show the sales prices if preference is set to?
            this.hideManufactureCosts = true;
            if (this.modPref.EHgen4_HideAllPricingUserPref) {
              this.hideAllPricing = true;
            }
            break;

          // Three stars
          case 0.99:
            // Show manufacturers cost only if preference is set to?
            if (this.modPref.EHgen4_HideManufactureCostsUserPref) {
              this.hideManufactureCosts = true;
            }
            // Show sales prices only if preference is set to?
            if (this.modPref.EHgen4_HideAllPricingUserPref) {
              this.hideAllPricing = true;
            }
            break;

          // One/No stars
          default:
            // Hide all the pricing informations.
            this.hideAllPricing = true;
            this.hideManufactureCosts = true;
            break;
        }
      }
    });

    this.userProfileService.getUserDetails().subscribe(user => {
      this.user = user;
      this.userIsUkBased = this.user && this.ukOpCoId.indexOf(this.user.operatingCompanyId) > -1;
      this.showJobExportBtn = this.userIsUkBased;  //bug fix #8302 - this is temp only fix until CRM is completed.
      this.sizingModuleForm.get('processConditions.installCountryGateway').patchValue(user.countryCode.toUpperCase(), { emitEvent: false });
    });




    this.theFormGroup = this.sizingModuleForm; // to drive GenericChangesGuard

    setInitialEnumerationValuesForPricing(this.initialPricingOptions, this.translationService);

    this.initialDHWGasketCode = this.setGasketCode(this.translationService.displayGroup.enumerations.find(mp => mp.opCoOverride === true && mp.enumerationName == 'EH_DHW_SC_GasketMaterial').enumerationDefinitions[0].defaultText);
    this.initialHTGGasketCode = this.setGasketCode(this.translationService.displayGroup.enumerations.find(mp => mp.opCoOverride === true && mp.enumerationName == 'EH_HTG_SC_GasketMaterial').enumerationDefinitions[0].defaultText);
    this.initialGlobalVariance = this.translationService.displayGroup.enumerations.find(mp => mp.opCoOverride === true && mp.enumerationName == 'EH_GEN4_GlobalVariance').enumerationDefinitions[0].value;
    //this.handleLoadingJob();
  }

  handleLoadingJob() {

    this.activatedRoute.params
    // subscribe to router event
    this.paramsSubscription = this.activatedRoute.params.subscribe((params: Params) => {

      let projectId = params['projectId'];
      let jobId = params['jobId'];
      console.log(`projectId=${projectId}, jobId=${jobId}`);
      if (!!projectId && !!jobId) {

        this.jobParams = true;
        this.projectId = projectId;
        this.jobId = jobId;

        // first get data, might be navigated link from P&Js or bookmark?
        this.projectsJobsService.getProjectsAndJobs().subscribe(() => {
          // Inform the view that areProjectsAndJobs are now loaded.
          this.areProjectsAndJobsLoaded = true;
        });

        //ToDo: Write slices and chain async call for single dedicated calls to GetProjectById, GetJobById or GetProjetAndSingleJobByIds all in one server/SP call etc.
        // subject subscription, update as service data has changed (probably changed in memory)
        this.projectsJobsService.projectJobsChange.subscribe(() => {
          //Subject has Updated Projects And Jobs Data.

          var notFound = false;
          if (this.jobParams) {
            this.project = this.projectsJobsService.projectsJobs.projects.find(p => p.id === projectId);
          } else return;

          if (!this.project) {
            // projectId not found
            // ToDo: infrom user
            notFound = true;
          }
          else {
            this.job = this.project.jobs.find(j => j.id === jobId);
          }
          if (!this.job) {
            // projectId not found
            // ToDo: infrom user
            notFound = true;
          }

          if (notFound) {
            // Simple popup message box
            let trans_Job_Not_Found = this.translatePipe.transform('SELECTED_JOB_WAS_NOT_FOUND_MESSAGE', true);
            let trans_Error = this.translatePipe.transform('ERROR', true);

            swal({
              title: trans_Error + ':',
              text: trans_Job_Not_Found,
              icon: "error",
              dangerMode: true,
              //buttons: ['Ok', 'Cancel']
            }).then((okbuttoncClicked?: boolean) => {

              console.info("Ok clicked...");

              // The parameter can also enter as null
              const returnVal = !(okbuttoncClicked === null);

            });
            return;
          }

          this.projectName = this.project.name;
          this.jobName = this.job.name;

          console.log(`Job loaded! ${this.project.name} - ${this.job.name}`);

          let request = new GetSizingJobRequest();
          request.jobId = this.job.id;
          this.productName = this.job.productName;
          this.moduleId = this.job.moduleId;
          this.jobStatusId = this.job.jobStatusId;

          // ToDo: Get the JobSizing XML or as part of the previous call, single round trip.
          this.projectsJobsService.getJobSizing(request).subscribe((response: SizingData) => {

            this.loadedJobSizingData = response;

            // This is required to prevent any re-validation and re-calculation when a job is loading.
            this.processConditionsComponent.isLoadingJob = true;
            this.resultsComponent.isLoadingJob = true;
           
            this.jobParams = false;

            this.loadJob();

            this.sizingModuleForm.markAsPristine();
            this.sizingModuleForm.markAsUntouched();

          });
        });
      }
    });
  }

  public findInvalidControls() {
    this.invalid = [];
    const controls = this.sizingModuleForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalid.push(name);
      }
    }
    return this.invalid;
  }


  loadJob() {
    try {

      if (this.loadedJobSizingData != null) {
        this.isThisAJob = true; // this prevents ProcessConditionValidation (which is kicking off on job load as soon as all process conditions fields are populated) from setting this.isEasiHeatSizingDone to false so the UI sizing results,pricing,options,notes panel will remain open (if there is any data saved for those).
        
        // Load unit preferences.
        let unitPreferences = this.loadedJobSizingData.processConditions[0].unitPreferences;
        this.preferenceService.addSizingUnitPreference(unitPreferences.find(u => u.name === this.pressureRef.preference.name), "PressureUnits", "PRESSURE", this.moduleGroupId);
        this.preferenceService.addSizingUnitPreference(unitPreferences.find(u => u.name === this.temperatureRef.preference.name), "TemperatureUnits", "TEMPERATURE", this.moduleGroupId);
        this.preferenceService.addSizingUnitPreference(unitPreferences.find(u => u.name === this.volumetricFlowRef.preference.name), "VolumetricFlowUnits", "VOLUMETRIC_FLOW", this.moduleGroupId);
        this.preferenceService.addSizingUnitPreference(unitPreferences.find(u => u.name === this.loadRef.preference.name), "LoadUnits", "LOAD", this.moduleGroupId);
        this.preferenceService.addSizingUnitPreference(unitPreferences.find(u => u.name === this.volumeUnitsRef.preference.name), "BoilerHouseVolumeUnits", "VOLUME", this.moduleGroupId);
        this.preferenceService.addSizingUnitPreference(unitPreferences.find(u => u.name === this.energyUnitsRef.preference.name), "BoilerHouseEnergyUnits", "ENERGY", this.moduleGroupId);
        this.preferenceService.addSizingUnitPreference(unitPreferences.find(u => u.name === this.emissionUnitsRef.preference.name), "BoilerHouseEmissionUnits", "CO2_EMISSIONS", this.moduleGroupId);
        this.processConditionsComponent.pressure_Drop_Unit = unitPreferences.find(u => u.name === "MaxPressureDropUnit").unitName;
        this.processConditionsComponent.pressure_Drop_Enumeration_Name = unitPreferences.find(u => u.name === "MaxPressureDropUnit").masterTextKey;

        // load process conditions
        let processConditions = this.loadedJobSizingData.processConditions[0];
        this.currentGlobalVariance = processConditions.processInputs.find(m => m.name === "Global Variance").value;
        this.resultsComponent.globalVariance = processConditions.processInputs.find(m => m.name === "Global Variance").value;
        this.selectedUnitType.dhw = processConditions.processInputs.find(m => m.name === "Application Type").value == 'dhw' ? true : false;
        this.selectedUnitType.htgcc = processConditions.processInputs.find(m => m.name === "Application Type").value == 'htgcc' ? true : false;
        this.processConditionsComponent.unitType.dhw = processConditions.processInputs.find(m => m.name === "Application Type").value == 'dhw' ? true : false;
        this.processConditionsComponent.unitType.htgcc = processConditions.processInputs.find(m => m.name === "Application Type").value == 'htgcc' ? true : false;
        this.sizingModuleForm.get('processConditions.inletPressure').patchValue(parseFloat(processConditions.processInputs.find(m => m.name === "Inlet Pressure").value));
        this.sizingModuleForm.get('processConditions.totalBackPressure').patchValue(parseFloat(processConditions.processInputs.find(m => m.name === "Total Back Pressure").value));
        this.sizingModuleForm.get('processConditions.inletTemperature').patchValue(parseFloat(processConditions.processInputs.find(m => m.name === "Inlet Temperature").value));
        this.sizingModuleForm.get('processConditions.outletTemperature').patchValue(parseFloat(processConditions.processInputs.find(m => m.name === "Outlet Temperature").value));
        this.sizingModuleForm.get('processConditions.waterFlowRate').patchValue(parseFloat(processConditions.processInputs.find(m => m.name === "Water Flow Rate").value), { emitEvent: false });
        this.sizingModuleForm.get('processConditions.load').patchValue(parseFloat(processConditions.processInputs.find(m => m.name === "Load").value), { emitEvent: false });
        this.sizingModuleForm.get('processConditions.maximumPressureDrop').patchValue(processConditions.processInputs.find(m => m.name === "Maximum Pressure Drop").value);
        this.sizingModuleForm.get('processConditions.globalVariance').patchValue(processConditions.processInputs.find(m => m.name === "Global Variance").value);
        this.sizingModuleForm.get('processConditions.installCountryGateway').patchValue(processConditions.processInputs.find(m => m.name === "Install Country Gateway").value);

        //create getwayInfo object
        this.processConditionsComponent.gatewayInfo.countryCode = processConditions.processInputs.find(m => m.name === "Gateway countryCode").value;
        this.processConditionsComponent.gatewayInfo.isGatewayAvailable = JSON.parse(processConditions.processInputs.find(m => m.name === "Gateway isGatewayAvailable").value);
        this.processConditionsComponent.gatewayInfo.modelCode = processConditions.processInputs.find(m => m.name === "Gateway modelCode").value;
        this.processConditionsComponent.gatewayInfo.nomenclatureCode = processConditions.processInputs.find(m => m.name === "Gateway nomenclatureCode").value; 

        if (JSON.parse(processConditions.processInputs.find(m => m.name === "Motive Inlet Pressure Visible").value) == true) {

          this.processConditionsComponent.motiveInletPressureAvailable = true;
          if (processConditions.processInputs.find(m => m.name === "Motive Inlet Pressure").value !== null && processConditions.processInputs.find(m => m.name === "Motive Inlet Pressure").value !== "") {
            this.sizingModuleForm.get('processConditions.motiveInletPressure').patchValue(+processConditions.processInputs.find(m => m.name === "Motive Inlet Pressure").value);
          }
        }


        if (JSON.parse(processConditions.processInputs.find(m => m.name === "Allow High Design Pressure Visible").value) == true) {

          this.processConditionsComponent.allowHighDesignPressure = true;
          if (processConditions.processInputs.find(m => m.name === "Allow High Design Pressure").value !== null && processConditions.processInputs.find(m => m.name === "Allow High Design Pressure").value !== "") {
            this.sizingModuleForm.get('processConditions.designPressure12Bar').patchValue(JSON.parse(processConditions.processInputs.find(m => m.name === "Allow High Design Pressure").value));
          }
        }

        if (JSON.parse(processConditions.processInputs.find(m => m.name === "CV Split Range Visible").value) == true) {

          this.processConditionsComponent.cvSplitRangeAvailable = true;
          this.sizingModuleForm.get('processConditions.sizeCvSplitRangeOnly').patchValue(JSON.parse(processConditions.processInputs.find(m => m.name === "CV Split Range Only").value));
        }

        //ENERGY SAVING (if calcs were performed)
        if (JSON.parse(processConditions.processInputs.find(m => m.name === "Perform Energy Saving Calc").value) == true) {

          const hoursOfOperationControl = this.sizingModuleForm.get('processConditions.hoursOfOperation');
          const diversityFactorControl = this.sizingModuleForm.get('processConditions.diversityFactor');
          const costOfCarbonControl = this.sizingModuleForm.get('processConditions.costOfCarbon');
          const costOfCarbonUnitControl = this.sizingModuleForm.get('processConditions.costOfCarbonUnit');
          const energyUnitCostControl = this.sizingModuleForm.get('processConditions.energyUnitCost');
          const energyUnitCostUnitControl = this.sizingModuleForm.get('processConditions.energyUnitCostUnit');
          const waterUnitCostControl = this.sizingModuleForm.get('processConditions.waterUnitCost');
          const waterUnitCostUnitControl = this.sizingModuleForm.get('processConditions.waterUnitCostUnit');
          const waterTreatmentUnitCostControl = this.sizingModuleForm.get('processConditions.waterTreatmentUnitCost');
          const waterTreatmentUnitCostUnitControl = this.sizingModuleForm.get('processConditions.waterTreatmentUnitCostUnit');
          const hotwellTemperatureControl = this.sizingModuleForm.get('processConditions.hotwellTemperature');
          const hotwellTemperatureUnitControl = this.sizingModuleForm.get('processConditions.hotwellTemperatureUnit');
          const boilerEfficiencyControl = this.sizingModuleForm.get('processConditions.boilerEfficiency');
          const boilerPressureControl = this.sizingModuleForm.get('processConditions.boilerPressure');

          if (hoursOfOperationControl && hoursOfOperationControl.disabled) { hoursOfOperationControl.enable({ onlySelf: true, emitEvent: false }); }
          if (diversityFactorControl && diversityFactorControl.disabled) { diversityFactorControl.enable({ onlySelf: true, emitEvent: false }); }
          if (costOfCarbonControl && costOfCarbonControl.disabled) { costOfCarbonControl.enable({ onlySelf: true, emitEvent: false }); }
          if (costOfCarbonUnitControl && costOfCarbonUnitControl.disabled) { costOfCarbonUnitControl.enable({ onlySelf: true, emitEvent: false }); }
          if (energyUnitCostControl && energyUnitCostControl.disabled) { energyUnitCostControl.enable({ onlySelf: true, emitEvent: false }); }
          if (energyUnitCostUnitControl && energyUnitCostUnitControl.disabled) { energyUnitCostUnitControl.enable({ onlySelf: true, emitEvent: false }); }
          if (waterUnitCostControl && waterUnitCostControl.disabled) { waterUnitCostControl.enable({ onlySelf: true, emitEvent: false }); }
          if (waterUnitCostUnitControl && waterUnitCostUnitControl.disabled) { waterUnitCostUnitControl.enable({ onlySelf: true, emitEvent: false }); }
          if (waterTreatmentUnitCostControl && waterTreatmentUnitCostControl.disabled) { waterTreatmentUnitCostControl.enable({ onlySelf: true, emitEvent: false }); }
          if (waterTreatmentUnitCostUnitControl && waterTreatmentUnitCostUnitControl.disabled) { waterTreatmentUnitCostUnitControl.enable({ onlySelf: true, emitEvent: false }); }
          if (hotwellTemperatureControl && hotwellTemperatureControl.disabled) { hotwellTemperatureControl.enable({ onlySelf: true, emitEvent: false }); }
          if (hotwellTemperatureUnitControl && hotwellTemperatureUnitControl.disabled) { hotwellTemperatureUnitControl.enable({ onlySelf: true, emitEvent: false }); }
          if (boilerEfficiencyControl && boilerEfficiencyControl.disabled) { boilerEfficiencyControl.enable({ onlySelf: true, emitEvent: false }); }
          if (boilerPressureControl && boilerPressureControl.disabled) { boilerPressureControl.enable({ onlySelf: true, emitEvent: false }); }

          this.sizingModuleForm.get('processConditions.performEnergySavingCalc').patchValue(JSON.parse(processConditions.processInputs.find(m => m.name === "Perform Energy Saving Calc").value), { onlySelf: true, emitEvent: false });
          this.sizingModuleForm.get('processConditions.hoursOfOperation').patchValue(+processConditions.processInputs.find(m => m.name === "Hours Of Operation").value, { onlySelf: true, emitEvent: false });
          this.sizingModuleForm.get('processConditions.diversityFactor').patchValue(+processConditions.processInputs.find(m => m.name === "Diversity Factor").value, { onlySelf: true, emitEvent: false });
          this.sizingModuleForm.get('processConditions.costOfCarbon').patchValue(+processConditions.processInputs.find(m => m.name === "Cost Of Carbon").value, { onlySelf: true, emitEvent: false });
          this.sizingModuleForm.get('processConditions.energyUnitCost').patchValue(+processConditions.processInputs.find(m => m.name === "Energy Unit Cost").value, { onlySelf: true, emitEvent: false });
          this.sizingModuleForm.get('processConditions.waterUnitCost').patchValue(+processConditions.processInputs.find(m => m.name === "Water Unit Cost").value, { onlySelf: true, emitEvent: false });
          this.sizingModuleForm.get('processConditions.waterTreatmentUnitCost').patchValue(+processConditions.processInputs.find(m => m.name === "Water Treatment Unit Cost").value, { onlySelf: true, emitEvent: false });
          this.sizingModuleForm.get('processConditions.hotwellTemperature').patchValue(+processConditions.processInputs.find(m => m.name === "Hotwell Temperature").value, { onlySelf: true, emitEvent: false });
          this.sizingModuleForm.get('processConditions.boilerEfficiency').patchValue(+processConditions.processInputs.find(m => m.name === "Boiler Efficiency").value, { onlySelf: true, emitEvent: false });
          this.sizingModuleForm.get('processConditions.boilerPressure').patchValue(+processConditions.processInputs.find(m => m.name === "Boiler Pressure").value, { onlySelf: true, emitEvent: false });

         
          this.sizingModuleForm.get('processConditions.fuelType').patchValue(processConditions.processInputs.find(m => m.name === "Fuel Type").value, { onlySelf: true, emitEvent: false });

          this.resultsComponent.setEnergySavingUnitTranslations(this.emissionUnitsRef, this.energyUnitsRef, this.volumeUnitsRef, this.temperatureRef);
          
        }

        // load sizing result if exists

        if (this.loadedJobSizingData.sizingOutput.outputGrid.outputGridRows.length > 0) {

          this.apiSizingResponse = new EasiheatGen4Output();
          this.apiSizingResponse.easiHeatGen4Units = [];
          this.apiSizingResponse.easiHeatGen4Units[0] = new EasiHeatGen4Units();
          this.apiSizingResponse.easiHeatGen4Units[1] = new EasiHeatGen4Units();
          var sizingRow = new EasiHeatGen4Units();

          this.apiPricingResponse = new Array<EHUnitPricingOutput>();
          this.apiPricingResponse = [];
          var pricingRow = new EHUnitPricingOutput();

          this.apiEnergySavingResponse = new EnergySavingOutput();
          this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits = [];
          this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[0] = new EasiHeatGen4EnergySavingResultsUnit();
          this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[1] = new EasiHeatGen4EnergySavingResultsUnit();
          var energySavingRow = new EasiHeatGen4EnergySavingResultsUnit();

          //pass gateway info to results component
          this.resultsComponent.gatewayInfo = this.processConditionsComponent.gatewayInfo;

          for (var x = 0; x < this.loadedJobSizingData.sizingOutput.outputGrid.outputGridRows.length; x++) {

            let data = this.loadedJobSizingData.sizingOutput.outputGrid.outputGridRows[x];

            this.translateUnitsName(data.outputItems.find(u => u.name == 'unitModel').value.substring(0, 5));


            sizingRow.nomenclature = data.outputItems.find(u => u.name == 'sizingNomenclature').value;
            sizingRow.subCooling = +data.outputItems.find(u => u.name == 'subCooling').value;
            sizingRow.numberOfPlates = +data.outputItems.find(u => u.name == 'numberOfPlates').value;
            sizingRow.primaryPressureDrop = +data.outputItems.find(u => u.name == 'primaryPressureDrop').value;
            sizingRow.secondaryPressureDrop = +data.outputItems.find(u => u.name == 'secondaryPressureDrop').value;
            sizingRow.steamCvSize = data.outputItems.find(u => u.name == 'steamCvSize').value;
            sizingRow.steamCvSizeAnsi = data.outputItems.find(u => u.name == 'steamCvSizeAnsi').value;
            sizingRow.steamCvKv = data.outputItems.find(u => u.name == 'steamCvKv').value;
            sizingRow.steamCvRequiredKv = data.outputItems.find(u => u.name == 'steamCvRequiredKv').value;
            sizingRow.steamCvSplitRangeKv = data.outputItems.find(u => u.name == 'steamCvSplitRangeKv').value;
            sizingRow.steamCvCalculatedNoiseLevel = +data.outputItems.find(u => u.name == 'steamCvCalculatedNoiseLevel').value;
            sizingRow.steamCvInletDensity = +data.outputItems.find(u => u.name == 'steamCvInletDensity').value;
            sizingRow.steamCvOutletDensity = +data.outputItems.find(u => u.name == 'steamCvOutletDensity').value;
            sizingRow.steamValveCalculatedExitVelocityMetersPerSecond = +data.outputItems.find(u => u.name == 'steamValveCalculatedExitVelocityMetersPerSecond').value;
            sizingRow.oversurfacing = +data.outputItems.find(u => u.name == 'oversurfacing').value;
            sizingRow.primaryVelocity = +data.outputItems.find(u => u.name == 'primaryVelocity').value;
            sizingRow.secondaryVelocity = +data.outputItems.find(u => u.name == 'secondaryVelocity').value;
            sizingRow.heOutletPressure = +data.outputItems.find(u => u.name == 'heOutletPressure').value;
            sizingRow.trimType = data.outputItems.find(u => u.name == 'trimType').value;
            sizingRow.height = data.outputItems.find(u => u.name == 'height').value;
            sizingRow.width = data.outputItems.find(u => u.name == 'width').value;
            sizingRow.length = data.outputItems.find(u => u.name == 'length').value;
            sizingRow.weight = data.outputItems.find(u => u.name == 'weight').value;
            sizingRow.condensateRemoval = data.outputItems.find(u => u.name == 'condensateRemoval').value;
            sizingRow.condensateValveDnSize = data.outputItems.find(u => u.name == 'condensateValveDnSize').value;
            sizingRow.condensateValveAnsiSize = data.outputItems.find(u => u.name == 'condensateValveAnsiSize').value;
            sizingRow.condensateValveTrim = data.outputItems.find(u => u.name == 'condensateValveTrim').value !== 'null' ? data.outputItems.find(u => u.name == 'condensateValveTrim').value : null;
            sizingRow.condensateValveKv = data.outputItems.find(u => u.name == 'condensateValveKv').value;
            sizingRow.condensateValveRequiredKv = data.outputItems.find(u => u.name == 'condensateValveRequiredKv').value;
            sizingRow.grossMargin = +data.outputItems.find(u => u.name == 'grossMargin').value;
            sizingRow.hePressure = +data.outputItems.find(u => u.name == 'hePressure').value;
            sizingRow.unitPrice = +data.outputItems.find(u => u.name == 'unitPrice').value;
            sizingRow.drawingFile = data.outputItems.find(u => u.name == 'drawingFile').value !== 'null' ? data.outputItems.find(u => u.name == 'drawingFile').value : null;
            sizingRow.drawingOnlineLink = data.outputItems.find(u => u.name == 'drawingOnlineLink').value !== 'null' ? data.outputItems.find(u => u.name == 'drawingOnlineLink').value : null;
            sizingRow.drawingOfflineLink = data.outputItems.find(u => u.name == 'drawingOfflineLink').value !== 'null' ? data.outputItems.find(u => u.name == 'drawingOfflineLink').value : null;
            sizingRow.tiFile = data.outputItems.find(u => u.name == 'tiFile').value !== 'null' ? data.outputItems.find(u => u.name == 'tiFile').value : null;
            sizingRow.tiOfflineLink = data.outputItems.find(u => u.name == 'tiOfflineLink').value !== 'null' ? data.outputItems.find(u => u.name == 'tiOfflineLink').value : null;
            sizingRow.tiOnlineLink = data.outputItems.find(u => u.name == 'tiOnlineLink').value !== 'null' ? data.outputItems.find(u => u.name == 'tiOnlineLink').value : null;
            sizingRow.hePrice = +data.outputItems.find(u => u.name == 'hePrice').value;
            sizingRow.heWeight = +data.outputItems.find(u => u.name == 'heWeight').value;
            sizingRow.heatExchangerPlateType = data.outputItems.find(u => u.name == 'heatExchangerPlateType').value;
            sizingRow.channelTypeSide1 = data.outputItems.find(u => u.name == 'channelTypeSide1').value;
            sizingRow.channelTypeSide2 = data.outputItems.find(u => u.name == 'channelTypeSide2').value;
            sizingRow.connectionStandard = data.outputItems.find(u => u.name == 'connectionStandard').value;
            sizingRow.numberOfChannelsSide1 = +data.outputItems.find(u => u.name == 'numberOfChannelsSide1').value;
            sizingRow.numberOfChannelsSide2 = +data.outputItems.find(u => u.name == 'numberOfChannelsSide2').value;
            sizingRow.unitModel = data.outputItems.find(u => u.name == 'unitModel').value;
            sizingRow.steamMassFlow = +data.outputItems.find(u => u.name == 'steamMassFlow').value;
            sizingRow.nomenclatureChannelType = data.outputItems.find(u => u.name == 'nomenclatureChannelType').value;
            sizingRow.heFrameType = data.outputItems.find(u => u.name == 'heFrameType').value;
            sizingRow.htgCcInletPipeVeloctiyOver40ms = data.outputItems.find(u => u.name == 'htgCcInletPipeVeloctiyOver40ms').value == 'true' ? true : false;
            sizingRow.epdmwGasketPressureCheck = data.outputItems.find(u => u.name == 'epdmwGasketPressureCheck').value == 'true' ? true : false;
            sizingRow.maxCondensateTemp = +data.outputItems.find(u => u.name == 'maxCondensateTemp').value;

            if (!isNullOrUndefined(data.messages)) {
              if (data.messages.length > 0) {
                sizingRow.modelSizingMessages = data.messages;
                this.showSizingMessages(data.messages, data.outputItems.find(u => u.name == 'unitModel').value);
              }
              else {
                sizingRow.modelSizingMessages = [];
              }
            }

            //Specification Sheet Header Details
            if (x == 0) {

              let specSheetValue: HeaderDetails = { sheet: "", quantity: "", revisionNumber: "", aoNumber: "", projectType: "", orderNumber: "", notes: "" };

              specSheetValue.sheet = data.outputItems.find(u => u.name == 'sheet').value;
              specSheetValue.quantity = data.outputItems.find(u => u.name == 'quantity').value;
              specSheetValue.revisionNumber = data.outputItems.find(u => u.name == 'revisionNumber').value;
              specSheetValue.aoNumber = data.outputItems.find(u => u.name == 'aoNumber').value;
              specSheetValue.projectType = data.outputItems.find(u => u.name == 'projectType').value;
              specSheetValue.orderNumber = data.outputItems.find(u => u.name == 'orderNumber').value;
              specSheetValue.notes = data.outputItems.find(u => u.name == 'notes').value;

              this.sizingModuleForm.get('specsheet').patchValue(specSheetValue, { emitEvent: false });
            }


            //data for Rob *********************************************************************************************************************************************
            sizingRow.steamCvRequiredKv = data.outputItems.find(u => u.name == 'steamCvRequiredKv').value;
            sizingRow.condensateValveRequiredKv = data.outputItems.find(u => u.name == 'condensateValveRequiredKv').value;
            sizingRow.steamValveCalculatedExitVelocityMetersPerSecond = +data.outputItems.find(u => u.name == 'steamValveCalculatedExitVelocityMetersPerSecond').value;
            sizingRow.steamCvInletDensity = +data.outputItems.find(u => u.name == 'steamCvInletDensity').value;
            sizingRow.steamCvOutletDensity = +data.outputItems.find(u => u.name == 'steamCvOutletDensity').value;
            //**********************************************************************************************************************************************************

            pricingRow.inletSize = data.outputItems.find(u => u.name == 'inletSize').value; // UI field name => 'Package Inlet Connection Size'
            pricingRow.inletSizeAnsi = data.outputItems.find(u => u.name == 'inletSizeAnsi').value; // UI field name => 'Package Inlet Connection Size Ansi'
            pricingRow.condensatePipeSize = data.outputItems.find(u => u.name == 'condensatePipeSize').value; // UI field name => 'Condensate Pipe Size'
            pricingRow.inletOutletPipeSize = data.outputItems.find(u => u.name == 'inletOutletPipeSize').value; // UI field name => 'Secondary Inlet Outlet Pipe Size'
            pricingRow.condensatePipeSizeAnsi = data.outputItems.find(u => u.name == 'condensatePipeSizeAnsi').value; // UI field name => 'Condensate Pipe Size Ansi'
            pricingRow.inletOutletPipeSizeAnsi = data.outputItems.find(u => u.name == 'inletOutletPipeSizeAnsi').value;// UI field name => 'Secondary Inlet Outlet Pipe Size Ansi'
            pricingRow.totalSSPPrice = +data.outputItems.find(u => u.name == 'totalSSPPrice').value;
            pricingRow.totalRecommendedSalesPrice = +data.outputItems.find(u => u.name == 'totalRecommendedSalesPrice').value;
            pricingRow.nomenclature = data.outputItems.find(u => u.name == 'pricingNomenclature').value;
            pricingRow.currency = +data.outputItems.find(u => u.name == 'currency').value;
            pricingRow.fa11Possible = data.outputItems.find(u => u.name == 'fa11Possible').value == 'true' ? true : false;
            this.sellingCurrencySymbol = this.loadedJobSizingData.sizingOutput.outputItems.find(u => u.name == 'currencySymbol').value;
          


            if (JSON.parse(processConditions.processInputs.find(m => m.name === "Perform Energy Saving Calc").value) == true) {

              this.resultsComponent.performEnergySavingCalc = true;
              energySavingRow.co2eEmmissionReductionPerYear = + data.outputItems.find(u => u.name == 'co2eEmmissionReductionPerYear').value;
              energySavingRow.co2eEmmissionCostReductionPerYear = + data.outputItems.find(u => u.name == 'co2eEmmissionCostReductionPerYear').value;
              energySavingRow.energySavedPerYear = + data.outputItems.find(u => u.name == 'energySavedPerYear').value;
              energySavingRow.energyCostReductionPerYear = + data.outputItems.find(u => u.name == 'energyCostReductionPerYear').value;
              energySavingRow.waterSavedPerYear = + data.outputItems.find(u => u.name == 'waterSavedPerYear').value;
              energySavingRow.waterCostReductionPerYear = + data.outputItems.find(u => u.name == 'waterCostReductionPerYear').value;
              energySavingRow.steamConsumptionReductionPercent = + data.outputItems.find(u => u.name == 'steamConsumptionReductionPercent').value;
              energySavingRow.steamMassSaved = + data.outputItems.find(u => u.name == 'steamMassSaved').value;
              energySavingRow.totalEvcCostReductionPerYear = + data.outputItems.find(u => u.name == 'totalEvcCostReductionPerYear').value;
              energySavingRow.unitType = data.outputItems.find(u => u.name == 'unitType').value;

              this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[x] = energySavingRow;
              energySavingRow = new EasiHeatGen4EnergySavingResultsUnit();

              //Fuel Info
              this.apiEnergySavingResponse.fuelCalorificValue = +data.outputItems.find(fuelInfo => fuelInfo.name == 'fuelCalorificValue').value;
              this.apiEnergySavingResponse.fuelCalorificValueUnit = +data.outputItems.find(fuelInfo => fuelInfo.name == 'fuelCalorificValueUnit').value;
              this.apiEnergySavingResponse.fuelCO2eEmissions = +data.outputItems.find(fuelInfo => fuelInfo.name == 'fuelCO2eEmissions').value;
              this.apiEnergySavingResponse.fuelCO2eEmmissionUnit = +data.outputItems.find(fuelInfo => fuelInfo.name == 'fuelCO2eEmmissionUnit').value;
              this.resultsComponent.fuelName = processConditions.processInputs.find(m => m.name === "Fuel Type").value;
              
            }

            this.apiSizingResponse.easiHeatGen4Units[x] = sizingRow;
            sizingRow = new EasiHeatGen4Units();

            this.apiPricingResponse[x] = pricingRow;
            pricingRow = new EHUnitPricingOutput();
          }

          //check how many units is being returned one or two
          if (!isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units[0].unitModel) && isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units[1].unitModel) || isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units[0].unitModel) && !isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units[1].unitModel)) {

            let first = !isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units[0].unitModel) ? true : false;
            let second = !isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units[1].unitModel) ? true : false;

            if (first) {
              let model = this.apiSizingResponse.easiHeatGen4Units[0].unitModel.substring(0, 5);
              if (!model.includes('SC')) {
                this.apiSizingResponse.easiHeatGen4Units[1] = this.apiSizingResponse.easiHeatGen4Units[0];
                this.apiSizingResponse.easiHeatGen4Units[0] = new EasiHeatGen4Units();

                this.apiPricingResponse[1] = this.apiPricingResponse[0];
                this.apiPricingResponse[0] = new EHUnitPricingOutput();

                this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[1] = this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[0];
                this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[0] = new EasiHeatGen4EnergySavingResultsUnit();


                this.sizedUnitTypes[1] = this.sizedUnitTypes[0];
                this.sizedUnitTypes[0] = null;

              }
            }
            else if (second) {
              let model = this.apiSizingResponse.easiHeatGen4Units[1].unitModel.substring(0, 5);
              if (model.includes('SC')) {
                this.apiSizingResponse.easiHeatGen4Units[0] = this.apiSizingResponse.easiHeatGen4Units[1];
                this.apiSizingResponse.easiHeatGen4Units[1] = new EasiHeatGen4Units();

                this.apiPricingResponse[0] = this.apiPricingResponse[1];
                this.apiPricingResponse[1] = new EHUnitPricingOutput();

                this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[0] = this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[1];
                this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[1] = new EasiHeatGen4EnergySavingResultsUnit();


                this.sizedUnitTypes[0] = this.sizedUnitTypes[1];
                this.sizedUnitTypes[1] = null;

              }
            }

          }
          else {

            let firstModel = this.apiSizingResponse.easiHeatGen4Units[0].unitModel.substring(0, 5);
            let secondModel = this.apiSizingResponse.easiHeatGen4Units[1].unitModel.substring(0, 5);
            if (!firstModel.includes('SC') && secondModel.includes('SC')) {

              let placeHolder = this.apiSizingResponse.easiHeatGen4Units[0];
              this.apiSizingResponse.easiHeatGen4Units[0] = this.apiSizingResponse.easiHeatGen4Units[1];
              this.apiSizingResponse.easiHeatGen4Units[1] = placeHolder;

              let pricingPlaceHolder = this.apiPricingResponse[0];
              this.apiPricingResponse[0] = this.apiPricingResponse[1];
              this.apiPricingResponse[1] = pricingPlaceHolder;

              let energySavingPlaceHolder = this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[0];
              this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[0] = this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[1]
              this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[1] = energySavingPlaceHolder;

              let sizedUnitTypesPlaceHolder = this.sizedUnitTypes[0];
              this.sizedUnitTypes[0] = this.sizedUnitTypes[1];
              this.sizedUnitTypes[1] = sizedUnitTypesPlaceHolder;

            }
          }

          this.resultsComponent.displaySizingResults(this.apiSizingResponse);
          this.resultsComponent.displayPricingResults(this.apiPricingResponse);

          if (JSON.parse(processConditions.processInputs.find(m => m.name === "Perform Energy Saving Calc").value) == true) {
            if (!isNullOrUndefined(this.apiEnergySavingResponse)) {
              this.resultsComponent.displayEnergySavingResults(this.apiEnergySavingResponse);
            } else {
              console.log('Perform Energy Saving Calc check box was checked, but there is no data in the "this.apiEnergySavingResponse" object');
            }
          }
          

          var leftUnitOptions: OutputItem[] = [];
          var rightUnitOptions: OutputItem[] = [];
          this.loadedJobSizingData.sizingOutput.outputItems.forEach(item => item.type == 'left unit options' ? leftUnitOptions.push(item) : null);
          this.loadedJobSizingData.sizingOutput.outputItems.forEach(item => item.type == 'right unit options' ? rightUnitOptions.push(item) : null);


          if (!isNullOrUndefined(leftUnitOptions.find(prop => !isNullOrUndefined(prop.name.includes('GasketMaterial'))).value)) {
            this.resultsComponent.selectedEnumerationNames.gasketMaterial[0] = leftUnitOptions.find(prop => prop.name.includes('GasketMaterial')).name;
            this.resultsComponent.selectedEnumerationNames.actuator[0] = leftUnitOptions.find(prop => prop.name.includes('Actuator')).name;
            this.resultsComponent.selectedEnumerationNames.condensateRemoval[0] = leftUnitOptions.find(prop => prop.name.includes('CondensateRemoval')).name;
            this.resultsComponent.selectedEnumerationNames.highLimit[0] = leftUnitOptions.find(prop => prop.name.includes('HighLimit')).name;
            this.resultsComponent.selectedEnumerationNames.manualIsolation[0] = leftUnitOptions.find(prop => prop.name.includes('ManualIsolation')).name;
            this.resultsComponent.selectedEnumerationNames.systemRecirculation[0] = leftUnitOptions.find(prop => prop.name.includes('SystemRecirculation')).name;
            this.resultsComponent.selectedEnumerationNames.controlSystem[0] = leftUnitOptions.find(prop => prop.name.includes('ControlSystem')).name;
            this.resultsComponent.selectedEnumerationNames.frameType[0] = leftUnitOptions.find(prop => prop.name.includes('FrameType')).name;
            this.resultsComponent.selectedEnumerationNames.packageSupports[0] = leftUnitOptions.find(prop => prop.name.includes('PackageSupports')).name;
            this.resultsComponent.selectedEnumerationNames.panelLocation[0] = leftUnitOptions.find(prop => prop.name.includes('PanelLocation')).name;
            this.resultsComponent.selectedEnumerationNames.extras[0] = leftUnitOptions.find(prop => prop.name.includes('Extras')).name;
            this.resultsComponent.selectedEnumerationNames.integrityTest[0] = leftUnitOptions.find(prop => prop.name.includes('IntegrityTest')).name;
            this.resultsComponent.selectedEnumerationNames.dataCollection[0] = leftUnitOptions.find(prop => prop.name.includes('DataCollection')).name;
            this.resultsComponent.selectedEnumerationNames.controlValveFeedback[0] = leftUnitOptions.find(prop => prop.name.includes('ControlValveFeedback')).name;
            this.resultsComponent.selectedEnumerationNames.ta11[0] = leftUnitOptions.find(prop => prop.name.includes('TA11')).name;
            this.resultsComponent.selectedEnumerationNames.ta31[0] = leftUnitOptions.find(prop => prop.name.includes('TA31')).name;
            this.resultsComponent.selectedEnumerationNames.ta41[0] = leftUnitOptions.find(prop => prop.name.includes('TA41')).name;
            this.resultsComponent.selectedEnumerationNames.ta01[0] = leftUnitOptions.find(prop => prop.name.includes('TA01')).name;
            this.resultsComponent.selectedEnumerationNames.pa31[0] = leftUnitOptions.find(prop => prop.name.includes('PA31')).name;
            this.resultsComponent.selectedEnumerationNames.fa11[0] = leftUnitOptions.find(prop => prop.name.includes('FA11')).name;
            this.resultsComponent.selectedEnumerationNames.ups[0] = leftUnitOptions.find(prop => prop.name.includes('UPS')).name;
            this.resultsComponent.selectedEnumerationNames.communications[0] = leftUnitOptions.find(prop => prop.name.includes('Communications')).name;
            this.resultsComponent.selectedEnumerationNames.insulation[0] = leftUnitOptions.find(prop => prop.name.includes('Insulation')).name;
          }

          if (!isNullOrUndefined(rightUnitOptions.find(prop => !isNullOrUndefined(prop.name.includes('GasketMaterial'))).value)) {
            this.resultsComponent.selectedEnumerationNames.gasketMaterial[1] = rightUnitOptions.find(prop => prop.name.includes('GasketMaterial')).name;
            this.resultsComponent.selectedEnumerationNames.actuator[1] = rightUnitOptions.find(prop => prop.name.includes('Actuator')).name;
            this.resultsComponent.selectedEnumerationNames.condensateRemoval[1] = rightUnitOptions.find(prop => prop.name.includes('CondensateRemoval')).name;
            this.resultsComponent.selectedEnumerationNames.highLimit[1] = rightUnitOptions.find(prop => prop.name.includes('HighLimit')).name;
            this.resultsComponent.selectedEnumerationNames.manualIsolation[1] = rightUnitOptions.find(prop => prop.name.includes('ManualIsolation')).name;
            this.resultsComponent.selectedEnumerationNames.systemRecirculation[1] = rightUnitOptions.find(prop => prop.name.includes('SystemRecirculation')).name;
            this.resultsComponent.selectedEnumerationNames.controlSystem[1] = rightUnitOptions.find(prop => prop.name.includes('ControlSystem')).name;
            this.resultsComponent.selectedEnumerationNames.frameType[1] = rightUnitOptions.find(prop => prop.name.includes('FrameType')).name;
            this.resultsComponent.selectedEnumerationNames.packageSupports[1] = rightUnitOptions.find(prop => prop.name.includes('PackageSupports')).name;
            this.resultsComponent.selectedEnumerationNames.panelLocation[1] = rightUnitOptions.find(prop => prop.name.includes('PanelLocation')).name;
            this.resultsComponent.selectedEnumerationNames.extras[1] = rightUnitOptions.find(prop => prop.name.includes('Extras')).name;
            this.resultsComponent.selectedEnumerationNames.integrityTest[1] = rightUnitOptions.find(prop => prop.name.includes('IntegrityTest')).name;
            this.resultsComponent.selectedEnumerationNames.dataCollection[1] = rightUnitOptions.find(prop => prop.name.includes('DataCollection')).name;
            this.resultsComponent.selectedEnumerationNames.controlValveFeedback[1] = rightUnitOptions.find(prop => prop.name.includes('ControlValveFeedback')).name;
            this.resultsComponent.selectedEnumerationNames.ta11[1] = rightUnitOptions.find(prop => prop.name.includes('TA11')).name;
            this.resultsComponent.selectedEnumerationNames.ta31[1] = rightUnitOptions.find(prop => prop.name.includes('TA31')).name;
            this.resultsComponent.selectedEnumerationNames.ta41[1] = rightUnitOptions.find(prop => prop.name.includes('TA41')).name;
            this.resultsComponent.selectedEnumerationNames.ta01[1] = rightUnitOptions.find(prop => prop.name.includes('TA01')).name;
            this.resultsComponent.selectedEnumerationNames.pa31[1] = rightUnitOptions.find(prop => prop.name.includes('PA31')).name;
            this.resultsComponent.selectedEnumerationNames.fa11[1] = rightUnitOptions.find(prop => prop.name.includes('FA11')).name;
            this.resultsComponent.selectedEnumerationNames.ups[1] = rightUnitOptions.find(prop => prop.name.includes('UPS')).name;
            this.resultsComponent.selectedEnumerationNames.communications[1] = rightUnitOptions.find(prop => prop.name.includes('Communications')).name;
            this.resultsComponent.selectedEnumerationNames.insulation[1] = rightUnitOptions.find(prop => prop.name.includes('Insulation')).name;
          }

          var leftPricingValues: OutputItem[] = [];
          var rightPricingValues: OutputItem[] = [];
          this.loadedJobSizingData.sizingOutput.outputItems.forEach(item => item.type == 'left pricing values' ? leftPricingValues.push(item) : null);
          this.loadedJobSizingData.sizingOutput.outputItems.forEach(item => item.type == 'right pricing values' ? rightPricingValues.push(item) : null);
          if (leftPricingValues.length > 1) {
            this.resultsComponent.resultsForm.get('left.pricingOptions.markup').patchValue(leftPricingValues.find(val => val.name == 'markup').value);
            this.resultsComponent.resultsForm.get('left.pricingOptions.grossMargin').patchValue(leftPricingValues.find(val => val.name == 'grossMargin').value);
            //this.resultsComponent.resultsForm.get('left.pricingOptions.serviceOffering').patchValue(leftPricingValues.find(val => val.name == 'serviceOffering').value);
            this.resultsComponent.resultsForm.get('left.pricingOptions.ssp').patchValue(leftPricingValues.find(val => val.name == 'ssp').value);
            this.resultsComponent.resultsForm.get('left.pricingOptions.sellingPrice').patchValue(leftPricingValues.find(val => val.name == 'sellingPrice').value);
            //this.resultsComponent.resultsForm.get('left.pricingOptions.serviceOfferingPrice').patchValue(leftPricingValues.find(val => val.name == 'serviceOfferingPrice').value);
            //this.resultsComponent.resultsForm.get('left.pricingOptions.serviceOfferingFieldLabel').patchValue(leftPricingValues.find(val => val.name == 'serviceOfferingFieldLabel').value);
            this.resultsComponent.resultsForm.get('left.pricingOptions.delivery').patchValue(leftPricingValues.find(val => val.name == 'delivery').value);
            this.resultsComponent.resultsForm.get('left.pricingOptions.totalPrice').patchValue(leftPricingValues.find(val => val.name == 'totalPrice').value);
            this.resultsComponent.resultsForm.get('left.pricingOptions.unit_and_eh_internal_SSP').patchValue(leftPricingValues.find(val => val.name == 'unit_and_eh_internal_SSP').value);
            this.resultsComponent.resultsForm.get('left.pricingOptions.unit_and_eh_internal_SellingPrice').patchValue(leftPricingValues.find(val => val.name == 'unit_and_eh_internal_SellingPrice').value);
            if (isUndefined(leftPricingValues.find(val => val.name == 'unitSelected'))) {
              this.resultsComponent.resultsForm.get('left.pricingOptions.unitSelected').patchValue(true, { emitEvent: false });
              this.resultsComponent.resultsForm.get('left.pricingOptions.unitSelected').disable({ emitEvent: false });
            }
            else {
              this.resultsComponent.resultsForm.get('left.pricingOptions.unitSelected').patchValue(JSON.parse(leftPricingValues.find(val => val.name == 'unitSelected').value));
            }
            this.resultsComponent.resultsForm.get('left.pricingOptions.sellingMarkupUpdated').patchValue(JSON.parse(leftPricingValues.find(val => val.name == 'sellingMarkupUpdated').value));
            this.resultsComponent.resultsForm.get('left.pricingOptions.grossMarginUpdated').patchValue(JSON.parse(leftPricingValues.find(val => val.name == 'grossMarginUpdated').value));
            this.resultsComponent.resultsForm.get('left.pricingOptions.sellingPriceUpdated').patchValue(JSON.parse(leftPricingValues.find(val => val.name == 'sellingPriceUpdated').value));
          }

          if (rightPricingValues.length > 1) {
            this.resultsComponent.resultsForm.get('right.pricingOptions.markup').patchValue(rightPricingValues.find(val => val.name == 'markup').value);
            this.resultsComponent.resultsForm.get('right.pricingOptions.grossMargin').patchValue(rightPricingValues.find(val => val.name == 'grossMargin').value);
            //this.resultsComponent.resultsForm.get('right.pricingOptions.serviceOffering').patchValue(rightPricingValues.find(val => val.name == 'serviceOffering').value);
            this.resultsComponent.resultsForm.get('right.pricingOptions.ssp').patchValue(rightPricingValues.find(val => val.name == 'ssp').value);
            this.resultsComponent.resultsForm.get('right.pricingOptions.sellingPrice').patchValue(rightPricingValues.find(val => val.name == 'sellingPrice').value);
            //this.resultsComponent.resultsForm.get('right.pricingOptions.serviceOfferingPrice').patchValue(rightPricingValues.find(val => val.name == 'serviceOfferingPrice').value);
            //this.resultsComponent.resultsForm.get('right.pricingOptions.serviceOfferingFieldLabel').patchValue(rightPricingValues.find(val => val.name == 'serviceOfferingFieldLabel').value);
            this.resultsComponent.resultsForm.get('right.pricingOptions.delivery').patchValue(rightPricingValues.find(val => val.name == 'delivery').value);
            this.resultsComponent.resultsForm.get('right.pricingOptions.totalPrice').patchValue(rightPricingValues.find(val => val.name == 'totalPrice').value);
            this.resultsComponent.resultsForm.get('right.pricingOptions.unit_and_eh_internal_SSP').patchValue(rightPricingValues.find(val => val.name == 'unit_and_eh_internal_SSP').value);
            this.resultsComponent.resultsForm.get('right.pricingOptions.unit_and_eh_internal_SellingPrice').patchValue(rightPricingValues.find(val => val.name == 'unit_and_eh_internal_SellingPrice').value);
            if (isUndefined(rightPricingValues.find(val => val.name == 'unitSelected'))) {
              this.resultsComponent.resultsForm.get('right.pricingOptions.unitSelected').patchValue(true, { emitEvent: false });
              this.resultsComponent.resultsForm.get('right.pricingOptions.unitSelected').disable({ emitEvent: false });
            }
            else {
              this.resultsComponent.resultsForm.get('right.pricingOptions.unitSelected').patchValue(JSON.parse(rightPricingValues.find(val => val.name == 'unitSelected').value));
            }
            this.resultsComponent.resultsForm.get('right.pricingOptions.sellingMarkupUpdated').patchValue(JSON.parse(rightPricingValues.find(val => val.name == 'sellingMarkupUpdated').value));
            this.resultsComponent.resultsForm.get('right.pricingOptions.grossMarginUpdated').patchValue(JSON.parse(rightPricingValues.find(val => val.name == 'grossMarginUpdated').value));
            this.resultsComponent.resultsForm.get('right.pricingOptions.sellingPriceUpdated').patchValue(JSON.parse(rightPricingValues.find(val => val.name == 'sellingPriceUpdated').value));
          }


          if (!isNullOrUndefined(leftPricingValues.find(prop => !isNullOrUndefined(prop.name.includes('ServiceOffering'))).value)) {
            this.resultsComponent.selectedEnumerationNames.service_Offering[0] = leftPricingValues.find(val => val.name.includes('ServiceOffering')).name;
          }
          if (!isNullOrUndefined(rightPricingValues.find(prop => !isNullOrUndefined(prop.name.includes('ServiceOffering'))).value)) {
            this.resultsComponent.selectedEnumerationNames.service_Offering[1] = rightPricingValues.find(val => val.name.includes('ServiceOffering')).name;
          }
          //this.resultsComponent.selectedEnumerationNames.service_Offering.push(leftPricingValues.find(val => val.name.includes('ServiceOffering')).name, rightPricingValues.find(val => val.name.includes('ServiceOffering')).name);
          //this.resultsComponent.serviceOfferingKey.push(leftPricingValues.find(val => val.name == 'serviceOfferingFieldLabel').value, rightPricingValues.find(val => val.name == 'serviceOfferingFieldLabel').value)

          //loading unit options filter lists
          var filterLists: OutputItem[] = [];
          this.loadedJobSizingData.sizingOutput.outputItems.forEach(item => item.type == 'left filter list' || item.type == 'right filter list' ? filterLists.push(item) : null);
          for (let x = 0; x < 2; x++) {

            let value;

            value = filterLists.find(list => list.name == 'insulation_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.insulation_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'gasketMaterial_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.gasketMaterial_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'actuator_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.actuator_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'condensateRemoval_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.condensateRemoval_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'highLimit_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.highLimit_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'manualIsolation_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.manualIsolation_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'systemRecirculation_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.systemRecirculation_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'controlSystem_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.controlSystem_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'frameType_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.frameType_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'packageSupports_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.packageSupports_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'panelLocation_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.panelLocation_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'extras_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.extras_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'integrityTest_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.integrityTest_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'dataCollection_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.dataCollection_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'controlValveFeedback_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.controlValveFeedback_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'ta11_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.ta11_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'ta31_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.ta31_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'ta41_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.ta41_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'ta01_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.ta01_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'pa31_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.pa31_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'fa11_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.fa11_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'ups_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.ups_FilterLists[x] = value == "" ? [] : value;
            value = filterLists.find(list => list.name == 'communications_FilterLists' && list.type == ((x == 0 ? 'left' : 'right') + ' filter list')).value.slice(0, -1).split(',');
            this.resultsComponent.communications_FilterLists[x] = value == "" ? [] : value;
          }

          //set correct selling symbol
          this.resultsComponent.hasCurrencySymbol = false;
          this.resultsComponent.setCurrencySymbol(this.loadedJobSizingData.sizingOutput.outputItems.find(item => item.name == 'currencySymbol').value);

          setTimeout(() => {
            this.resultsComponent.resultsForm.get('left.unitOptions.gasketMaterial').setValue(this.resultsComponent.leftGasketMaterial.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.actuator').patchValue(this.resultsComponent.leftActuator.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.condensateRemoval').patchValue(this.resultsComponent.leftCondensateRemoval.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.highLimit').patchValue(this.resultsComponent.leftHighLimit.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.manualIsolation').patchValue(this.resultsComponent.leftManualIsolation.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.systemRecirculation').patchValue(this.resultsComponent.leftSystemRecirculation.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.controlSystem').patchValue(this.resultsComponent.leftControlSystem.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.frameType').patchValue(this.resultsComponent.leftFrameType.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.packageSupports').patchValue(this.resultsComponent.leftPackageSupports.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.panelLocation').patchValue(this.resultsComponent.leftPanelLocation.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.extras').patchValue(this.resultsComponent.leftExtras.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.integrityTest').patchValue(this.resultsComponent.leftIntegrityTest.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.dataCollection').patchValue(this.resultsComponent.leftDataCollection.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.controlValveFeedback').patchValue(this.resultsComponent.leftControlValveFeedback.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.ta11').patchValue(this.resultsComponent.leftTa11.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.ta31').patchValue(this.resultsComponent.leftTa31.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.ta41').patchValue(this.resultsComponent.leftTa41.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.ta01').patchValue(this.resultsComponent.leftTa01.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.pa31').patchValue(this.resultsComponent.leftPa31.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.fa11').patchValue(this.resultsComponent.leftFa11.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.ups').patchValue(this.resultsComponent.leftUps.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.communications').patchValue(this.resultsComponent.leftCommunications.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('left.unitOptions.insulation').patchValue(this.resultsComponent.leftInsulation.internalValue, { emitEvent: false });

            //this.resultsComponent.resultsForm.get('right.unitOptions.nomenclature').patchValue(this.resultsComponent.)
            this.resultsComponent.resultsForm.get('right.unitOptions.gasketMaterial').patchValue(this.resultsComponent.rightGasketMaterial.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.actuator').patchValue(this.resultsComponent.rightActuator.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.condensateRemoval').patchValue(this.resultsComponent.rightCondensateRemoval.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.highLimit').patchValue(this.resultsComponent.rightHighLimit.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.manualIsolation').patchValue(this.resultsComponent.rightManualIsolation.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.systemRecirculation').patchValue(this.resultsComponent.rightSystemRecirculation.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.controlSystem').patchValue(this.resultsComponent.rightControlSystem.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.frameType').patchValue(this.resultsComponent.rightFrameType.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.packageSupports').patchValue(this.resultsComponent.rightPackageSupports.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.panelLocation').patchValue(this.resultsComponent.rightPanelLocation.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.extras').patchValue(this.resultsComponent.rightExtras.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.integrityTest').patchValue(this.resultsComponent.rightIntegrityTest.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.dataCollection').patchValue(this.resultsComponent.rightDataCollection.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.controlValveFeedback').patchValue(this.resultsComponent.rightControlValveFeedback.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.ta11').patchValue(this.resultsComponent.rightTa11.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.ta31').patchValue(this.resultsComponent.rightTa31.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.ta41').patchValue(this.resultsComponent.rightTa41.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.ta01').patchValue(this.resultsComponent.rightTa01.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.pa31').patchValue(this.resultsComponent.rightPa31.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.fa11').patchValue(this.resultsComponent.rightFa11.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.ups').patchValue(this.resultsComponent.rightUps.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.communications').patchValue(this.resultsComponent.rightCommunications.internalValue, { emitEvent: false });
            this.resultsComponent.resultsForm.get('right.unitOptions.insulation').patchValue(this.resultsComponent.rightInsulation.internalValue, { emitEvent: false });

            if (!isNullOrUndefined(leftUnitOptions.find(prop => !isNullOrUndefined(prop.name.includes('GasketMaterial'))).value)) {
              this.resultsComponent.leftGasketMaterial.enumerationName = leftUnitOptions.find(prop => prop.name.includes('GasketMaterial')).name;
              this.resultsComponent.leftGasketMaterial.internalValue = leftUnitOptions.find(prop => prop.name.includes('GasketMaterial')).value;
              this.resultsComponent.leftActuator.enumerationName = leftUnitOptions.find(prop => prop.name.includes('Actuator')).name;
              this.resultsComponent.leftActuator.internalValue = leftUnitOptions.find(prop => prop.name.includes('Actuator')).value;
              this.resultsComponent.leftCondensateRemoval.enumerationName = leftUnitOptions.find(prop => prop.name.includes('CondensateRemoval')).name;
              this.resultsComponent.leftCondensateRemoval.internalValue = leftUnitOptions.find(prop => prop.name.includes('CondensateRemoval')).value;
              this.resultsComponent.leftHighLimit.enumerationName = leftUnitOptions.find(prop => prop.name.includes('HighLimit')).name;
              this.resultsComponent.leftHighLimit.internalValue = leftUnitOptions.find(prop => prop.name.includes('HighLimit')).value;
              this.resultsComponent.leftManualIsolation.enumerationName = leftUnitOptions.find(prop => prop.name.includes('ManualIsolation')).name;
              this.resultsComponent.leftManualIsolation.internalValue = leftUnitOptions.find(prop => prop.name.includes('ManualIsolation')).value;
              this.resultsComponent.leftSystemRecirculation.enumerationName = leftUnitOptions.find(prop => prop.name.includes('SystemRecirculation')).name;
              this.resultsComponent.leftSystemRecirculation.internalValue = leftUnitOptions.find(prop => prop.name.includes('SystemRecirculation')).value;
              this.resultsComponent.leftControlSystem.enumerationName = leftUnitOptions.find(prop => prop.name.includes('ControlSystem')).name;
              this.resultsComponent.leftControlSystem.internalValue = leftUnitOptions.find(prop => prop.name.includes('ControlSystem')).value;
              this.resultsComponent.leftFrameType.enumerationName = leftUnitOptions.find(prop => prop.name.includes('FrameType')).name;
              this.resultsComponent.leftFrameType.internalValue = leftUnitOptions.find(prop => prop.name.includes('FrameType')).value;
              this.resultsComponent.leftPackageSupports.enumerationName = leftUnitOptions.find(prop => prop.name.includes('PackageSupports')).name;
              this.resultsComponent.leftPackageSupports.internalValue = leftUnitOptions.find(prop => prop.name.includes('PackageSupports')).value;
              this.resultsComponent.leftPanelLocation.enumerationName = leftUnitOptions.find(prop => prop.name.includes('PanelLocation')).name;
              this.resultsComponent.leftPanelLocation.internalValue = leftUnitOptions.find(prop => prop.name.includes('PanelLocation')).value;
              this.resultsComponent.leftExtras.enumerationName = leftUnitOptions.find(prop => prop.name.includes('Extras')).name;
              this.resultsComponent.leftExtras.internalValue = leftUnitOptions.find(prop => prop.name.includes('Extras')).value;
              this.resultsComponent.leftIntegrityTest.enumerationName = leftUnitOptions.find(prop => prop.name.includes('IntegrityTest')).name;
              this.resultsComponent.leftIntegrityTest.internalValue = leftUnitOptions.find(prop => prop.name.includes('IntegrityTest')).value;
              this.resultsComponent.leftDataCollection.enumerationName = leftUnitOptions.find(prop => prop.name.includes('DataCollection')).name;
              this.resultsComponent.leftDataCollection.internalValue = leftUnitOptions.find(prop => prop.name.includes('DataCollection')).value;
              this.resultsComponent.leftControlValveFeedback.enumerationName = leftUnitOptions.find(prop => prop.name.includes('ControlValveFeedback')).name;
              this.resultsComponent.leftControlValveFeedback.internalValue = leftUnitOptions.find(prop => prop.name.includes('ControlValveFeedback')).value;
              this.resultsComponent.leftTa11.enumerationName = leftUnitOptions.find(prop => prop.name.includes('TA11')).name;
              this.resultsComponent.leftTa11.internalValue = leftUnitOptions.find(prop => prop.name.includes('TA11')).value;
              this.resultsComponent.leftTa31.enumerationName = leftUnitOptions.find(prop => prop.name.includes('TA31')).name;
              this.resultsComponent.leftTa31.internalValue = leftUnitOptions.find(prop => prop.name.includes('TA31')).value;
              this.resultsComponent.leftTa41.enumerationName = leftUnitOptions.find(prop => prop.name.includes('TA41')).name;
              this.resultsComponent.leftTa41.internalValue = leftUnitOptions.find(prop => prop.name.includes('TA41')).value;
              this.resultsComponent.leftTa01.enumerationName = leftUnitOptions.find(prop => prop.name.includes('TA01')).name;
              this.resultsComponent.leftTa01.internalValue = leftUnitOptions.find(prop => prop.name.includes('TA01')).value;
              this.resultsComponent.leftPa31.enumerationName = leftUnitOptions.find(prop => prop.name.includes('PA31')).name;
              this.resultsComponent.leftPa31.internalValue = leftUnitOptions.find(prop => prop.name.includes('PA31')).value;
              this.resultsComponent.leftFa11.enumerationName = leftUnitOptions.find(prop => prop.name.includes('FA11')).name;
              this.resultsComponent.leftFa11.internalValue = leftUnitOptions.find(prop => prop.name.includes('FA11')).value;
              this.resultsComponent.leftUps.enumerationName = leftUnitOptions.find(prop => prop.name.includes('UPS')).name;
              this.resultsComponent.leftUps.internalValue = leftUnitOptions.find(prop => prop.name.includes('UPS')).value;
              this.resultsComponent.leftCommunications.enumerationName = leftUnitOptions.find(prop => prop.name.includes('Communications')).name;
              this.resultsComponent.leftCommunications.internalValue = leftUnitOptions.find(prop => prop.name.includes('Communications')).value;
              this.resultsComponent.leftInsulation.enumerationName = leftUnitOptions.find(prop => prop.name.includes('Insulation')).name;
              this.resultsComponent.leftInsulation.internalValue = leftUnitOptions.find(prop => prop.name.includes('Insulation')).value;
              this.resultsComponent.leftServiceOffering.enumerationName = leftPricingValues.find(val => val.name.includes('ServiceOffering')).name;
              this.resultsComponent.leftServiceOffering.internalValue = leftPricingValues.find(val => val.name.includes('ServiceOffering')).value;
            }

            if (!isNullOrUndefined(rightUnitOptions.find(prop => !isNullOrUndefined(prop.name.includes('GasketMaterial'))).value)) {
              this.resultsComponent.rightGasketMaterial.enumerationName = rightUnitOptions.find(prop => prop.name.includes('GasketMaterial')).name;
              this.resultsComponent.rightGasketMaterial.internalValue = rightUnitOptions.find(prop => prop.name.includes('GasketMaterial')).value;
              this.resultsComponent.rightActuator.enumerationName = rightUnitOptions.find(prop => prop.name.includes('Actuator')).name;
              this.resultsComponent.rightActuator.internalValue = rightUnitOptions.find(prop => prop.name.includes('Actuator')).value;
              this.resultsComponent.rightCondensateRemoval.enumerationName = rightUnitOptions.find(prop => prop.name.includes('CondensateRemoval')).name;
              this.resultsComponent.rightCondensateRemoval.internalValue = rightUnitOptions.find(prop => prop.name.includes('CondensateRemoval')).value;
              this.resultsComponent.rightHighLimit.enumerationName = rightUnitOptions.find(prop => prop.name.includes('HighLimit')).name;
              this.resultsComponent.rightHighLimit.internalValue = rightUnitOptions.find(prop => prop.name.includes('HighLimit')).value;
              this.resultsComponent.rightManualIsolation.enumerationName = rightUnitOptions.find(prop => prop.name.includes('ManualIsolation')).name;
              this.resultsComponent.rightManualIsolation.internalValue = rightUnitOptions.find(prop => prop.name.includes('ManualIsolation')).value;
              this.resultsComponent.rightSystemRecirculation.enumerationName = rightUnitOptions.find(prop => prop.name.includes('SystemRecirculation')).name;
              this.resultsComponent.rightSystemRecirculation.internalValue = rightUnitOptions.find(prop => prop.name.includes('SystemRecirculation')).value;
              this.resultsComponent.rightControlSystem.enumerationName = rightUnitOptions.find(prop => prop.name.includes('ControlSystem')).name;
              this.resultsComponent.rightControlSystem.internalValue = rightUnitOptions.find(prop => prop.name.includes('ControlSystem')).value;
              this.resultsComponent.rightFrameType.enumerationName = rightUnitOptions.find(prop => prop.name.includes('FrameType')).name;
              this.resultsComponent.rightFrameType.internalValue = rightUnitOptions.find(prop => prop.name.includes('FrameType')).value;
              this.resultsComponent.rightPackageSupports.enumerationName = rightUnitOptions.find(prop => prop.name.includes('PackageSupports')).name;
              this.resultsComponent.rightPackageSupports.internalValue = rightUnitOptions.find(prop => prop.name.includes('PackageSupports')).value;
              this.resultsComponent.rightPanelLocation.enumerationName = rightUnitOptions.find(prop => prop.name.includes('PanelLocation')).name;
              this.resultsComponent.rightPanelLocation.internalValue = rightUnitOptions.find(prop => prop.name.includes('PanelLocation')).value;
              this.resultsComponent.rightExtras.enumerationName = rightUnitOptions.find(prop => prop.name.includes('Extras')).name;
              this.resultsComponent.rightExtras.internalValue = rightUnitOptions.find(prop => prop.name.includes('Extras')).value;
              this.resultsComponent.rightIntegrityTest.enumerationName = rightUnitOptions.find(prop => prop.name.includes('IntegrityTest')).name;
              this.resultsComponent.rightIntegrityTest.internalValue = rightUnitOptions.find(prop => prop.name.includes('IntegrityTest')).value;
              this.resultsComponent.rightDataCollection.enumerationName = rightUnitOptions.find(prop => prop.name.includes('DataCollection')).name;
              this.resultsComponent.rightDataCollection.internalValue = rightUnitOptions.find(prop => prop.name.includes('DataCollection')).value;
              this.resultsComponent.rightControlValveFeedback.enumerationName = rightUnitOptions.find(prop => prop.name.includes('ControlValveFeedback')).name;
              this.resultsComponent.rightControlValveFeedback.internalValue = rightUnitOptions.find(prop => prop.name.includes('ControlValveFeedback')).value;
              this.resultsComponent.rightTa11.enumerationName = rightUnitOptions.find(prop => prop.name.includes('TA11')).name;
              this.resultsComponent.rightTa11.internalValue = rightUnitOptions.find(prop => prop.name.includes('TA11')).value;
              this.resultsComponent.rightTa31.enumerationName = rightUnitOptions.find(prop => prop.name.includes('TA31')).name;
              this.resultsComponent.rightTa31.internalValue = rightUnitOptions.find(prop => prop.name.includes('TA31')).value;
              this.resultsComponent.rightTa41.enumerationName = rightUnitOptions.find(prop => prop.name.includes('TA41')).name;
              this.resultsComponent.rightTa41.internalValue = rightUnitOptions.find(prop => prop.name.includes('TA41')).value;
              this.resultsComponent.rightTa01.enumerationName = rightUnitOptions.find(prop => prop.name.includes('TA01')).name;
              this.resultsComponent.rightTa01.internalValue = rightUnitOptions.find(prop => prop.name.includes('TA01')).value;
              this.resultsComponent.rightPa31.enumerationName = rightUnitOptions.find(prop => prop.name.includes('PA31')).name;
              this.resultsComponent.rightPa31.internalValue = rightUnitOptions.find(prop => prop.name.includes('PA31')).value;
              this.resultsComponent.rightFa11.enumerationName = rightUnitOptions.find(prop => prop.name.includes('FA11')).name;
              this.resultsComponent.rightFa11.internalValue = rightUnitOptions.find(prop => prop.name.includes('FA11')).value;
              this.resultsComponent.rightUps.enumerationName = rightUnitOptions.find(prop => prop.name.includes('UPS')).name;
              this.resultsComponent.rightUps.internalValue = rightUnitOptions.find(prop => prop.name.includes('UPS')).value;
              this.resultsComponent.rightCommunications.enumerationName = rightUnitOptions.find(prop => prop.name.includes('Communications')).name;
              this.resultsComponent.rightCommunications.internalValue = rightUnitOptions.find(prop => prop.name.includes('Communications')).value;
              this.resultsComponent.rightInsulation.enumerationName = rightUnitOptions.find(prop => prop.name.includes('Insulation')).name;
              this.resultsComponent.rightInsulation.internalValue = rightUnitOptions.find(prop => prop.name.includes('Insulation')).value;
              this.resultsComponent.rightServiceOffering.enumerationName = rightPricingValues.find(val => val.name.includes('ServiceOffering')).name;
              this.resultsComponent.rightServiceOffering.internalValue = rightPricingValues.find(val => val.name.includes('ServiceOffering')).value;
            }

            if (!isNullOrUndefined(leftPricingValues.find(prop => !isNullOrUndefined(prop.name.includes('ServiceOffering'))).value)) {
              this.resultsComponent.calculateServiceOfferingPrice({ selectedValue: leftPricingValues.find(val => val.name.includes('ServiceOffering')).value }, 0);
            }
            if (!isNullOrUndefined(rightPricingValues.find(prop => !isNullOrUndefined(prop.name.includes('ServiceOffering'))).value)) {
              this.resultsComponent.calculateServiceOfferingPrice({ selectedValue: rightPricingValues.find(val => val.name.includes('ServiceOffering')).value }, 1);
            }

            this.leftOptions = this.resultsComponent.getAllUnitOptionsValue('left').options;
            this.rightOptions = this.resultsComponent.getAllUnitOptionsValue('right').options;
            this.sizingModuleForm.get('processConditions.maximumPressureDrop').patchValue(processConditions.processInputs.find(m => m.name === "Maximum Pressure Drop").value, { emitEvent: false });


            debugger;
            this.processConditionsComponent.isLoadingJob = false;
            this.resultsComponent.isLoadingJob = false;

            this.sizingModuleForm.markAsPristine();
            this.sizingModuleForm.markAsUntouched();

          }, 400);
          this.isEasiHeatSizingDone = true;
          this.isSpecSheetEnabled = true;
          this.isTiEnabled = true;
          this.isImiEnabled = true;

        }
        else {
          setTimeout(() => {
            this.sizingModuleForm.get('processConditions.maximumPressureDrop').patchValue(processConditions.processInputs.find(m => m.name === "Maximum Pressure Drop").value, { emitEvent: false });
            this.resultsComponent.isLoadingJob = false;

            this.sizingModuleForm.markAsPristine();
            this.sizingModuleForm.markAsUntouched();
          }, 400);
        }

      } else {
        this.isEasiHeatSizingDone = false;
      }

      //this.resultsComponent.isLoadingJob = false; //this has been moved to set time out function in the code above around line 736 
      this.isThisAJob = false;

    } catch (err) {
      console.log(`LoadJob() failed err=${err}`);
      //this.showJobLoadError();
      this.resultsComponent.isLoadingJob = false;
      this.sizingModuleForm.markAsPristine();
      this.sizingModuleForm.markAsUntouched();
    }
  }

  updateUnitType($event) {
    this.selectedUnitType = $event

    if (this.selectedUnitType.dhw) {

      convertToDefaultValueInPageUnits("inletTemperature", 10, this.temperatureRef.preference.value, this.unitsService, this.sizingModuleForm);
      convertToDefaultValueInPageUnits("outletTemperature", 65, this.temperatureRef.preference.value, this.unitsService, this.sizingModuleForm);
    }
    else {
      convertToDefaultValueInPageUnits("inletTemperature", 71, this.temperatureRef.preference.value, this.unitsService, this.sizingModuleForm);
      convertToDefaultValueInPageUnits("outletTemperature", 82, this.temperatureRef.preference.value, this.unitsService, this.sizingModuleForm);
    }

    this.processConditionsComponent.recalcLoadAndFlowRate();

  }

  updatedOptionsAndRePrice($event) {
    if (!this.resultsComponent.isLoadingJob) {

      if ($event.side == 'left') {
        this.leftOptions = $event.options;
      }
      else if ($event.side == 'right') {
        this.rightOptions = $event.options;
      }

      //recalc price method to be called here
      if (!isUndefined(this.apiSizingResponse)) {
        if (!this.isCalculating && !this.resultsComponent.isLoadingJob) {

          this.calculatePrice($event.options, $event.side);
        }
      }

    }

  }

  updatePricingOption($event) {

    //below veriables are going to be used in save job method. 

    if ($event.side == 'left') {
      this.leftPricingOptionsValues = $event.form;
      if ($event.form.unitSelected == true) {
        this.rightPricingOptionsValues.unitSelected = false
      }
    }
    else {
      this.rightPricingOptionsValues = $event.form;
      if ($event.form.unitSelected == true) {
        this.leftPricingOptionsValues.unitSelected = false
      }
    }
  }

  onCalculateSizing() {

    if (this.resizeUnitIndex == null) {

      this.messagesService.addMessage(null);
      this.messagesService.clearMessages();
      this.resultsComponent.resultsForm.reset();
      this.resultsComponent.serviceOfferingKey = [];
      this.resultsComponent.clearEnumerationsFilterLists();
      this.resultsComponent.initilaPricing = true;
      this.isEasiHeatSizingDone = false;
      this.currentGlobalVariance = this.processConditionsComponent.getGlobalVariance();
      this.currentGasketCode = this.selectedUnitType.dhw ? this.initialDHWGasketCode : this.initialHTGGasketCode;
      this.currentCondensateRemoval = '';
      //reset previous sizing result
      this.apiSizingResponse = new EasiheatGen4Output();
      this.sizedUnitTypes = [];

    }


    // ToDo: Move this to selected result checked/ticked
    this.isSpecSheetEnabled = true;
    this.isTiEnabled = true;
    this.isImiEnabled = true;
    if (this.isCalculating == false) {
      this.isCalculating = true;
      console.info("Calculating EasiheatGen4!");

      this.blockUI.start(this.translatePipe.transform("CALCULATING_MESSAGE", true) + "...");

      //pcv --> 'p'rocess 'c'onditions 'v'alues
      const pcv = this.processConditionsComponent.getFormInformation();

      this.convertPressureToBarg(pcv.inletPressure);

      //User Settings / Credentials
      this.ehSizingDataInput.UserId = this.user.userId;
      this.ehSizingDataInput.UserName = this.user.username;
      this.ehSizingDataInput.UserCountry = this.user.operatingCompanyId; ////TODO UserCountry not used in sizing remove from model in later date
      this.ehSizingDataInput.UserOperatorType = "-", ////TODO UseOperatorType not used in sizing remove from model in later date
        this.ehSizingDataInput.UserSellingCurrency = this.sellingCurrency.id;
      this.ehSizingDataInput.UserManufacturerCurrency = this.manufacturerCurrency.id;
      this.ehSizingDataInput.Manufacturer = + this.modPref.EHgen4_Manufacturer;
      this.ehSizingDataInput.ManufacturingCurrencyConversionRate = 1.0; ////TODO ManufacturingCurrencyConversionRate not used in sizing remove from model in later date

      //ApplicationType
      this.ehSizingDataInput.ApplicationType = this.selectedUnitType.dhw ? 'DHW' : this.selectedUnitType.htgcc ? 'HTGCC' : 'HTGSC';
       
      // DHW and HTG Shared Primary Process Conditions Input Fields
      this.ehSizingDataInput.InletPressure = +pcv.inletPressure;
      this.ehSizingDataInput.BackPressure = +pcv.totalBackPressure;
      this.ehSizingDataInput.AllowSizeCvSplitRange = this.modPref.EHgen4_CVSplitRange;
      this.ehSizingDataInput.SizeCvSplitRangeOnly = pcv.sizeCvSplitRangeOnly;
      this.ehSizingDataInput.DesignPressure12Bar = pcv.designPressure12Bar;

      //Specific Primary Process Conditions Input Fields for DHW
      this.ehSizingDataInput.NoiseLimit = +pcv.noiseLimit;
      this.ehSizingDataInput.MotivePressureAvailable = this.processConditionsComponent.motiveInletPressureEntered;//this.modPref.EHgen4_MotiveInletPressureAvailable;
      this.ehSizingDataInput.MotivePressure = +(isNull(pcv.motiveInletPressure) ? 0 : pcv.motiveInletPressure)

      //Secondary Side (Water) --> same for both Units DHW & HTG
      this.ehSizingDataInput.InletTemperature = +pcv.inletTemperature;
      this.ehSizingDataInput.OutletTemperature = +pcv.outletTemperature;
      this.ehSizingDataInput.WaterFlowRate = +pcv.waterFlowRate;
      this.ehSizingDataInput.HeLoad = +pcv.load;
      this.ehSizingDataInput.MaxPressureDrop = +pcv.maximumPressureDrop !== 0 ? +pcv.maximumPressureDrop : this.defaultMaxPressDropValue();

      //Measuring Units Details
      this.ehSizingDataInput.InletPressureUnit = +(this.pressureRef.preference.value);
      this.ehSizingDataInput.TemperatureUnit = +(this.temperatureRef.preference.value);
      this.ehSizingDataInput.FlowRateUnit = +(this.volumetricFlowRef.preference.value);
      this.ehSizingDataInput.HeLoadUnit = +(this.loadRef.preference.value);
      this.ehSizingDataInput.NoiseLimitUnit = 65; //--> Rafal fake value as there is no Unit value in table Units for Noise (dBA) I suggest remove that from the class at the back end and the at the front end.

      this.ehSizingDataInput.MaxPressureDropUnit = this.currentGlobalVariance !== 'AS/UL' ? 39 : 45; // 'kPa' else 'PSI absolute'

      //Options
      this.ehSizingDataInput.HeOverriddenP251Markup = this.modPref.EHgen4_HEOverridenP251Markup;
      this.ehSizingDataInput.HeOverriddenP251MarkupValue = + this.modPref.EHgen4_HEOverridenP251MarkupValue;
      this.ehSizingDataInput.HeTs6Discount = + this.modPref.EHgen4_HETS6Discount;
      //this.ehSizingDataInput.HeM6Discount = + this.modPref.EHgen4_HEM6Discount;

      this.ehSizingDataInput.HeT6PDiscount = + this.modPref.EHgen4_HEM6Discount
      this.ehSizingDataInput.HeT8MDiscount = + this.modPref.EHgen4_HEM10Discount
      this.ehSizingDataInput.HeT10MDiscount = + this.modPref.EHgen4_HET10Discount;

      this.ehSizingDataInput.SellingCurrencyConversionRate = +this.modPref.EHgen4_CurrencyConversion;
      this.ehSizingDataInput.ServiceOffering = '';//(this.resultsForm ? this.resultsForm.internal_ServiceOfferingPrice : "").toString();

      this.ehSizingDataInput.GlobalVariance = this.currentGlobalVariance;
      this.ehSizingDataInput.GasketCode = this.currentGasketCode;

      this.ehSizingDataInput.PricingApplicationtype = this.resizeUnitIndex != null ? this.sizedUnitTypes[this.resizeUnitIndex].substr(3).replace('_', '') : null;
      this.ehSizingDataInput.CondensateRemoval = this.currentCondensateRemoval;// ST-steam trap PT-pumptrap  PTHC-pump trap high capacity
      this.ehSizingDataInput.UserCondensateSet = this.userCondensate;


      this.sizingApi.sizeEasiheat(this.ehSizingDataInput).toPromise().then((response: EasiheatGen4Output) => {

        this.isCalculating = true;
        debugger;
        this.resultsComponent.gatewayInfo = this.processConditionsComponent.gatewayInfo;
        //first check if this is initial sizing or resizing
        if (this.resizeUnitIndex == null) {

          //Check and handle if nothig has been sizied.
          // If both nemenclatures come back as empty "", that means that nothing has been sized for given Process Condtions.
          if (response.easiHeatGen4Units[0].nomenclature === "" && response.easiHeatGen4Units[1].nomenclature === "") {

            let transUnableToSize = this.translatePipe.transform('THE_HEAT_EXCHANGER_IS_UNSUITABLE_FOR_THE_SPECIFIED_CONDITIONS_A_BESPOKE_UNIT_IS_REQUIRED_MESSAGE', false);
            let transInfo = this.translatePipe.transform('INFO', false);
            swal({
              closeOnClickOutside: false, closeOnEsc: false,
              title: transInfo,
              text: transUnableToSize,
              icon: "info",
              dangerMode: true,
              //buttons: ['Ok', 'Cancel']
            }).then((okbuttoncClicked?: boolean) => {
              console.info("Ok clicked...");
              this.isCalculating = false;
              //this.isSizing = false;
              //this.calcTriggerListChanged = false;
              //this.sizingModuleForm.markAsPristine();
              //this.sizingModuleForm.markAsUntouched();
              this.userCondensate = false;
              this.blockUI.stop();

              // The parameter can also enter as null
              const returnVal = !(okbuttoncClicked === null);
            }
            );
            return;
          }

          if (!this.processConditionsComponent.gatewayInfo.isGatewayAvailable) {

            let transUnableToSize = this.translatePipe.transform('GATEWAY_NOT_AVAILABLE_MSG', false);
            let transInfo = this.translatePipe.transform('INFO', false);
            swal({
              closeOnClickOutside: false, closeOnEsc: false,
              title: transInfo,
              text: transUnableToSize,
              icon: "info",
              dangerMode: true,
              //buttons: ['Ok', 'Cancel']
            }).then((okbuttoncClicked?: boolean) => {
              console.info("Ok clicked...");

              this.blockUI.stop();

              // The parameter can also enter as null
              const returnVal = !(okbuttoncClicked === null);
            }
            );
          }

          //Check and handle if only one unit was sizied.
          // If ONLY ONE of the nemenclatures come back as NOT empty, it would means that only one Unit was able to be sized for given Process Condtions.
          if (response.easiHeatGen4Units[0].nomenclature === "" && response.easiHeatGen4Units[1].nomenclature !== "" || response.easiHeatGen4Units[0].nomenclature !== "" && response.easiHeatGen4Units[1].nomenclature === "") {

            let first = response.easiHeatGen4Units[0].nomenclature !== "" ? true : false;
            let second = response.easiHeatGen4Units[1].nomenclature !== "" ? true : false;

            if (first) {
              let model = response.easiHeatGen4Units[0].unitModel.substring(0, 5);
              if (!model.includes('SC')) {
                let placeHolder = response.easiHeatGen4Units[1];
                response.easiHeatGen4Units[1] = response.easiHeatGen4Units[0];
                response.easiHeatGen4Units[0] = placeHolder;

                if (this.resizeUnitIndex == null) {
                  this.resultsComponent.energySavingSubPanelLeft = 'energySavingSubPanelDisabled';
                }
              }
              else if (this.resizeUnitIndex == null) {
                this.resultsComponent.energySavingSubPanelRight = 'energySavingSubPanelDisabled';
              }

            }
            else if (second) {
              let model = response.easiHeatGen4Units[1].unitModel.substring(0, 5);
              if (model.includes('SC')) {
                let placeHolder = response.easiHeatGen4Units[0];
                response.easiHeatGen4Units[0] = response.easiHeatGen4Units[1];
                response.easiHeatGen4Units[1] = placeHolder;

                if (this.resizeUnitIndex == null) {
                  this.resultsComponent.energySavingSubPanelRight = 'energySavingSubPanelDisabled';
                }
              }
              else if (this.resizeUnitIndex == null) {
                this.resultsComponent.energySavingSubPanelLeft = 'energySavingSubPanelDisabled';
              }
            }

            let transUnableToSize = this.translatePipe.transform('ONLY_ONE_UNIT_WAS_ABLE_TO_BE_SIZED_FOR_SPECIFIED_CONDITIONS_MESSAGE', false);
            let transInfo = this.translatePipe.transform('INFO', false);
            swal({
              closeOnClickOutside: false, closeOnEsc: false,
              title: transInfo,
              text: transUnableToSize,
              icon: "info",
              dangerMode: true,
              //buttons: ['Ok', 'Cancel']
            }).then((okbuttoncClicked?: boolean) => {
              console.info("Ok clicked...");
              // The parameter can also enter as null
              const returnVal = !(okbuttoncClicked === null);
            }
            );
          }
          else {

            let firstModel = response.easiHeatGen4Units[0].unitModel.substring(0, 5);
            let secondModel = response.easiHeatGen4Units[1].unitModel.substring(0, 5);

            if (!firstModel.includes('SC') && secondModel.includes('SC')) {

              let placeHolder = response.easiHeatGen4Units[0];
              response.easiHeatGen4Units[0] = response.easiHeatGen4Units[1];
              response.easiHeatGen4Units[1] = placeHolder;
            }
          }

          //Put all the Sizing Messages in to Messages Box (Envelope on the modal panel). 
          if (response.easiHeatGen4Units[0].modelSizingMessages.length > 0) {
            this.showSizingMessages(response.easiHeatGen4Units[0].modelSizingMessages, response.easiHeatGen4Units[0].unitModel);
          }
          if (response.easiHeatGen4Units[1].modelSizingMessages.length > 0) {
            this.showSizingMessages(response.easiHeatGen4Units[1].modelSizingMessages, response.easiHeatGen4Units[1].unitModel);
          }
        } else {

          //first check if this was resize initialised by condensate removal and see if it was actually able to size with the new condensate setting
          if (JSON.parse(this.resultsComponent.condensateRemoval_TouchLists[this.resizeUnitIndex].toString())) {
            if (response.easiHeatGen4Units[this.resizeUnitIndex].condensateRemoval == 'N' || !response.easiHeatGen4Units[this.resizeUnitIndex].condensateRemoval.includes(this.ehSizingDataInput.CondensateRemoval)) {

              let condensateRemovalFullName = this.ehSizingDataInput.CondensateRemoval == 'PT' ? 'PUMP_TRAP' : 'STEAM_TRAP';
              let transUnableToSize = this.translatePipe.transform('NOT_POSSIBLE_TO_SIZE_' + condensateRemovalFullName + '_MESSAGE', false);
              let transInfo = this.translatePipe.transform('INFO', false);
              swal({
                closeOnClickOutside: false, closeOnEsc: false,
                title: transInfo,
                text: transUnableToSize,
                icon: "info",
                dangerMode: true,
                //buttons: ['Ok', 'Cancel']
              }).then((okbuttoncClicked?: boolean) => {
                console.info("Ok clicked...");
                // The parameter can also enter as null
                response.easiHeatGen4Units[this.resizeUnitIndex] = this.apiSizingResponse.easiHeatGen4Units[this.resizeUnitIndex];
                this.resultsComponent.condensateRemoval_TouchLists[this.resizeUnitIndex] = [false];
                this.resultsComponent.leftCondensateRemoval.internalValue = this.ehSizingDataInput.CondensateRemoval == 'PT' ? 'ST' : 'PT';
                this.resultsComponent.actualCondensateRemovalValue[this.resizeUnitIndex] = this.ehSizingDataInput.CondensateRemoval == 'PT' ? 'ST' : 'PT';
                let side = this.resizeUnitIndex == 0 ? 'left' : 'right';
                this.resultsComponent.resultsForm.get(`${side}.unitOptions.condensateRemoval`).patchValue(this.ehSizingDataInput.CondensateRemoval == 'PT' ? 'ST' : 'PT', { emitEvent: false });
                this.isCalculating = false;
                this.blockUI.stop();
              }
              );
              return;
            }

          }

          if (response.easiHeatGen4Units[0].modelSizingMessages.length > 0) {
            this.showSizingMessages(response.easiHeatGen4Units[0].modelSizingMessages, response.easiHeatGen4Units[0].unitModel);
          }
        }

        response.easiHeatGen4Units.forEach(unit => !isNullOrUndefined(unit.unitModel) ? this.translateUnitsName(unit.unitModel.substring(0, 5)) : this.translateUnitsName('empty'));


        if (this.resizeUnitIndex == null) { // if this.resizeUnitIndex = null; that means this is a initial sizing
          this.resultsComponent.setupDefaultOptionsForSizingSelection(this.sizedUnitTypes);
          this.apiSizingResponse = response;
          this.resultsComponent.displaySizingResults(this.apiSizingResponse);
          if (!isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units[1])) {
            this.resultsComponent.htgCcInletPipeVeloctiyOver40ms = this.apiSizingResponse.easiHeatGen4Units[1].htgCcInletPipeVeloctiyOver40ms;
          }

          if (pcv.performEnergySavingCalc) {
            this.calculateEnergySaving();
            this.resultsComponent.performEnergySavingCalc = true;
          }
          this.calculatePrice();
          
        }
        else { //this.resizeUnitIndex != null; that means this is a re-sizing or only one unit has been sized initially
          this.apiSizingResponse.easiHeatGen4Units[this.resizeUnitIndex] = response.easiHeatGen4Units[0];
          this.resultsComponent.displaySizingResults(this.apiSizingResponse);
          if (this.resizeUnitIndex === 1) {
            this.resultsComponent.htgCcInletPipeVeloctiyOver40ms = this.apiSizingResponse.easiHeatGen4Units[this.resizeUnitIndex].htgCcInletPipeVeloctiyOver40ms;
          }
        
          this.calculatePrice(this.resultsComponent.getAllUnitOptionsValue(this.resizeUnitIndex == 0 ? 'left' : 'right').options, this.resizeUnitIndex == 0 ? 'left' : 'right');
          if (pcv.performEnergySavingCalc) {
            this.calculateEnergySaving();
            this.resultsComponent.performEnergySavingCalc = true;
          }
          setTimeout(() => { this.goDownToResults() }, 200);
        }
        
        //updating Job Status from 1 (Process Conditions) to 2 (Calculated) this status is used for Saving Job 
        this.jobStatusId = 2;

        this.isCalculating = false;
       
        return
      });
    }
  }


  /**
   * Method to calculate total price based on selected model and options.
  */
  calculatePrice(options?: EHPricingOptions[], side?: string) {
    console.info(this.sizingModuleForm.value);
    if (this.isCalculating == false) {
      this.isCalculating = true;

      if (!this.sellingCurrency || !this.sellingCurrency.symbol || this.sellingCurrency === null || this.sellingCurrency.symbol === null) {

        //jesli te if statement na gorze zwroci hociarz jeden false to prubuj zlapac preferencje jeszcze raz i moze sie uda  
        // try to get module prefs again
        //this.getModulePreferenceValues();

        if (!this.sellingCurrency || !this.sellingCurrency.symbol || this.sellingCurrency === null || this.sellingCurrency.symbol === null) {

          this.blockUI.stop();

          let trans_Error = "DEBUG!"; // this.translatePipe.transform('ERROR', true);
          let trans_Message = "Unable to calculate Pricing data. Module Preferences have failed to load, please refresh the page and try again."; // this.translatePipe.transform('SELECTED_JOB_WAS_NOT_FOUND_MESSAGE', true);

          swal({
            title: trans_Error + ':',
            text: trans_Message,
            icon: "error",
            dangerMode: true,
            //buttons: ['Ok', 'Cancel']
          }).then((okbuttoncClicked?: boolean) => {

            console.info("Ok clicked...");

            // The parameter can also enter as null
            const returnVal = !(okbuttoncClicked === null);

          });
          return;
        }
      }
    }

    // Ensure that the prices are displayed in current selling currency from the module prefs.

    //this.ehPricing.ModelId = this.modelId;
    this.messagesService.addMessage(null);
    this.sellingCurrencySymbol = this.sellingCurrency.symbol;
    this.ehPricing.UserSellingCurrency = +this.modPref.EHgen4_SellingCurrency

    this.ehPricing.OperatingCompanyId = this.user.operatingCompanyId;
    this.ehPricing.ManufacturerId = +this.modPref.EHgen4_Manufacturer;
    this.ehPricing.BasePriceOption = 0;
    this.ehPricing.LocalRecommendedSalesPriceOption = 0;
    this.ehPricing.LandedCostIncreaseFactor = +this.modPref.EHgen4_LandCostIncrease;
    this.ehPricing.WaterFlowRate = this.processConditionsComponent.processConditionsFormGroup.get('waterFlowRate').value;
    this.ehPricing.FlowRateUnit = +this.processConditionsComponent.volumetricFlowUnit.preference.value;
    this.ehPricing.GlobalVariance = this.processConditionsComponent.processConditionsFormGroup.get('globalVariance').value;
    this.ehPricing.Units = [];
    this.ehPricing.DesignPressure12Bar = this.processConditionsComponent.processConditionsFormGroup.get('designPressure12Bar').value;
    
    for (var x = 0; x < this.apiSizingResponse.easiHeatGen4Units.length; x++) {

      let unit = new EasiHeatGen4PricingUnit();
      unit.PricingOptions = [];

      if (isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units[x].unitModel)) {
        //this.ehPricing.Units.push(unit);
        continue;
      }

      unit.ModelId = 0;
      unit.UnitName = this.apiSizingResponse.easiHeatGen4Units[x].unitModel;
      unit.SplitRange = this.apiSizingResponse.easiHeatGen4Units[x].steamCvSplitRangeKv == '0' ? false : true;//this.processConditionsComponent.processConditionsFormGroup.get('sizeCvSplitRangeOnly').value
      unit.UnitType = this.apiSizingResponse.easiHeatGen4Units[x].unitModel.substr(3, 2);
      unit.steamCvSize = this.apiSizingResponse.easiHeatGen4Units[x].steamCvSize;
      unit.steamCvKv = this.apiSizingResponse.easiHeatGen4Units[x].steamCvKv;
      unit.CondensateValveDnSize = this.apiSizingResponse.easiHeatGen4Units[x].condensateValveDnSize;
      unit.CondensateValveKv = this.apiSizingResponse.easiHeatGen4Units[x].condensateValveKv;
      unit.NumberOfPlates = this.apiSizingResponse.easiHeatGen4Units[x].numberOfPlates;
      unit.ChannelTypeSide1 = this.apiSizingResponse.easiHeatGen4Units[x].channelTypeSide1;
      unit.ChannelTypeSide2 = this.apiSizingResponse.easiHeatGen4Units[x].channelTypeSide2;
      unit.SteamCvSplitRangeKv = this.apiSizingResponse.easiHeatGen4Units[x].steamCvSplitRangeKv;
      unit.HtgCcInletPipeVeloctiyOver40ms = this.apiSizingResponse.easiHeatGen4Units[x].htgCcInletPipeVeloctiyOver40ms;

      var unitInitialOptions = new Array<EHPricingOptions>();

      if (x == 0 && side == 'left') {
        unitInitialOptions = options;
      } else if (x == 1 && side == 'right') {
        unitInitialOptions = options;
      } else if (side == 'both') {
        unitInitialOptions = this.resultsComponent.getAllUnitOptionsValue(x == 0 ? 'left' : 'right').options;
      }
      else {
        if (side) {
          unitInitialOptions = this.resultsComponent.setUnitsOptionsRulesToModulePrefBeforeInitialPricingCall(
            this.initialPricingOptions.filter(opt => opt.EnumerationName.includes(this.sizedUnitTypes[x])),
            this.apiSizingResponse.easiHeatGen4Units[x].unitModel.substr(0, 5),
            this.apiSizingResponse.easiHeatGen4Units[x].unitModel.substr(5, 6)
          );
         //this.resultsComponent.pushInitialEnumerationValuesTotheForm(x == 0 ? 'left' : 'right', unitInitialOptions);
        }
        else {
          unitInitialOptions = this.resultsComponent.setUnitsOptionsRulesToModulePrefBeforeInitialPricingCall(
            this.initialPricingOptions.filter(opt => opt.EnumerationName.includes(this.sizedUnitTypes[x])),
            this.apiSizingResponse.easiHeatGen4Units[x].unitModel.substr(0, 5),
            this.apiSizingResponse.easiHeatGen4Units[x].unitModel.substr(5, 6)
          );
          this.resultsComponent.pushInitialEnumerationValuesTotheForm(x == 0 ? 'left' : 'right', unitInitialOptions);
        }
      }

      for (let option of unitInitialOptions) {
        unit.PricingOptions.push({ EnumerationName: option.EnumerationName, SelectedValue: option.SelectedValue });
      }

      if (this.sizedUnitTypes[x].includes("_SC") && this.isCalculating && isNullOrUndefined(options)) {
        unit.PricingOptions.find(opt => opt.EnumerationName.includes('_SC_CondensateRemoval')).SelectedValue = this.apiSizingResponse.easiHeatGen4Units[x].condensateRemoval;
      }
      else if ((this.sizedUnitTypes[x].includes("_SC") && this.isCalculating && !isNullOrUndefined(options))) {
        if (options.length == 0) {
          unit.PricingOptions.find(opt => opt.EnumerationName.includes('_SC_CondensateRemoval')).SelectedValue = this.apiSizingResponse.easiHeatGen4Units[x].condensateRemoval;
        }

      }

      unit.PricingOptions.find(opt => opt.EnumerationName.includes('GasketMaterial')).SelectedValue = this.setGasketCode(unit.PricingOptions.find(opt => opt.EnumerationName.includes('GasketMaterial')).SelectedValue);
      if (this.apiSizingResponse.easiHeatGen4Units[side == 'left' ? 0 : 1].condensateRemoval == 'PTHC') {
        unit.PricingOptions.find(opt => opt.EnumerationName.includes('CondensateRemoval')).SelectedValue = 'PTHC';
      }


      this.ehPricing.Units.push(unit);

    }

    if (side == 'left' && this.ehPricing.Units.length == 2) { this.ehPricing.Units.splice(1, 1) }
    if (side == 'right' && this.ehPricing.Units.length == 2) { this.ehPricing.Units.splice(0, 1) }


    //this.ehPricing.PricingOptions = [];  ---- bede musial pomyslec potem jak zrobic przy zmianie opcji zeby z update -owac

    this.sizingApi.calculateTotalPrice(this.ehPricing).subscribe((response: EHUnitPricingOutput[]) => {
      this.isCalculating = true;
      // First, check if any valid data returned?
      //if (response && response.totalSSPPrice >= 0 && response.totalRecommendedSalesPrice >= 0) {

      if (response) {

        if (!isNullOrUndefined(side)) {
          //if (side == 'both') { //that means this is result of re-pricing the initial sizing after units rules have been applied - therefore clear any existing msg as the new one will apply 
          //  this.clearUiMessages();
          //}

          if (!isUndefined(response[0].unitPricingMessages)) {
            if (!isNull(response[0].unitPricingMessages)) {
              if (response[0].unitPricingMessages.length > 0) {
                if (side == 'right') {
                  this.showSizingMessages(response[0].unitPricingMessages, this.resultsComponent.resultsForm.get('right.easiHeatUnit').value);
                } else {
                  this.showSizingMessages(response[0].unitPricingMessages, this.resultsComponent.resultsForm.get('left.easiHeatUnit').value);
                }
              } else {
                this.messagesService.clearMessages(side == 'right' ? this.resultsComponent.resultsForm.get('right.easiHeatUnit').value : this.resultsComponent.resultsForm.get('left.easiHeatUnit').value);
              }
            }
            else {
              this.messagesService.clearMessages(side == 'right' ? this.resultsComponent.resultsForm.get('right.easiHeatUnit').value : this.resultsComponent.resultsForm.get('left.easiHeatUnit').value);
            }
          }

          if (!isNullOrUndefined(response[1])) {
            if (!isNullOrUndefined(response[1].unitPricingMessages)) {
              if (response[1].unitPricingMessages.length > 0) {
                this.showSizingMessages(response[1].unitPricingMessages, this.resultsComponent.resultsForm.get('right.easiHeatUnit').value);
              }
              else {
                //clear
              }
            }
          }

        }


        let index: number = null;
        if (!isNullOrUndefined(side)) {
          index = side == 'left' ? 0 : side == 'right' ? 1 : null;
        }

        if (index == null) { // if index = null; that means this is a initial pricing

          //check if only one unit was sized initially
          if (isNullOrUndefined(response[0]) || isNullOrUndefined(response[1])) {
            let first = !isNullOrUndefined(response[0]) ? true : false;
            let second = !isNullOrUndefined(response[1]) ? true : false;

            if (first) {
              let model = response[0].nomenclature.substring(0, 5);
              response[1] = new EHUnitPricingOutput();
              if (!model.includes('SC')) {
                let placeHolder = response[1];
                response[1] = response[0];
                response[0] = placeHolder;

                if (response[1].unitPricingMessages !== null) {
                  this.showSizingMessages(response[1].unitPricingMessages, this.resultsComponent.resultsForm.get('right.easiHeatUnit').value);
                }
                
              } else {
                if (response[0].unitPricingMessages !== null) {
                  this.showSizingMessages(response[0].unitPricingMessages, this.resultsComponent.resultsForm.get('left.easiHeatUnit').value);
                }
              }

            }

            if (second) {
              let model = response[1].nomenclature.substring(0, 5);
              response[0] = new EHUnitPricingOutput();
              if (model.includes('SC')) {
                let placeHolder = response[0];
                response[0] = response[1];
                response[1] = placeHolder;

                if (response[0].unitPricingMessages !== null) {
                  this.showSizingMessages(response[0].unitPricingMessages, this.resultsComponent.resultsForm.get('left.easiHeatUnit').value);
                }
              } else {
                if (response[1].unitPricingMessages !== null) {
                  this.showSizingMessages(response[1].unitPricingMessages, this.resultsComponent.resultsForm.get('right.easiHeatUnit').value);
                }
              }
            }

          }
         
          this.apiPricingResponse = response;
          this.resultsComponent.displayPricingResults(this.apiPricingResponse);
          setTimeout(() => { this.goDownToResults() }, 200); //after sizing is complete it scroll down the page 
        }
        else { // if index != null; that means this is a re-pricing
          this.apiPricingResponse[index] = response[0];
          this.resultsComponent.displayPricingResults(this.apiPricingResponse);

          //this.findInvalidControls();
        }

        this.isEasiHeatSizingDone = true;
        this.blockUI.stop();
        this.isCalculating = false;
      }
      side = '';
      this.resizeUnitIndex = null;
    })
  }

  calculateEnergySaving() {

    const pcv = this.processConditionsComponent.getFormInformation();
    let unitsTypeAndEnthalpyValues: EnergySavingInputUnit[] = [];
    this.apiEnergySavingResponse = new EnergySavingOutput();

    for (var x = 0; x < this.apiSizingResponse.easiHeatGen4Units.length; x++) {
      if (!isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units[x].unitModel)) {
        unitsTypeAndEnthalpyValues.push({
          unitType: this.apiSizingResponse.easiHeatGen4Units[x].unitModel.substr(3, 2),
          massFlowSteam: this.apiSizingResponse.easiHeatGen4Units[x].steamMassFlow,
          massFlowSteamUnit: 0, // 0 until units are implemented
          BalancePressureWaterEnthalpy: this.apiSizingResponse.easiHeatGen4Units[x].balancePressureWaterEnthalpy,
          CVOutletEvaporationEnthalpy: this.apiSizingResponse.easiHeatGen4Units[x].cvOutletEvaporationEnthalpy,
          CVOutletWaterEnthalpy: this.apiSizingResponse.easiHeatGen4Units[x].cvOutletWaterEnthalpy,
          EnthalpyOfPrimarySideInletSteam: this.apiSizingResponse.easiHeatGen4Units[x].enthalpyOfPrimarySideInletSteam
        })
      }
    }

    let energySavingInput: EnergySavingInput = {

      //General User and Sizing informations
      userId : this.user.userId,
      userName : this.user.username,
      userCountry : this.user.operatingCompanyId,
      userOperatorType : "-", ////TODO UseOperatorType not used in sizing remove from model in later date
      userCurrency : this.sellingCurrency.id,
      applicationType : this.selectedUnitType.dhw ? 'DHW' : this.selectedUnitType.htgcc ? 'HTGCC' : 'HTGSC',

      // Values Specific for Energy Saving Calculation (user UI input)

      hoursOfOperation: pcv.hoursOfOperation,
      diversityFactor: pcv.diversityFactor,
      fuelType: pcv.fuelType,
      costOfCarbon: pcv.costOfCarbon,
      costOfCarbonUnit: +this.emissionUnitsRef.preference.value,
      energyUnitCost: pcv.energyUnitCost,
      energyUnitCostUnit: +this.energyUnitsRef.preference.value,
      waterUnitCost: pcv.waterUnitCost,
      waterUnitCostUnit: +this.volumeUnitsRef.preference.value,
      waterTreatmentUnitCost: pcv.waterTreatmentUnitCost,
      waterTreatmentUnitCostUnit: +this.volumeUnitsRef.preference.value,
      hotwellTemperature: pcv.hotwellTemperature,
      hotwellTemperatureUnit: +this.temperatureRef.preference.value,
      boilerEfficiency: pcv.boilerEfficiency,
      boilerPressure: pcv.boilerPressure,
      boilerPressureUnit: +this.pressureRef.preference.value,
      carbonEmmissionSavedPerYearUnit: +this.emissionUnitsRef.preference.value,
      energySavedPerYearUnit: +this.energyUnitsRef.preference.value,
      waterSavedPerYearUnit: +this.volumeUnitsRef.preference.value,
      emmissionCostReductionPerYearUnit: +this.emissionUnitsRef.preference.value,
      energyCostReductionPerYearUnit: +this.energyUnitsRef.preference.value,
      waterCostReductionPerYearUnit: +this.volumeUnitsRef.preference.value,

      //Values are coming from Sizing Api call.
      units: unitsTypeAndEnthalpyValues

    }

    this.sizingApi.calculateEnergySavings(energySavingInput).subscribe((energyResponse: EnergySavingOutput) => {

      if (energyResponse == null) {
        return;
      }
      else {
        this.resultsComponent.fuelName = pcv.fuelType;
        this.resultsComponent.setEnergySavingUnitTranslations(this.emissionUnitsRef, this.energyUnitsRef, this.volumeUnitsRef, this.temperatureRef);

        if (energyResponse.easiHeatGen4EnergySavingUnits.length == 1 && energyResponse.easiHeatGen4EnergySavingUnits[0].unitType != 'SC') {
          let updatedResponse = Array<EasiHeatGen4EnergySavingResultsUnit>();
          updatedResponse[0] = new EasiHeatGen4EnergySavingResultsUnit();
          updatedResponse[1] = energyResponse.easiHeatGen4EnergySavingUnits[0];
          energyResponse.easiHeatGen4EnergySavingUnits = updatedResponse;
          this.apiEnergySavingResponse = energyResponse;
        }
        else {
          this.apiEnergySavingResponse = energyResponse;
        }

        this.apiEnergySavingResponse = energyResponse;
        this.resultsComponent.displayEnergySavingResults(this.apiEnergySavingResponse);
      }
    })
  };

      //    this.setQuotationValidationMessage(new Date);

  onSaveJob() {
    // First, check if a job is already loaded?
    if (this.project && this.projectName && this.job && this.jobName) {
      //if (this.project != null && this.projectName !== '' && this.job != null && this.jobName !== '') {
      // Job already exists, so just update the sizing.
      return false;
    } else {

      return true;
    }
  }

  //onSave(savedProjectDetails: Project): JobSizing {
  //  return
  //}

  onSave(savedProjectDetails: Project): JobSizing {

    let jobSizing = new JobSizing;
    this.project = new Project;
    this.job = new Job;
    let sizingData = new SizingData;
    let processConditions = new Array<ProcessCondition>();
    let processInputs = new Array<ProcessInput>();
    let unitPreferences = new Array<Preference>();

    let outputGridRow = new OutputGridRow;
    let outputGridRows = new Array<OutputGridRow>();
    let outputItems = new Array<OutputItem>();
    let messages = new Array<OutputGridRowMessageItem>();
    if (!savedProjectDetails) {
      return null;
    }

    jobSizing = this.saveData(jobSizing, this.project, this.job, sizingData, processConditions, processInputs, unitPreferences, outputGridRow, outputGridRows, outputItems, messages);

    //save header details
    this.project.name = savedProjectDetails.name;
    this.project.customerName = savedProjectDetails.customerName;
    this.project.projectReference = savedProjectDetails.projectReference;
    // Update the local Project/Job header details for UI.
    this.projectName = savedProjectDetails.name;
    this.jobName = savedProjectDetails.jobs.length > 1 ? savedProjectDetails.jobs[1].name : savedProjectDetails.jobs[0].name;

    if (savedProjectDetails.jobs.length > 1) {
      // save as call
      this.job.name = savedProjectDetails.jobs[1].name;
      this.job.plantOwner = savedProjectDetails.jobs[1].plantOwner;
      this.job.lastExported = savedProjectDetails.jobs[1].lastExported;
    } else {
      // save call
      this.job.name = savedProjectDetails.jobs[0].name;
      this.job.plantOwner = savedProjectDetails.jobs[0].plantOwner;
      this.job.lastExported = savedProjectDetails.jobs[0].lastExported;
    }

    this.job.moduleId = 13;
    this.job.productName = "EasiheatGen4";
    this.moduleId = 13;
    this.productName = "EasiheatGen4";
    if (this.apiSizingResponse) {
      if (this.apiSizingResponse.easiHeatGen4Units.length > 0) {
        if (this.resultsComponent.resultsForm.get(`left.pricingOptions.unitSelected`).value == true || this.resultsComponent.resultsForm.get(`right.pricingOptions.unitSelected`).value == true) {
          this.job.jobStatusId = 3; //Selected
          this.jobStatusId = 3;
        }
        else {
          this.job.jobStatusId = 2; //Calculated
          this.jobStatusId = 2;
        }
      }
    } else {
      this.job.jobStatusId = 1; // Input
      this.jobStatusId = 1;
    }

    if (typeof savedProjectDetails.id === 'undefined') {
      this.project.id = ""; // new Guid required from API
      this.job.projectId = ""; // new Guid required from API
    } else {
      this.job.projectId = savedProjectDetails.id;
    }

    //save objects into appropriate sizing data objects
    this.project.jobs = new Array<Job>();
    this.project.jobs[0] = this.job;

    jobSizing.project = this.project;


    this.sizingModuleForm.markAsUntouched;

    return jobSizing;
  }

  saveData(jobSizing: JobSizing, project: Project, job: Job,
    sizingData: SizingData, processConditions: Array<ProcessCondition>,
    processInputs: Array<ProcessInput>, unitPreferences: Array<Preference>, outputGridRow: OutputGridRow, outputGridRows: Array<OutputGridRow>, outputItems: Array<OutputItem>, messages: Array<OutputGridRowMessageItem>): JobSizing {


    const pcv = this.processConditionsComponent.getFormInformation();

    //save shared process conditions
    processInputs.push({ name: "Application Type", value: this.selectedUnitType.dhw == true ? 'dhw' : this.selectedUnitType.htgcc == true ? 'htgcc' : 'htgsc', unitId: 0, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Inlet Pressure", value: pcv.inletPressure.toString(), unitId: parseInt(this.pressureRef.preference.value), listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Total Back Pressure", value: pcv.totalBackPressure.toString(), unitId: parseInt(this.pressureRef.preference.value), listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Inlet Temperature", value: pcv.inletTemperature.toString(), unitId: parseInt(this.temperatureRef.preference.value), listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Outlet Temperature", value: pcv.outletTemperature.toString(), unitId: parseInt(this.temperatureRef.preference.value), listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Water Flow Rate", value: pcv.waterFlowRate.toString(), unitId: parseInt(this.volumetricFlowRef.preference.value), listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Load", value: pcv.load.toString(), unitId: parseInt(this.loadRef.preference.value), listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Maximum Pressure Drop", value: pcv.maximumPressureDrop, unitId: this.processConditionsComponent.pressure_Drop_Unit == 'PSI' ? 45 : 39, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Global Variance", value: this.currentGlobalVariance, unitId: 0, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Install Country Gateway", value: pcv.installCountryGateway, unitId: 0, listItemId: null, value2: "", childInputs: null });

    processInputs.push({ name: "Hide HE Model From Sizing", value: String(this.modPref.hideHEmodelFromSizing), unitId: 0, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Motive Inlet Pressure Visible", value: String(this.processConditionsComponent.motiveInletPressureAvailable), unitId: 0, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Motive Inlet Pressure", value: !isNull(pcv.motiveInletPressure) ? pcv.motiveInletPressure.toString() : null, unitId: parseInt(this.pressureRef.preference.value), listItemId: null, value2: "", childInputs: null });

    processInputs.push({ name: "Noise Limit", value: pcv.noiseLimit, unitId: 0, listItemId: null, value2: "", childInputs: null });

    processInputs.push({ name: "Allow High Design Pressure Visible", value: String(this.processConditionsComponent.allowHighDesignPressure), unitId: 0, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Allow High Design Pressure", value: String(pcv.designPressure12Bar), unitId: 0, listItemId: null, value2: "", childInputs: null });

    processInputs.push({ name: "CV Split Range Visible", value: String(this.processConditionsComponent.cvSplitRangeAvailable), unitId: 0, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "CV Split Range Only", value: String(pcv.sizeCvSplitRangeOnly), unitId: 0, listItemId: null, value2: "", childInputs: null });

    //save Gateway Information
    processInputs.push({ name: "Gateway countryCode", value: this.processConditionsComponent.gatewayInfo.countryCode, unitId: 0, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Gateway isGatewayAvailable", value: String(this.processConditionsComponent.gatewayInfo.isGatewayAvailable), unitId: 0, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Gateway modelCode", value: this.processConditionsComponent.gatewayInfo.modelCode, unitId: 0, listItemId: null, value2: "", childInputs: null });
    processInputs.push({ name: "Gateway nomenclatureCode", value: this.processConditionsComponent.gatewayInfo.nomenclatureCode, unitId: 0, listItemId: null, value2: "", childInputs: null });

    if (pcv.performEnergySavingCalc) {

      processInputs.push({ name: "Perform Energy Saving Calc", value: String(pcv.performEnergySavingCalc), unitId: null, listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Hours Of Operation", value: pcv.hoursOfOperation.toString(), unitId: null, listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Diversity Factor", value: pcv.diversityFactor.toString(), unitId: null, listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Cost Of Carbon", value: pcv.costOfCarbon.toString(), unitId: null, listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Energy Unit Cost", value: pcv.energyUnitCost.toString(), unitId: null, listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Water Unit Cost", value: pcv.waterUnitCost.toString(), unitId: null, listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Water Treatment Unit Cost", value: pcv.waterTreatmentUnitCost.toString(), unitId: null, listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Hotwell Temperature", value: pcv.hotwellTemperature.toString(), unitId: parseInt(this.temperatureRef.preference.value), listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Boiler Efficiency", value: pcv.boilerEfficiency.toString(), unitId: null, listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Boiler Pressure", value: pcv.boilerPressure.toString(), unitId: parseInt(this.pressureRef.preference.value), listItemId: null, value2: "", childInputs: null });
      processInputs.push({ name: "Fuel Type", value: pcv.fuelType.toString(), unitId: 0, listItemId: null, value2: "", childInputs: null });
    }
    else {
      processInputs.push({ name: "Perform Energy Saving Calc", value: String(pcv.performEnergySavingCalc), unitId: null, listItemId: null, value2: "", childInputs: null });
    }


    processInputs.push({ name: "Is EasiHeat Sizing Done", value: String(this.isEasiHeatSizingDone), unitId: 0, listItemId: null, value2: "", childInputs: null });

    // Create Maximum Pressure Drop Preference
    var maxPressureDropPref = new Preference();
    maxPressureDropPref.name = "MaxPressureDropUnit";
    maxPressureDropPref.decimalPlaces = 2;
    maxPressureDropPref.isUnit = true;
    maxPressureDropPref.masterTextKey = this.processConditionsComponent.pressure_Drop_Enumeration_Name;
    maxPressureDropPref.unitName = this.processConditionsComponent.pressure_Drop_Unit;
    maxPressureDropPref.value = this.processConditionsComponent.pressure_Drop_Unit == 'PSI' ? "45" : "39";

    // Save unit preferences.
    unitPreferences.push(this.pressureRef.preference);
    unitPreferences.push(this.temperatureRef.preference);
    unitPreferences.push(this.volumetricFlowRef.preference);
    unitPreferences.push(this.loadRef.preference);
    unitPreferences.push(this.volumeUnitsRef.preference);
    unitPreferences.push(this.energyUnitsRef.preference);
    unitPreferences.push(this.emissionUnitsRef.preference);

    unitPreferences.push(maxPressureDropPref);

    processConditions.push({ name: this.jobName, processInputs: processInputs, unitPreferences: unitPreferences });

    sizingData.processConditions = new Array<ProcessCondition>();
    sizingData.processConditions = processConditions;

    //save sizing grid results
    outputGridRow.outputItems = [];
    outputGridRow.messages = [];

    if (!isNullOrUndefined(this.apiSizingResponse)) {
      if (!isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units)) {

        for (var x = 0; x < this.apiSizingResponse.easiHeatGen4Units.length; x++) {

          var obj = this.apiSizingResponse.easiHeatGen4Units[x];
          var sizingDataSide = x == 0 ? 'left sizing data' : 'right sizing data';

          if (isNullOrUndefined(obj.unitModel)) {
            continue;
          }

          //Save messages from Sizing 
          if (obj.modelSizingMessages.length > 0) {
            obj.modelSizingMessages.forEach(msg => {

              outputGridRow.messages.push({
                messageKey: msg.messageKey,
                value: msg.value,
                unitKey: msg.unitKey,
                severity: msg.severity,
                displayValue: msg.displayValue,
                additionalMessage: ''
              });
            });
          }
          else { outputGridRow.messages = []; }

          outputGridRow.outputItems.push({
            name: "sizingNomenclature",
            value: obj.nomenclature.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "subCooling", //subCooling
            value: obj.subCooling.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "numberOfPlates", //Number Of Plates
            value: obj.numberOfPlates.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "primaryPressureDrop", //primaryPressureDrop
            value: obj.primaryPressureDrop.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "secondaryPressureDrop", //Actual Secondary Pressure Drop
            value: obj.secondaryPressureDrop.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamCvSize", //Control Valve Size Dn
            value: obj.steamCvSize.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamCvSizeAnsi", //Control Valve Size Ansi
            value: obj.steamCvSizeAnsi.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamCvKv", //Steam Cv Kv
            value: obj.steamCvKv.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamCvRequiredKv", //steamCvRequiredKv
            value: obj.steamCvRequiredKv.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamCvSplitRangeKv", //steamCvSplitRangeKv
            value: obj.steamCvSplitRangeKv.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamCvCalculatedNoiseLevel", //Calculated Noise Level
            value: obj.steamCvCalculatedNoiseLevel.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamCvInletDensity", //steamCvInletDensity
            value: obj.steamCvInletDensity.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamCvOutletDensity", //steamCvOutletDensity
            value: obj.steamCvOutletDensity.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamValveCalculatedExitVelocityMetersPerSecond", //steamValveCalculatedExitVelocityMetersPerSecond
            value: obj.steamValveCalculatedExitVelocityMetersPerSecond.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "oversurfacing", //oversurfacing
            value: obj.oversurfacing.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "primaryVelocity", //primaryVelocity
            value: obj.primaryVelocity.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "secondaryVelocity", //secondaryVelocity
            value: obj.secondaryVelocity.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "heOutletPressure", //heOutletPressure
            value: obj.heOutletPressure.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "trimType", //Trim Type
            value: obj.trimType.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "height",
            value: obj.height.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "width",
            value: obj.width.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "length",
            value: obj.length.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "weight",
            value: obj.weight.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "condensateRemoval",
            value: obj.condensateRemoval.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "condensateValveDnSize", //Condensate Valve Size Dn
            value: obj.condensateValveDnSize.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "condensateValveAnsiSize", //Condensate Valve Size Ansi
            value: obj.condensateValveAnsiSize.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "condensateValveTrim", //Condensate Valve Trim
            value: !isNull(obj.condensateValveTrim).toString() ? obj.condensateValveTrim.toString() : 'null',
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "condensateValveKv", //Condensate Valve Kv
            value: obj.condensateValveKv.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "condensateValveRequiredKv", //condensateValveRequiredKv
            value: obj.condensateValveRequiredKv.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "grossMargin",
            value: obj.grossMargin.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "hePressure", //hePressure
            value: obj.hePressure.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "unitPrice", //unitPrice
            value: obj.unitPrice.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "drawingFile",
            value: !isNull(obj.drawingFile) ? obj.drawingFile.toString() : 'null',
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "drawingOnlineLink",
            value: !isNull(obj.drawingOnlineLink) ? obj.drawingOnlineLink.toString() : 'null',
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "drawingOfflineLink",
            value: !isNull(obj.drawingOfflineLink) ? obj.drawingOfflineLink.toString() : 'null',
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "tiFile",
            value: !isNull(obj.tiFile) ? obj.tiFile.toString() : 'null',
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "tiOfflineLink",
            value: !isNull(obj.tiOfflineLink) ? obj.tiOfflineLink.toString() : 'null',
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "tiOnlineLink",
            value: !isNull(obj.tiOnlineLink) ? obj.tiOnlineLink.toString() : 'null',
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "hePrice", //hePrice
            value: obj.hePrice.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "heWeight",
            value: obj.heWeight.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "heatExchangerPlateType", //Heat Exchange Plate Type
            value: obj.heatExchangerPlateType.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "channelTypeSide1", //channelTypeSide1
            value: obj.channelTypeSide1.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "channelTypeSide2", //channelTypeSide2
            value: obj.channelTypeSide2.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "connectionStandard", //connectionStandard
            value: obj.connectionStandard.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "numberOfChannelsSide1", //numberOfChannelsSide1
            value: obj.numberOfChannelsSide1.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "numberOfChannelsSide2", //numberOfChannelsSide2
            value: obj.numberOfChannelsSide2.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "unitModel", //EasiHeatGen4 Unit
            value: obj.unitModel.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "steamMassFlow", //steamMassFlow
            value: obj.steamMassFlow.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "nomenclatureChannelType",
            value: obj.nomenclatureChannelType.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "heFrameType",
            value: obj.heFrameType.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "htgCcInletPipeVeloctiyOver40ms", //htgCcInletPipeVeloctiyOver40ms
            value: obj.htgCcInletPipeVeloctiyOver40ms.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "epdmwGasketPressureCheck",
            value: obj.epdmwGasketPressureCheck.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          outputGridRow.outputItems.push({
            name: "maxCondensateTemp", //Maximum Condensate Temperature //////////////////////////////// THIS VALUE IS HARD CODED VALUE  !!!!!!!!!!!!! IT DOSEN'T COME FROM SIZING RESPONSE !!!!!!!!!!!!!!!
            value: '95',
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



          /////////////////////   PRICING OBJECT STARTS HERE   ////////////////////   PRICING OBJECT STARTS HERE   ///////////////////////   PRICING OBJECT STARTS HERE   ////////////////////////////////
          //   ||      ||      ||
          //   ||      ||      ||
          //  \  /    \  /    \  /
          //   \/      \/      \/
          let pricingObj = this.apiPricingResponse[x]

          outputGridRow.outputItems.push({
            name: "totalSSPPrice",
            value: pricingObj.totalSSPPrice.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "totalRecommendedSalesPrice",
            value: pricingObj.totalRecommendedSalesPrice.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "inletSize",// UI field name => "Package Inlet Connection Size",
            value: pricingObj.inletSize.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "condensatePipeSize",// UI field name => "Condensate Pipe Size",
            value: pricingObj.condensatePipeSize.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "inletOutletPipeSize",// UI field name => "Secondary Inlet Outlet Pipe Size",
            value: pricingObj.inletOutletPipeSize.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "inletSizeAnsi",// UI field name => "Package Inlet Connection Size Ansi",
            value: pricingObj.inletSizeAnsi.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "condensatePipeSizeAnsi",// UI field name => "Condensate Pipe Size Ansi",
            value: pricingObj.condensatePipeSizeAnsi.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "inletOutletPipeSizeAnsi",// UI field name => "Secondary Inlet Outlet Pipe Size Ansi",
            value: pricingObj.inletOutletPipeSizeAnsi.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "pricingNomenclature",
            value: pricingObj.nomenclature.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "currency",
            value: pricingObj.currency.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });

          outputGridRow.outputItems.push({
            name: "fa11Possible",
            value: pricingObj.fa11Possible.toString(),
            unitId: null,
            selected: false,
            listItemId: null,
            type: sizingDataSide
          });



          //Save messages from Pricing Api
          if (!isNullOrUndefined(pricingObj.unitPricingMessages)) {
            if (pricingObj.unitPricingMessages.length > 0) {
              if (obj.modelSizingMessages.length > 0) {
                pricingObj.unitPricingMessages.forEach(msg => {

                  obj.modelSizingMessages.forEach(sizingMsg => {
                    if (sizingMsg.messageKey !== msg.messageKey) {
                      outputGridRow.messages.push({
                        messageKey: msg.messageKey,
                        value: msg.value,
                        unitKey: msg.unitKey,
                        severity: msg.severity,
                        displayValue: msg.displayValue,
                        additionalMessage: ''
                      })
                    }
                  })
                });
              } else {
                pricingObj.unitPricingMessages.forEach(msg => {
                  outputGridRow.messages.push({
                    messageKey: msg.messageKey,
                    value: msg.value,
                    unitKey: msg.unitKey,
                    severity: msg.severity,
                    displayValue: msg.displayValue,
                    additionalMessage: ''
                  })
                });
              }
            }
          };

          /////////////////////   ENERGY SAVING OBJECT STARTS HERE   ////////////////////   ENERGY SAVING OBJECT STARTS HERE   ///////////////////////   ENERGY SAVING OBJECT STARTS HERE   ////////////////////////////////
          //   ||      ||      ||
          //   ||      ||      ||
          //  \  /    \  /    \  /
          //   \/      \/      \/

          // First check if the energy saving object is available. If not then that means the energy saving is not performed.
          if (pcv.performEnergySavingCalc) {
            if (this.apiEnergySavingResponse) {
              if (this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[x]) {

                let energySavingObj = this.apiEnergySavingResponse.easiHeatGen4EnergySavingUnits[x]

                outputGridRow.outputItems.push({
                  name: "co2eEmmissionReductionPerYear",
                  value: energySavingObj.co2eEmmissionReductionPerYear.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "co2eEmmissionCostReductionPerYear",
                  value: energySavingObj.co2eEmmissionCostReductionPerYear.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "energySavedPerYear",
                  value: energySavingObj.energySavedPerYear.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "energyCostReductionPerYear",
                  value: energySavingObj.energyCostReductionPerYear.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "waterSavedPerYear",
                  value: energySavingObj.waterSavedPerYear.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "waterCostReductionPerYear",
                  value: energySavingObj.waterCostReductionPerYear.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "steamConsumptionReductionPercent",
                  value: energySavingObj.steamConsumptionReductionPercent.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "steamMassSaved",
                  value: energySavingObj.steamMassSaved.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "totalEvcCostReductionPerYear",
                  value: energySavingObj.totalEvcCostReductionPerYear.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "unitType",
                  value: energySavingObj.unitType.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                //save fuel info
                outputGridRow.outputItems.push({
                  name: "fuelCalorificValue",
                  value: this.apiEnergySavingResponse.fuelCalorificValue.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "fuelCalorificValueUnit",
                  value: this.apiEnergySavingResponse.fuelCalorificValueUnit.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "fuelCO2eEmissions",
                  value: this.apiEnergySavingResponse.fuelCO2eEmissions.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });

                outputGridRow.outputItems.push({
                  name: "fuelCO2eEmmissionUnit",
                  value: this.apiEnergySavingResponse.fuelCO2eEmmissionUnit.toString(),
                  unitId: null,
                  selected: false,
                  listItemId: null,
                  type: sizingDataSide
                });
              }
            }
          }
          

         
           outputGridRows.push(outputGridRow);

          //clear for next iteration
          outputGridRow = new OutputGridRow();
          outputGridRow.outputItems = [];
          outputGridRow.messages = [];

        }

        if (!isNullOrUndefined(this.sizingModuleForm.get('specsheet').value)) {
          let specSheet: HeaderDetails;
          specSheet = this.sizingModuleForm.get('specsheet').value;

          Object.entries(specSheet).forEach(([key, value]) =>
            outputGridRows[0].outputItems.push({
              name: key,
              value: value,
              unitId: null,
              selected: false,
              listItemId: null,
              type: "specSheetHeader"
            })
          );

        } else {

          let specSheet: HeaderDetails = { sheet: "", quantity: "", revisionNumber: "", aoNumber: "", projectType: "", orderNumber: "", notes: "" };
          Object.entries(specSheet).forEach(([key, value]) =>
            outputGridRows[0].outputItems.push({
              name: key,
              value: value,
              unitId: null,
              selected: false,
              listItemId: null,
              type: "specSheetHeader"
            })
          );
        }

      }
    }

    //Saving Spcification Sheet Header Details


    if (this.isEasiHeatSizingDone) {

      this.leftOptions = this.resultsComponent.getAllUnitOptionsValue('left').options;
      this.rightOptions = this.resultsComponent.getAllUnitOptionsValue('right').options;

      //save unit options
      this.leftOptions.forEach(opt =>
        outputItems.push({
          name: opt.EnumerationName,
          value: opt.SelectedValue,
          unitId: null,
          selected: false,
          type: 'left unit options',
          listItemId: null
        })
      );

      this.rightOptions.forEach(opt =>
        outputItems.push({
          name: opt.EnumerationName,
          value: opt.SelectedValue,
          unitId: null,
          selected: false,
          type: 'right unit options',
          listItemId: null
        })
      );

      //save unit options drop down value selections availability
      for (let x = 0; x < 2; x++) {



        let availableOptionsString = '';
        this.resultsComponent.gasketMaterial_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "gasketMaterial_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.actuator_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "actuator_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.condensateRemoval_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "condensateRemoval_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.highLimit_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "highLimit_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.manualIsolation_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "manualIsolation_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.systemRecirculation_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "systemRecirculation_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.controlSystem_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "controlSystem_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.frameType_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "frameType_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.packageSupports_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "packageSupports_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.panelLocation_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "panelLocation_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.extras_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "extras_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.integrityTest_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "integrityTest_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.dataCollection_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "dataCollection_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.controlValveFeedback_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "controlValveFeedback_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.ta11_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "ta11_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.ta31_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "ta31_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.ta41_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "ta41_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.ta01_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "ta01_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.pa31_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "pa31_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.fa11_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "fa11_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.ups_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "ups_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.communications_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "communications_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

        availableOptionsString = '';
        this.resultsComponent.insulation_FilterLists[x].forEach(option => availableOptionsString += option + ',');
        outputItems.push({
          name: "insulation_FilterLists",
          value: availableOptionsString,
          unitId: null,
          selected: false,
          type: (x == 0 ? 'left ' : 'right ') + 'filter list',
          listItemId: null
        })

      }// end of for loop for filter lists


      //save pricing options details
      const leftKeys = Object.keys(this.leftPricingOptionsValues);

      if (!leftKeys.find(x => x == 'unitSelected')) { //this handle the case when only one unit is being returned and the field 'unitSelected' is directly disabled threrefore it is not available in the form
        outputItems.push({
          name: 'unitSelected',
          value: 'true',
          unitId: null,
          selected: false,
          type: 'left pricing values',
          listItemId: null
        });
      }

      for (const key of leftKeys) {
        const val = this.leftPricingOptionsValues[key];
        outputItems.push({
          name: key,
          value: val,
          unitId: null,
          selected: false,
          type: 'left pricing values',
          listItemId: null
        });
      }

      const rightKeys = Object.keys(this.rightPricingOptionsValues);

      if (!rightKeys.find(x => x == 'unitSelected')) { //this handle the case when only one unit is being returned and the field 'unitSelected' is directly disabled threrefore it is not available in the form
        outputItems.push({
          name: 'unitSelected',
          value: 'true',
          unitId: null,
          selected: false,
          type: 'right pricing values',
          listItemId: null
        });
      }
      for (const key of rightKeys) {
        const val = this.rightPricingOptionsValues[key];
        outputItems.push({
          name: key,
          value: val,
          unitId: null,
          selected: false,
          type: 'right pricing values',
          listItemId: null
        });
      }

      const serviceOfferingEnumerations = this.resultsComponent.selectedEnumerationNames.service_Offering;
      outputItems.push({
        name: serviceOfferingEnumerations[0],
        value: this.resultsComponent.leftServiceOffering.internalValue,
        unitId: null,
        selected: false,
        type: 'left pricing values',
        listItemId: null
      });

      outputItems.push({
        name: serviceOfferingEnumerations[1],
        value: this.resultsComponent.rightServiceOffering.internalValue,
        unitId: null,
        selected: false,
        type: 'right pricing values',
        listItemId: null

      });

      //save modePref object
      const keys = Object.keys(this.modPref);
      for (const key of keys) {
        const val = this.modPref[key];
        outputItems.push({
          name: key,
          value: val,
          unitId: null,
          selected: false,
          listItemId: null,
          type: 'modPref'
        });
      }

      //save currency symbol
      outputItems.push({
        name: 'currencySymbol',
        value: this.resultsComponent.currencySymbol[0],
        unitId: null,
        selected: false,
        listItemId: null,
        type: 'modPref'
      });
    }



    // Reset button status
    outputItems.push({ name: "IsResetEnabled", value: (!this.sizingModuleForm.pristine).toString(), unitId: null, selected: false, listItemId: null, type: null });

    //save objects into appropriate sizing data objects
    jobSizing.sizingData = sizingData;
    jobSizing.sizingData.sizingOutput = new SizingOutput;
    jobSizing.sizingData.sizingOutput.outputItems = outputItems;
    jobSizing.sizingData.sizingOutput.outputGrid = new OutputGrid;
    jobSizing.sizingData.sizingOutput.outputGrid.outputGridRows = outputGridRows;

    return jobSizing;
  }

  repackageSizing() {

    let jobSizing = new JobSizing;
    let sizingData = new SizingData;
    let processConditions = new Array<ProcessCondition>();
    let processInputs = new Array<ProcessInput>();
    let unitPreferences = new Array<Preference>();
    let outputItems = new Array<OutputItem>();
    let messages = new Array<OutputGridRowMessageItem>();
    let outputGridRow = new OutputGridRow;
    let outputGridRows = new Array<OutputGridRow>();

    if (isUndefined(this.project.id) && this.existingProjectInfo.existingProjectSave == true) {
      this.project = this.existingProjectInfo;
      this.job = this.existingProjectInfo.jobs.find(job => isUndefined(job.id));
    }

    jobSizing = this.saveData(jobSizing, this.project, this.job, sizingData, processConditions, processInputs, unitPreferences, outputGridRow, outputGridRows, outputItems, messages);

    jobSizing.project = new Project;
    jobSizing.project.jobs = new Array<Job>();
    jobSizing.project.jobs[0] = this.job;

    if (this.apiSizingResponse) {
      if (!isNullOrUndefined(this.apiSizingResponse.easiHeatGen4Units)) {
        if (this.apiSizingResponse.easiHeatGen4Units.length > 0) {
          if (this.resultsComponent.resultsForm.get(`left.pricingOptions.unitSelected`).value == true || this.resultsComponent.resultsForm.get(`right.pricingOptions.unitSelected`).value == true) {
            jobSizing.jobStatusId = 3; //Selected
            this.jobStatusId = 3;
          }
          else {
            jobSizing.jobStatusId = 2; //Calculated
            this.jobStatusId = 2;
          }
        }
      }
      else {
        jobSizing.jobStatusId = 1; // Input
        this.jobStatusId = 1;
      }
    } else {
      jobSizing.jobStatusId = 1; // Input
      this.jobStatusId = 1;
    }

    jobSizing.project.jobs[0].id = (!!this.jobId ? this.jobId : "");

    this.projectsJobsService.updateJobSizing(jobSizing).subscribe((response: GetSizingJobRequest) => {

      this.jobId = response.toString();

      if (!!response) {
        this.saveJobSuccess = true;
      } else {
        this.saveJobSuccess = false;
      }

      this.sizingModuleForm.markAsPristine();
      this.sizingModuleForm.markAsUntouched();
    });
  }


  onUnitsChanged() {
    // Re-validate all the inputs.
    this.processConditionsComponent.processConditionsFormGroup.get('inletPressure').updateValueAndValidity();
    this.processConditionsComponent.processConditionsFormGroup.get('totalBackPressure').updateValueAndValidity();
    this.processConditionsComponent.processConditionsFormGroup.get('inletTemperature').updateValueAndValidity();
    this.processConditionsComponent.processConditionsFormGroup.get('inletTemperature').markAsTouched();
    if (this.processConditionsComponent.processConditionsFormGroup.get('hotwellTemperature').enabled) {
      this.processConditionsComponent.processConditionsFormGroup.get('hotwellTemperature').updateValueAndValidity();
    }
  }

  onResetModuleForm() {
    this.sizingModuleForm.reset();
    this.processConditionsComponent.setUiFieldsVisibility();
    this.processConditionsComponent.resetProcessConditionsFormGroup();
    this.processConditionsComponent.processConditionsFormGroup.get('designPressure12Bar').patchValue(JSON.parse('false'), { emitEvent: false });
    this.sizingModuleForm.get('processConditions.installCountryGateway').patchValue(this.user.countryCode.toUpperCase(), { emitEvent: false });
    this.processConditionsComponent.selectedUnitType();
    this.processConditionsComponent.processConditionsFormGroup.get('noiseLimit').patchValue('85', { emitEvent: false });
    this.processConditionsComponent.pressure_Drop_Unit = this.initialGlobalVariance !== 'AS/UL' ? 'KPA' : 'PSI';
    this.processConditionsComponent.setEnergySavingDefaults();
    this.sizingModuleForm.get('specsheet').patchValue({ sheet: "", quantity: "", revisionNumber: "", aoNumber: "", projectType: "", orderNumber: "", notes: "" });
    this.resultsComponent.globalVariance = this.initialGlobalVariance;
    this.resetSizingResults();
  }

  resetSizingResults() {
    if (this.isEasiHeatSizingDone && this.apiSizingResponse && !this.resultsComponent.isLoadingJob) {

      this.resultsComponent.resetUnitOptionPreferencesToTheirOriginalSettings();
      this.resultsComponent.resultsForm.reset();
      this.resultsComponent.energySavingSubPanelLeft = 'energySavingSubPanelEnabled';
      this.resultsComponent.energySavingSubPanelRight = 'energySavingSubPanelEnabled';
      this.messagesService.addMessageEhGen4(null);
      this.isEasiHeatSizingDone = false;
      this.apiSizingResponse = new EasiheatGen4Output();
      this.apiPricingResponse = [];
      this.apiEnergySavingResponse = new EnergySavingOutput();
      this.userCondensate = false;
      this.messagesService.clearMessages();
      this.resultsComponent.resultsForm.enable();
      this.resultsComponent.serviceOfferingKey = [];
      this.resultsComponent.unitTypeHeaderName = [];
      this.resultsComponent.firstSelectedUnitCalcs = '';
      this.resultsComponent.secondSelectedUnitCalcs = '';
      this.resultsComponent.clearEnumerationsFilterLists();
      this.resultsComponent.clearEnergySavingUIvalues();
      this.resultsComponent.initilaPricing = true;
      this.resultsComponent.performEnergySavingCalc = false;
    }
  }

  onNewSizingForm() {
    this.projectName = null;
    this.projectId = null;
    this.jobName = null;
    this.jobId = null;
    this.onResetModuleForm();
  }

  onEnterHeaderDetailsForm() {

  }


  updateSpecificationSheetValues($event?) {
    this.specificationSheetValuesCollection = $event;
  }

  updateSpecSheetHeaderDetailsChanged() {
    if (isUndefined(this.specificationSheetValuesCollection)) {
      this.specificationSheetValuesCollection = new SpecificationSheetValuesCollection();
    }
    this.specificationSheetValuesCollection.specSheetHeaders = this.sizingModuleForm.get('specsheet').value;
  }

  getAppTypeFullName(unitType: string): string {
    var appName: string;

    switch (unitType) {

      case "EHDSC":
        appName = "Domestic Hot Water Steam Control"
        return appName;

      case "EHDDC":
        appName = "Domestic Hot Water Dual Control"
        return appName;

      case "EHHSC":
        appName = "Heating Steam Control"
        return appName;

      case "EHHCC":
        appName = "Heating Condensate Control"
        return appName;

      case "EHHDC":
        appName = "Heating Dual Control"
        return appName;
    }
  }

  onPdfSubmit() {

    if (!this.resultsComponent.resultsForm.get('left.pricingOptions.unitSelected').value && !this.resultsComponent.resultsForm.get('right.pricingOptions.unitSelected').value) {
      // Simple popup message box
      let infoHeader = this.translatePipe.transform('INFO', false);
      let message = this.translatePipe.transform('PLEASE_CHOOSE_UNIT_FIRST', false);
      let firstUnitCode = this.resultsComponent.resultsForm.get('left.easiHeatUnit').value;
      let firstUnitTypeName = this.resultsComponent.unitTypeHeaderName[0];
      let secondUnitCode = this.resultsComponent.resultsForm.get('right.easiHeatUnit').value;
      let secondUnitTypeName = this.resultsComponent.unitTypeHeaderName[1];
      swal({
        //styling sweet alert is done in file ssv2-ws\projects\ssv2\src\index.html - according to online documentation that's the only way to do it
        title: infoHeader + ':',
        text: message,
        icon: "info",
        dangerMode: true,
        buttons: {
          firstUnitBt: {
            text: firstUnitTypeName + ' (' + firstUnitCode + ')',
            value: 1,
          },
          secondUnitBt: {
            text: secondUnitTypeName + ' (' + secondUnitCode + ')',
            value: 2,
          },
          cancelBt: {
            text: 'Cancel',
            value: 3,
          }
        },
      }).then((value) => {

        switch (value) {
          case 1:
            console.info('user selected: ' + firstUnitTypeName + '(' + firstUnitCode + ')');
            this.resultsComponent.resultsForm.get('left.pricingOptions.unitSelected').patchValue(true, { emitEvent: true });
            this.generateSpecSheet();
            break;

          case 2:
            console.info('user selected: ' + secondUnitTypeName + '(' + secondUnitCode + ')');
            this.resultsComponent.resultsForm.get('right.pricingOptions.unitSelected').patchValue(true, { emitEvent: true });
            this.generateSpecSheet();
            break;

          case 3:
            console.info('user canceled selection');
            break;
        }
      });
      return
    }
    else this.generateSpecSheet();
  }

  generateSpecSheet() {
    const ssv = this.specificationSheetValuesCollection;

    this.docGen = new DocGen;
    this.docGen.specItems = new Array<SpecSheetItem>();
    this.docGen.moduleId = 13;
    this.docGen.template = "pdf";
    this.docGen.headerImage = "sxsLogo.jpg";
    this.docGen.bodyImage = "";
    this.docGen.userPreference = new Array<Preference>();

    for (let userPref of this.userPrefs) {

      this.docGen.userPreference.push(userPref);
    }

    // Pass data only, labels are retrieved from database in Doc Gen dll.
    this.setSpecSheetValues(ssv);

    this.easiheatDocGenService.getEasiHeatPdf(this.docGen);

    let options = {
      headers: {
        "Content-Type": "application/json", Accept: "application/octet-stream"
      },
      responseType: 'json' as 'json'
    };
  }

  setSpecSheetValues(data: SpecificationSheetValuesCollection) {
    // ToDo: All usage of translatePipe.transform here will be wrong if the
    // SpecSheet language is not the same as the UI language. Effects all sizing modules!
    var currentDate = new Date();
    const globalVariance = this.currentGlobalVariance;

      var projName = this.projectId != "" ? this.project.name : "-";
      var projCusName = this.projectId != "" ? this.project.customerName : "-";
      var projRef = this.projectId != "" ? this.project.projectReference : "-";
      var jobName = this.jobId != "" ? this.job.name : "-";
      var projLocation = this.projectId != "" ? this.project.customerLocation : "-";
      var projQuoteRef = this.projectId != "" ? this.project.quoteReference : "-";

    var applicationTypeFullName = this.getAppTypeFullName(data.selectedUnit.unitOptions.nomenclature.toString().substr(0, 5));

    var decimalPlaces = this.userPrefs.find(m => m.name === "PressureUnit").decimalPlaces;
    var hePressure = "-";// (applicationType == 'HTGCC') ? "-" : this.ehOutputData[0].hePressure.toFixed(decimalPlaces).toString();

    //Check and Get Spec Sheet Headers Data if Available
    if (!isNull(data.specSheetHeaders)) {
      this.docGen.specItems.push({ name: 'Sheet', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: data.specSheetHeaders.sheet == null ? "-" : data.specSheetHeaders.sheet, calculation: "" });
      this.docGen.specItems.push({ name: 'Revision No', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: data.specSheetHeaders.revisionNumber == null ? "-" : data.specSheetHeaders.revisionNumber, calculation: "" });
      this.docGen.specItems.push({ name: 'Quantity', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: data.specSheetHeaders.quantity == null ? "-" : data.specSheetHeaders.quantity, calculation: "" });
      this.docGen.specItems.push({ name: 'AO Number', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: data.specSheetHeaders.aoNumber == null ? "-" : data.specSheetHeaders.aoNumber, calculation: "" });
      this.docGen.specItems.push({ name: 'Order No', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: data.specSheetHeaders.orderNumber == null ? "-" : data.specSheetHeaders.orderNumber, calculation: "" });
      this.docGen.specItems.push({ name: 'Project Type', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: data.specSheetHeaders.projectType == null ? "-" : data.specSheetHeaders.projectType, calculation: "" });
      this.docGen.specItems.push({ name: 'Note 1', type: 'Section', masterTextKey: '', sectionName: 'Notes', targetLanguage: this.specSheetLanguage, value: data.specSheetHeaders.notes == null ? "-" : data.specSheetHeaders.notes, calculation: "" });
    }
    else {
      this.docGen.specItems.push({ name: 'Sheet', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: "-", calculation: "" });
      this.docGen.specItems.push({ name: 'Revision No', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: "-", calculation: "" });
      this.docGen.specItems.push({ name: 'Quantity', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: "-", calculation: "" });
      this.docGen.specItems.push({ name: 'AO Number', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: "-", calculation: "" });
      this.docGen.specItems.push({ name: 'Order No', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: "-", calculation: "" });
      this.docGen.specItems.push({ name: 'Project Type', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: "-", calculation: "" });
      this.docGen.specItems.push({ name: 'Note 1', type: 'Section', masterTextKey: '', sectionName: 'Notes', targetLanguage: this.specSheetLanguage, value: "-", calculation: "" });
    }

    this.docGen.specItems.push({ name: 'Date', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: currentDate.toDateString(), calculation: "" });
    this.docGen.specItems.push({ name: 'Quotation Ref', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: projQuoteRef, calculation: "" });
    this.docGen.specItems.push({ name: 'Prepared By', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: this.user.firstname + ' ' + this.user.lastname, calculation: "" });
    this.docGen.specItems.push({ name: 'Email', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: this.user.email, calculation: "" });
    this.docGen.specItems.push({ name: 'Telephone', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: this.user.telephone, calculation: "" });
    this.docGen.specItems.push({ name: 'Customer', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: projCusName, calculation: "" });
    this.docGen.specItems.push({ name: 'Location', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: projLocation, calculation: "" });
    this.docGen.specItems.push({ name: 'Project Name', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: projName, calculation: "" });
    this.docGen.specItems.push({ name: 'Project Ref', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: projRef, calculation: "" });
    this.docGen.specItems.push({ name: 'Job Name', type: 'Header', masterTextKey: '', sectionName: '', targetLanguage: this.specSheetLanguage, value: jobName, calculation: "" });

    //Process Data
    this.docGen.specItems.push({ name: 'ApplicationType', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: applicationTypeFullName, calculation: "" });

    this.docGen.specItems.push({ name: 'PrimarySide', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: "Primary Side (Steam)", calculation: "" });
    this.docGen.specItems.push({ name: 'SecondarySide', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: "Secondary Side (Water)", calculation: "" });

    this.docGen.specItems.push({ name: 'InletPressure', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.processConditions.inletPressure.toString() /*this.inputPRV ? this.packageinletpressure.value + " / " + this.inletpressure.value : this.inletpressure.value*/, calculation: "" });
    this.docGen.specItems.push({ name: 'InletPressureUnit', type: 'Unit', masterTextKey: this.pressureRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.pressureRef.preference.unitName, calculation: "" });

    this.docGen.specItems.push({ name: 'CVInletPressure', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: "0.11"/*applicationType == "HTGCC" ? "-" : this.inletpressure.value*/, calculation: "" });
    this.docGen.specItems.push({ name: 'CVInletPressureUnit', type: 'Unit', masterTextKey: this.pressureRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.pressureRef.preference.unitName, calculation: "" });

    this.docGen.specItems.push({ name: 'HEInletPressure', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: hePressure, calculation: "" });
    this.docGen.specItems.push({ name: 'HEInletPressureUnit', type: 'Unit', masterTextKey: this.pressureRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.pressureRef.preference.unitName, calculation: "" });

    this.docGen.specItems.push({ name: 'TotalBackPressure', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.processConditions.totalBackPressure.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'TotalBackPressureUnit', type: 'Unit', masterTextKey: this.pressureRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.pressureRef.preference.unitName, calculation: "" });

    this.docGen.specItems.push({ name: 'InletTemperature', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.processConditions.inletTemperature.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'InletTemperatureUnit', type: 'Unit', masterTextKey: this.temperatureRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.temperatureRef.preference.unitName, calculation: "" });

    this.docGen.specItems.push({ name: 'OutletTemperature', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.processConditions.outletTemperature.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'OutletTemperatureUnit', type: 'Unit', masterTextKey: this.temperatureRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.temperatureRef.preference.unitName, calculation: "" });

    this.docGen.specItems.push({ name: 'WaterFlowRate', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.processConditions.waterFlowRate.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'WaterFlowRateUnit', type: 'Unit', masterTextKey: this.volumetricFlowRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.volumetricFlowRef.preference.unitName, calculation: "" });

    this.docGen.specItems.push({ name: 'Load', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.processConditions.load.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'LoadUnit', type: 'Unit', masterTextKey: this.loadRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.loadRef.preference.unitName, calculation: "" });

    this.docGen.specItems.push({ name: 'Feed water pressure unit', type: 'Unit', masterTextKey: this.pressureRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.pressureRef.preference.unitName, calculation: "" });
    this.docGen.specItems.push({ name: 'Feed water temperature unit', type: 'Unit', masterTextKey: this.temperatureRef.preference.masterTextKey, sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.temperatureRef.preference.unitName, calculation: "" });

    this.docGen.specItems.push({ name: 'NoiseLimit', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.steamCvCalculatedNoiseLevel.toString().substring(0, data.selectedUnit.steamCvCalculatedNoiseLevel.toString().length - 3), calculation: "" });
    this.docGen.specItems.push({ name: 'NoiseLimitUnit', type: 'Unit', masterTextKey: 'DB', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: 'db(A)', calculation: "" });

    this.docGen.specItems.push({ name: 'MaxPressDrop', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.processConditions.maximumPressureDrop.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'MaxPressDropUnit', type: 'Unit', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.processConditionsComponent.pressure_Drop_Unit == 'KPA' ? 'kPa' : 'Psi', calculation: "" });

    this.docGen.specItems.push({ name: 'Type', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.unitOptions.nomenclature.toString().substr(0, 5), calculation: "" });
    this.docGen.specItems.push({ name: 'SteamCvKv', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.steamCvKv.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'SplitRange', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.processConditions.sizeCvSplitRangeOnly ? "1" : "0", calculation: "" });
    this.docGen.specItems.push({ name: 'CondensateCvKv', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.condensateValveKv, calculation: "" });
    this.docGen.specItems.push({ name: 'PlateType', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.heatExchangePlateType + data.selectedUnit.heFrameType, calculation: "" });
    this.docGen.specItems.push({ name: 'HexPlateCount', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.numberOfPlates, calculation: "" });
    this.docGen.specItems.push({ name: 'HexChannelType', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.channelTypeSide1, calculation: "" });

    if (globalVariance !== 'AS/UL') {
      this.docGen.specItems.push({ name: 'SteamCvSize', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.controlValveSizeDn, calculation: "" });
      this.docGen.specItems.push({ name: 'CondensateCvSize', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.condensateValveSizeDn, calculation: "" });
    } else {
      this.docGen.specItems.push({ name: 'SteamCvSize', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.controlValveSizeAnsi, calculation: "" });
      this.docGen.specItems.push({ name: 'CondensateCvSize', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.condensateValveSizeAnsi, calculation: "" });
    }

    this.docGen.specItems.push({ name: 'InstallCountryGateway', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.processConditionsComponent.countryTranslatedName, calculation: "" });
    this.docGen.specItems.push({ name: 'GatewayModelCode', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.processConditionsComponent.gatewayInfo.modelCode, calculation: "" });
    this.docGen.specItems.push({ name: 'GatewayNomenclatureCode', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.processConditionsComponent.gatewayInfo.nomenclatureCode, calculation: "" });
    this.docGen.specItems.push({ name: 'GatewayCountryCode', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: this.processConditionsComponent.gatewayInfo.countryCode, calculation: "" });
    

    // Mechanical Options
    const act = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("Actuator"));
    let ActuationType = this.translationService.getEnumerationMasterKeyText(act.EnumerationName, act.SelectedValue);
    this.docGen.specItems.push({ name: 'ActuationType', type: 'Section', masterTextKey: ActuationType, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: this.translatePipe.transform(ActuationType, false), calculation: "" });

    const hiLmtAct = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("HighLimit"));
    let HighLimitActuation = this.translationService.getEnumerationMasterKeyText(hiLmtAct.EnumerationName, hiLmtAct.SelectedValue);
    this.docGen.specItems.push({ name: 'HighLimitActuation', type: 'Section', masterTextKey: HighLimitActuation, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: this.translatePipe.transform(HighLimitActuation, false), calculation: "" });

    const gst = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("GasketMaterial"));
    let Gasket = this.translationService.getEnumerationMasterKeyText(gst.EnumerationName, gst.SelectedValue);
    this.docGen.specItems.push({ name: 'Gasket', type: 'Section', masterTextKey: Gasket, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: Gasket, calculation: "" });

    const isol = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("ManualIsolation"));
    let Isolation = this.translationService.getEnumerationMasterKeyText(isol.EnumerationName, isol.SelectedValue);
    this.docGen.specItems.push({ name: 'Isolation', type: 'Section', masterTextKey: Isolation, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: this.translatePipe.transform(Isolation, false), calculation: "" });

    this.docGen.specItems.push({ name: 'JackingWheels', type: 'Section', masterTextKey: '', sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: "N"/*this.jacking_Wheels_Enumeration.value*/, calculation: "" });
    this.docGen.specItems.push({ name: 'EN12828SafetyCompliant', type: 'Section', masterTextKey: '', sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: "N"/*this.en12828_Compliant_Enumeration.value*/, calculation: "" });

    this.docGen.specItems.push({ name: 'ServiceOffering', type: 'Section', masterTextKey: '', sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.pricingOptions.serviceOfferingFieldLabel, calculation: "" });

    // Panel Options
    let EnergyMonitoring = "NONE";//this.translationService.getEnumerationMasterKeyText(this.hashTable.find(m => m.name === type).energy_Monitoring, this.energy_Monitoring_Enum.internalValue);
    this.docGen.specItems.push({ name: 'EnergyMonitoring', type: 'Section', masterTextKey: EnergyMonitoring, sectionName: 'Panel Options', targetLanguage: this.specSheetLanguage, value: this.translatePipe.transform(EnergyMonitoring, false), calculation: "" });

    let RemoteAccess = "NONE";//this.translationService.getEnumerationMasterKeyText(this.hashTable.find(m => m.name === type).remote_Access, this.remote_Access_Enum.internalValue);
    this.docGen.specItems.push({ name: 'RemoteAccess', type: 'Section', masterTextKey: RemoteAccess, sectionName: 'Panel Options', targetLanguage: this.specSheetLanguage, value: this.translatePipe.transform(RemoteAccess, false), calculation: "" });

    let PanelType = "SIMS_PANEL_230V_EU1";// this.translationService.getEnumerationMasterKeyText(this.hashTable.find(m => m.name === type).control_System, this.control_System_Enum.internalValue);
    this.docGen.specItems.push({ name: 'PanelType', type: 'Section', masterTextKey: PanelType, sectionName: 'Panel Options', targetLanguage: this.specSheetLanguage, value: this.translatePipe.transform(PanelType, false), calculation: "" });

    // product
    this.docGen.specItems.push({
      name: 'EasiHeatUnit', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.unitOptions.nomenclature, calculation: ""
    });
    this.docGen.specItems.push({ name: 'TrimType', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.trimType, calculation: "" });
    this.docGen.specItems.push({ name: 'ActualNoiseLevel', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.steamCvCalculatedNoiseLevel.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'Length', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.lenght, calculation: "" });
    this.docGen.specItems.push({ name: 'Width', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.width, calculation: "" });
    this.docGen.specItems.push({ name: 'Height', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.height, calculation: "" });
    this.docGen.specItems.push({ name: 'Weight', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.weight, calculation: "" });
    this.docGen.specItems.push({ name: 'LengthUnit', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: this.lengthPrefUnit, calculation: "" });
    this.docGen.specItems.push({ name: 'WidthUnit', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: this.lengthPrefUnit, calculation: "" });
    this.docGen.specItems.push({ name: 'HeightUnit', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: this.lengthPrefUnit, calculation: "" });
    this.docGen.specItems.push({ name: 'WeightUnit', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: this.weightPrefUnit, calculation: "" });
    this.docGen.specItems.push({ name: 'EasiHeatSizesHeader', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: "EasiHeat Sizes", calculation: "" });
    this.docGen.specItems.push({ name: 'HeatExchangerDetailsHeader', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: "HeatExchanger Details", calculation: "" });


    if (globalVariance !== 'AS/UL') {
      this.docGen.specItems.push({ name: 'PackageInletConnSize', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.packageInletConnectionSize, calculation: "" });
      this.docGen.specItems.push({ name: 'CondensatePipeSize', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.condensatePipeSize, calculation: "" });
      this.docGen.specItems.push({ name: 'SecondaryInOutPipeSize', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.secondaryInletOutletPipeSize, calculation: "" });

      this.docGen.specItems.push({ name: 'ControlValveSize', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.controlValveSizeDn, calculation: "" });
    }
    else {
      this.docGen.specItems.push({ name: 'PackageInletConnSize', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.packageInletConnectionSize, calculation: "" });
      this.docGen.specItems.push({ name: 'CondensatePipeSize', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.condensatePipeSize, calculation: "" });
      this.docGen.specItems.push({ name: 'SecondaryInOutPipeSize', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.secondaryInletOutletPipeSize, calculation: "" });

      this.docGen.specItems.push({ name: 'ControlValveSize', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.controlValveSizeAnsi, calculation: "" });
    }

    this.docGen.specItems.push({ name: 'ActualSecondaryPressureDrop', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.actualSecondaryPressureDrop, calculation: "" });
    this.docGen.specItems.push({ name: 'ActualSecondaryPressureDropUnit', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: this.resultsComponent.actualSecondaryPressureDropUnit, calculation: "" });
    this.docGen.specItems.push({ name: 'NumberOfPlates', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.numberOfPlates, calculation: "" });
    this.docGen.specItems.push({ name: 'MaximumSecondaryPressure', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: globalVariance == 'AS/UL' || this.pressureRef.preference.value == '45' ? '130' : '9', calculation: "" });
    this.docGen.specItems.push({ name: 'MaximumSecondaryTemperature', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: globalVariance == 'AS/UL' || this.temperatureRef.preference.value == '147' ? '220' : '105', calculation: "" });
    this.docGen.specItems.push({ name: 'MaximumSecondaryPressureUnit', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: globalVariance == 'AS/UL' || this.pressureRef.preference.value == '45' ? this.translatePipe.transform('PSI_GAUGE') : this.translatePipe.transform('BAR_GAUGE'), calculation: "" });
    this.docGen.specItems.push({ name: 'MaximumSecondaryTemperatureUnit', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: globalVariance == 'AS/UL' || this.temperatureRef.preference.value == '147' ? String.fromCharCode(176) + 'F' : String.fromCharCode(176) + 'C', calculation: "" });
    if (!applicationTypeFullName.includes('Steam')) {
      this.docGen.specItems.push({ name: 'Maximum Condensate Temperature', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.maximumCondensateTemperature, calculation: "" });
    } else {
      this.docGen.specItems.push({ name: 'Maximum Condensate Temperature', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: ' - ', calculation: "" });
    }
    this.docGen.specItems.push({ name: 'PrimarySideMaxDesignPressure', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: this.processConditionsComponent.PrimarySideMaxDesignPressure.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'PrimarySideMaxOperatingPressure', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: this.processConditionsComponent.PrimarySideMaxOperatingPressure.toString(), calculation: "" });
    this.docGen.specItems.push({ name: 'PrimarySideMaxPressureUnit', type: 'Section', masterTextKey: '', sectionName: 'Product', targetLanguage: this.specSheetLanguage, value: globalVariance == 'AS/UL' || this.pressureRef.preference.value == '45' ? this.translatePipe.transform('PSI_GAUGE', false) : this.translatePipe.transform('BAR_GAUGE', false), calculation: "" });

    let GlobalVariance = globalVariance; //this.translationService.getEnumerationMasterKeyText(gv.EnumerationName, gv.SelectedValue);
    this.docGen.specItems.push({ name: 'GlobalVariance', type: 'Section', masterTextKey: GlobalVariance, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: GlobalVariance, calculation: "" });


    let Actuator = act.SelectedValue;//this.translationService.getEnumerationMasterKeyText(act.EnumerationName, act.SelectedValue);
    this.docGen.specItems.push({ name: 'Actuator', type: 'Section', masterTextKey: Actuator, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: Actuator, calculation: "" });

    const cr = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("CondensateRemoval"));
    let CondensateRemoval = cr.SelectedValue;//this.translationService.getEnumerationMasterKeyText(cr.EnumerationName, cr.SelectedValue);
    this.docGen.specItems.push({ name: 'CondensateRemoval', type: 'Section', masterTextKey: CondensateRemoval, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: CondensateRemoval, calculation: "" });

    const hl = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("HighLimit"));
    let HighLimit = hl.SelectedValue; //this.translationService.getEnumerationMasterKeyText(hl.EnumerationName, hl.SelectedValue);
    this.docGen.specItems.push({ name: 'HighLimit', type: 'Section', masterTextKey: HighLimit, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: HighLimit, calculation: "" });

    const mi = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("ManualIsolation"));
    let ManualIsolation = mi.SelectedValue; //this.translationService.getEnumerationMasterKeyText(mi.EnumerationName, mi.SelectedValue);
    this.docGen.specItems.push({ name: 'ManualIsolation', type: 'Section', masterTextKey: ManualIsolation, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: ManualIsolation, calculation: "" });

    const sr = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("SystemRecirculation"));
    let SystemRecirculation = sr.SelectedValue; //this.translationService.getEnumerationMasterKeyText(sr.EnumerationName, sr.SelectedValue);
    this.docGen.specItems.push({ name: 'SystemRecirculation', type: 'Section', masterTextKey: SystemRecirculation, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: SystemRecirculation, calculation: "" });

    const cs = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("ControlSystem"));
    let ControlSystem = cs.SelectedValue; //this.translationService.getEnumerationMasterKeyText(cs.EnumerationName, cs.SelectedValue);
    this.docGen.specItems.push({ name: 'ControlSystem', type: 'Section', masterTextKey: SystemRecirculation, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: ControlSystem, calculation: "" });

    const ft = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("FrameType"));
    let FrameType = ft.SelectedValue; //this.translationService.getEnumerationMasterKeyText(ft.EnumerationName, ft.SelectedValue);
    this.docGen.specItems.push({ name: 'FrameType', type: 'Section', masterTextKey: FrameType, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: FrameType, calculation: "" });

    const ps = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("PackageSupports"));
    let PackageSupports = ps.SelectedValue; //this.translationService.getEnumerationMasterKeyText(ps.EnumerationName, ps.SelectedValue);
    this.docGen.specItems.push({ name: 'PackageSupports', type: 'Section', masterTextKey: PackageSupports, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: PackageSupports, calculation: "" });

    const cpl = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("PanelLocation"));
    let ControlPanelLocation = cpl.SelectedValue; //this.translationService.getEnumerationMasterKeyText(cpl.EnumerationName, cpl.SelectedValue);
    this.docGen.specItems.push({ name: 'ControlPanelLocation', type: 'Section', masterTextKey: ControlPanelLocation, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: ControlPanelLocation, calculation: "" });

    const ex = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("Extras"));
    let Gauges = "YES" // "GAUGE_PACK" // this.translationService.getEnumerationMasterKeyText(this.hashTable.find(m => m.name === type).Gauges, this.Gauges_Enum.internalValue);
    this.docGen.specItems.push({ name: 'Gauges', type: 'Section', masterTextKey: Gauges, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: this.translatePipe.transform(Gauges, false), calculation: "" });
    // Replaced by:
    let Extras = ex.SelectedValue; //this.translationService.getEnumerationMasterKeyText(ex.EnumerationName, ex.SelectedValue);
    this.docGen.specItems.push({ name: 'Extras', type: 'Section', masterTextKey: Extras, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: Extras, calculation: "" });

    const it = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("IntegrityTest"));
    let IntegrityTest = it.SelectedValue; //this.translationService.getEnumerationMasterKeyText(it.EnumerationName, it.SelectedValue);
    this.docGen.specItems.push({ name: 'IntegrityTest', type: 'Section', masterTextKey: IntegrityTest, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: IntegrityTest, calculation: "" });

    const dc = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("DataCollection"));
    let DataCollection = dc.SelectedValue; //this.translationService.getEnumerationMasterKeyText(dc.EnumerationName, dc.SelectedValue);
    this.docGen.specItems.push({ name: 'DataCollection', type: 'Section', masterTextKey: DataCollection, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: DataCollection, calculation: "" });

    let ServiceOffering = "1_YEAR_EXTENDED_WARRANTY" // this.translationService.getEnumerationMasterKeyText(this.hashTable.find(m => m.name === type).ServiceOffering, this.ServiceOffering_Enum.internalValue);
    this.docGen.specItems.push({ name: 'ServiceOffering', type: 'Section', masterTextKey: ServiceOffering, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: this.translatePipe.transform(ServiceOffering, false), calculation: "" });

    const gm = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("GasketMaterial"));
    let GasketMaterial = gm.SelectedValue; //this.translationService.getEnumerationMasterKeyText(gm.EnumerationName, gm.SelectedValue);
    this.docGen.specItems.push({ name: 'GasketMaterial', type: 'Section', masterTextKey: GasketMaterial, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: GasketMaterial, calculation: "" });

    const cvf = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("ControlValveFeedback"));
    let ControlValveFeedback = cvf.SelectedValue; //this.translationService.getEnumerationMasterKeyText(cvf.EnumerationName, cvf.SelectedValue);
    this.docGen.specItems.push({ name: 'ControlValveFeedback', type: 'Section', masterTextKey: ControlValveFeedback, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: ControlValveFeedback, calculation: "" });

    const ta11 = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("TA11"));
    let TA11 = ta11.SelectedValue; //this.translationService.getEnumerationMasterKeyText(ta11.EnumerationName, ta11.SelectedValue);
    this.docGen.specItems.push({ name: 'TA11', type: 'Section', masterTextKey: TA11, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: TA11, calculation: "" });

    const ta31 = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("TA31"));
    let TA31 = ta31.SelectedValue; //this.translationService.getEnumerationMasterKeyText(ta31.EnumerationName, ta31.SelectedValue);
    this.docGen.specItems.push({ name: 'TA31', type: 'Section', masterTextKey: TA31, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: TA31, calculation: "" });

    const ta41 = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("TA41"));
    let TA41 = ta41.SelectedValue; //this.translationService.getEnumerationMasterKeyText(ta41.EnumerationName, ta41.SelectedValue);
    this.docGen.specItems.push({ name: 'TA41', type: 'Section', masterTextKey: TA41, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: TA41, calculation: "" });

    const ta01 = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("TA01"));
    let TA01 = ta01.SelectedValue; //this.translationService.getEnumerationMasterKeyText(TA01.EnumerationName, TA01.SelectedValue);
    this.docGen.specItems.push({ name: 'TA01', type: 'Section', masterTextKey: TA01, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: TA01, calculation: "" });

    const pa31 = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("PA31"));
    let PA31 = pa31.SelectedValue; //this.translationService.getEnumerationMasterKeyText(pa31.EnumerationName, pa31.SelectedValue);
    this.docGen.specItems.push({ name: 'PA31', type: 'Section', masterTextKey: PA31, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: PA31, calculation: "" });

    const fa11 = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("FA11"));
    let FA11 = fa11.SelectedValue; //this.translationService.getEnumerationMasterKeyText(fa11.EnumerationName, fa11.SelectedValue);
    this.docGen.specItems.push({ name: 'FA11', type: 'Section', masterTextKey: FA11, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: FA11, calculation: "" });

    const ups = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("UPS"));
    let UPS = ups.SelectedValue; //this.translationService.getEnumerationMasterKeyText(ups.EnumerationName, ups.SelectedValue);
     this.docGen.specItems.push({ name: 'UPS', type: 'Section', masterTextKey: UPS, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: UPS, calculation: "" });

    const comm = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("Communications"));
    let Communications = comm.SelectedValue; //this.translationService.getEnumerationMasterKeyText(ups.EnumerationName, ups.SelectedValue);
    this.docGen.specItems.push({ name: 'Communications', type: 'Section', masterTextKey: UPS, sectionName: 'Mechanical Options', targetLanguage: this.specSheetLanguage, value: Communications, calculation: "" });

    const ins = data.selectedUnitOptions.find(opt => opt.EnumerationName.includes("Insulation"));
    let Insulation = ins.SelectedValue;
    this.docGen.specItems.push({ name: 'Insulation', type: 'Section', masterTextKey: '', sectionName: 'Process Data', targetLanguage: this.specSheetLanguage, value: Insulation, calculation: "" });

    
   
    if (this.sizingModuleForm.get('processConditions.performEnergySavingCalc').value === true) {
      this.docGen.specItems.push({ name: 'HoursOfOperation', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.hoursOfOperation.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'DiversityFactor', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.diversityFactor.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'CostOfCarbon', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.costOfCarbon.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'EnergyUnitCost', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.energyUnitCost.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'WaterUnitCost', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.waterUnitCost.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'WaterTreatmentUnitCost', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.waterTreatmentUnitCost.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'HotwellTemperature', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.hotwellTemperature.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'BoilerEfficiency', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.boilerEfficiency.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'BoilerPressure', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.boilerPressure.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'FuelType', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.processConditions.fuelType.toString(), calculation: "" });

      this.docGen.specItems.push({ name: 'Co2eEmmissionSavingsPerYear', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: (+data.selectedUnit.energySavings.co2eEmmissionReductionPerYear).toFixed(0), calculation: "" });
      this.docGen.specItems.push({ name: 'Co2eEmmissionCostReductionPerYear', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: (+data.selectedUnit.energySavings.co2eEmmissionCostReductionPerYear).toFixed(0), calculation: "" });
      this.docGen.specItems.push({ name: 'EnergySavedPerYear', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: (+data.selectedUnit.energySavings.energySavedPerYear).toFixed(0), calculation: "" });
      this.docGen.specItems.push({ name: 'EnergyCostReductionPerYear', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: (+data.selectedUnit.energySavings.energyCostReductionPerYear).toFixed(0), calculation: "" });
      this.docGen.specItems.push({ name: 'WaterSavedPerYear', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: (+data.selectedUnit.energySavings.waterSavedPerYear).toFixed(0), calculation: "" });
      this.docGen.specItems.push({ name: 'WaterCostReductionPerYear', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: (+data.selectedUnit.energySavings.waterCostReductionPerYear).toFixed(0), calculation: "" });
      this.docGen.specItems.push({ name: 'SteamSavingsPercent', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: (+data.selectedUnit.energySavings.steamConsumptionReductionPercent).toFixed(0), calculation: "" });
      this.docGen.specItems.push({ name: 'SteamMassSaved', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: (+data.selectedUnit.energySavings.steamMassSaved).toFixed(0), calculation: "" });
      this.docGen.specItems.push({ name: 'TotalEvcCostReductionPerYear', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: (+data.selectedUnit.energySavings.totalEvcCostReductionPerYear).toFixed(0), calculation: "" });
      this.docGen.specItems.push({ name: 'UnitType', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.selectedUnit.energySavings.unitType.toString(), calculation: "" });
      let unitTypeHeaderIndex = data.selectedUnit.unitOptions.nomenclature.toString().substr(0, 5).includes('SC') ? 0 : 1;
      this.docGen.specItems.push({ name: 'UnitControlEnergySavingHeader', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: this.resultsComponent.unitTypeHeaderName[unitTypeHeaderIndex] + ' ' + this.translatePipe.transform('SUSTAINABILITY_SAVINGS', false) + ' ' + this.translatePipe.transform('SCORE_CARD', false), calculation: "" });
      this.docGen.specItems.push({ name: 'Currency', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: this.sellingCurrencySymbol.toString(), calculation: "" });

      //units
      this.docGen.specItems.push({ name: 'VolumeUnit', type: 'Unit', masterTextKey: this.volumeUnitsRef.preference.masterTextKey, sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: this.volumeUnitsRef.preference.unitName, calculation: "" });
      this.docGen.specItems.push({ name: 'EnergyUnit', type: 'Unit', masterTextKey: this.energyUnitsRef.preference.masterTextKey, sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: this.energyUnitsRef.preference.unitName, calculation: "" });
      this.docGen.specItems.push({ name: 'EmissionUnit', type: 'Unit', masterTextKey: this.emissionUnitsRef.preference.masterTextKey, sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: this.emissionUnitsRef.preference.unitName, calculation: "" });

      //Feul Info
      this.docGen.specItems.push({ name: 'CalorificValue', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.energySavingFuelInfo.fuelCalorificValue.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'EmissionValueCO2e', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.energySavingFuelInfo.fuelCO2eEmissions.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'CalorificValueUnit', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.energySavingFuelInfo.fuelCalorificValueUnit.toString(), calculation: "" });
      this.docGen.specItems.push({ name: 'EmissionValueCO2eUnit', type: 'Section', masterTextKey: '', sectionName: 'Energy Saving', targetLanguage: this.specSheetLanguage, value: data.energySavingFuelInfo.fuelCO2eEmmissionUnit.toString(), calculation: "" });


    }

    // Messages - Sizing Grid Row message and UI options advisory messages
    let messageMasterTextKey: string = '';
    let messageFullTranslatedKey: string = '';
    let messageValue: string = '';
    let messageUnitKey: string = '';

    let lastMessageIndex = 0;

    for (var i = 0; i < 10; i++) {
      messageMasterTextKey = ''; // reset for each message.
      messageFullTranslatedKey = '';
      messageUnitKey = '';
      messageValue = '';

      // Row messages
      if (!!this.messagesService.allMessages) {

        let filteredMessages = this.messagesService.allMessages.filter(msg => msg.eH_Gen4_Unit.includes(data.selectedUnit.unitOptions.nomenclature.toString().substr(0, 5)));

        if (!!filteredMessages[0] && !!filteredMessages[i]) {
          messageMasterTextKey = filteredMessages[i].messageKey;
          messageFullTranslatedKey = filteredMessages[i].eH_Gen4_Unit + this.translatePipe.transform(messageMasterTextKey, false);
          messageUnitKey = filteredMessages[i].unitKey;
          messageValue = this.localizeValue(filteredMessages[i].value, this.pressureRef.preference.decimalPlaces); // Default to this decimal place preference
          lastMessageIndex = i;



          this.docGen.specItems.push({ name: 'MESSAGE' + (i + 1).toString(), type: 'Section', masterTextKey: messageMasterTextKey, sectionName: 'Messages', targetLanguage: this.specSheetLanguage, value: messageFullTranslatedKey, calculation: "" });

          if (!!messageValue && !!messageUnitKey) {
            this.docGen.specItems.push({ name: 'MessageValue' + (i + 1).toString(), type: 'Section', masterTextKey: messageValue, sectionName: 'Messages', targetLanguage: this.specSheetLanguage, value: messageValue, calculation: "" });
            this.docGen.specItems.push({ name: 'UnitKey' + (i + 1).toString(), type: 'Section', masterTextKey: messageUnitKey, sectionName: 'Messages', targetLanguage: this.specSheetLanguage, value: messageUnitKey, calculation: "" });
          }

        }
      }
    }
  }

  /**
 Method to localize values.
*/
  localizeValue(value: any, decimalPoints: number) {
    return this.localeService.formatDecimal(value.toFixed(decimalPoints));
  }

  goDownToResults() {
    document.getElementById("sizingResults").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  onExcelSubmit() {

    swal({
      closeOnClickOutside: false, closeOnEsc: false,
      title: "Disabled",
      text: "Button disabled. Please click 'PDF' for specification sheet.",
      dangerMode: true,
      //buttons: ['Ok', 'Cancel']
    }).then((okbuttoncClicked?: boolean) => {

      console.info("Ok clicked...");

      // The parameter can also enter as null
      const returnVal = !(okbuttoncClicked === null);

    });

  }

  onGetTiSheet() {
    // Intentional call to the debugger to force poke debugging for PRS devs!

    // ToDo: Drive parameters for real CSG Ti documents.
    // There is a CORS issue pulling file from another domain!
    // We have to pull down the PDF file using the WebAPI then render to the client like a spec sheet.

    let tiRequestModel: TiRequestModel = new TiRequestModel();
    tiRequestModel.languageId = this.user.languageId; // The required Imi language, defaults to languageId=4 ('en') if Imi language not defined/found in the Imi table.
    tiRequestModel.moduleId = 13; // EHgen4 ModuleId is 13 (child, Product Sizing), moduleGroupId=16 (parent)
    tiRequestModel.languageId = -1; // not supported yet, will get default Ti language, normally 'en'

    //ToDo: Drive selected Unit/model correctly.
    //Don't forget to update/add the data for links to the public PDFs, located in
    //...\SXS.SizingSuite.Web.Reports\TechnicalInformation.json and rebuild to bin folder.
    //Disable browser popup blocking!
    debugger;
    tiRequestModel.code = "EH_DHW_SC";// Unit name selected
    tiRequestModel.params = "EH_DHW_SC_TI"; // Ti selection

    let trans_Ti_Error = this.translatePipe.transform('TI_ERROR', true);
    let trans_Ti_Information = this.translatePipe.transform('TI_INFORMATION', true);
    let trans_Ti_Failed_To_Download = this.translatePipe.transform('TI_FAILED_TO_DOWNLOAD', true);
    let trans_Ti_The_Product_Technical_Information_sheet = this.translatePipe.transform('TI_THE_PRODUCT_TECHNICAL_INFORMATION_SHEET', true);
    let trans_Ti_missing = this.translatePipe.transform('TI_THE_PRODUCT_TECHNICAL_INFORMATION_SHEET_IS_UNAVAILABLE_OR_MISSING', true);

    // Get Ti url/path
    this.docGenService.getTiDocumentInfo(tiRequestModel).subscribe((response: TiDocumentInfo[]) => {

      // ToDo: Better manage multiple Ti documents returned?
      if (!!response && response.length > 0) {

        response.forEach(ti => {
          // Get first Ti url from result.
          let tiUrlPath = ti.tiPath + ti.tiFileName;// pdf

          if (!!tiUrlPath && tiUrlPath.length > 0) {
            window.open(tiUrlPath, "_blank");
          }
          else {
            swal({
              closeOnClickOutside: false, closeOnEsc: false,
              title: trans_Ti_Error + ':',
              text: trans_Ti_The_Product_Technical_Information_sheet + ' "' + ti.tiPath + ti.tiFileName + '" ' + trans_Ti_Failed_To_Download + '.',
              icon: "error",
              dangerMode: false,
              //buttons: ['Ok', 'Cancel']
            }).then((okbuttoncClicked?: boolean) => {
              console.info("Ok clicked...");
              // The parameter can also enter as null
              const returnVal = !(okbuttoncClicked === null);
            }); // OF SWAL
          } // end of if (!!tiUrlPath)

        }); // end of foreach

      } // end of if
      else {
        // Notify UI, Ti missing/unavailable.
        // ToDo : Translation support?
        swal({
          title: trans_Ti_Information + ':',
          text: trans_Ti_missing + '.',
          icon: "warning",
          dangerMode: false,
          //buttons: ['Ok', 'Cancel']
        }).then((okbuttoncClicked?: boolean) => {
          console.info("Ok clicked...");
          // The parameter can also enter as null
          const returnVal = !(okbuttoncClicked === null);
        });

      }

    }); // end of getTiDocumentInfo() Subscribe

  }

  onGetImiSheet() {
    // ToDo: Drive parameters for real CSG Imi documents.
    // There is a CORS issue pulling file from annother domain!
    // We have to pull down the PDF file using the WebAPI then render to the client like a spec sheet.

    let tiRequestModel: TiRequestModel = new TiRequestModel();
    tiRequestModel.languageId = this.user.languageId; // The required Imi language, defaults to languageId=4 ('en') if Imi language not defined/found in the Imi table.
    tiRequestModel.moduleId = 13; // EHgen4 ModuleId is 13 (child, Product Sizing), moduleGroupId=16 (parent)
    tiRequestModel.languageId = -1; // not supported yet, will get default Ti language, normally 'en'

    //ToDo: Drive selected Unit/model correctly.
    //Don't forget to update/add the data for links to the public PDFs, located in
    //...\SXS.SizingSuite.Web.Reports\TechnicalInformation.json and rebuild to bin folder.
    //Disable browser popup blocking!
    debugger;
    tiRequestModel.code = "EH_DHW_SC";// Unit name selected
    tiRequestModel.params = "EH_DHW_SC_IM"; // IMI selection

    let trans_Imi_Error = this.translatePipe.transform('IMI_ERROR', true);
    let trans_Imi_Information = this.translatePipe.transform('IMI_INFORMATION', true);
    let trans_Imi_Failed_To_Download = this.translatePipe.transform('IMI_FAILED_TO_DOWNLOAD', true);
    let trans_Imi_The_Product_Technical_Information_sheet = this.translatePipe.transform('IMI_THE_PRODUCT_IMI_SHEET', true);
    let trans_Imi_missing = this.translatePipe.transform('IMI_THE_PRODUCT_IMI_SHEET_IS_UNAVAILABLE_OR_MISSING', true);

    // Get Imi url/path
    this.docGenService.getTiDocumentInfo(tiRequestModel).subscribe((response: TiDocumentInfo[]) => {

      // ToDo: Better manage multiple Imi documents returned?
      if (!!response && response.length > 0) {

        response.forEach(ti => {
          // Get first Imi url from result.
          let tiUrlPath = ti.tiPath + ti.tiFileName;// pdf

          if (!!tiUrlPath && tiUrlPath.length > 0) {
            window.open(tiUrlPath, "_blank");
          }
          else {
            swal({
              closeOnClickOutside: false, closeOnEsc: false,
              title: trans_Imi_Error + ':',
              text: trans_Imi_The_Product_Technical_Information_sheet + ' "' + ti.tiPath + ti.tiFileName + '" ' + trans_Imi_Failed_To_Download + '.',
              icon: "error",
              dangerMode: false,
              //buttons: ['Ok', 'Cancel']
            }).then((okbuttoncClicked?: boolean) => {
              console.info("Ok clicked...");
              // The parameter can also enter as null
              const returnVal = !(okbuttoncClicked === null);
            }); // OF SWAL
          } // end of if (!!tiUrlPath)

        }); // end of foreach

      } // end of if
      else {
        // Notify UI, Imi missing/unavailable.
        // ToDo : Translation support?
        swal({
          title: trans_Imi_Information + ':',
          text: trans_Imi_missing + '.',
          icon: "warning",
          dangerMode: false,
          //buttons: ['Ok', 'Cancel']
        }).then((okbuttoncClicked?: boolean) => {
          console.info("Ok clicked...");
          // The parameter can also enter as null
          const returnVal = !(okbuttoncClicked === null);
        });

      }

    }); // end of getImiDocumentInfo() Subscribe

  }

  // If crm export is clicked then this can only happen if nothing has been chenged since the last save.
  // If unsaved changes exist then display a dialog to indicate CRM export is not possible.  
  onCheckCrmExportValid() {

    let transUnableToEportCrm = this.translatePipe.transform('CHANGES_HAVE_NOT_BEEN_SAVED_CRM_EXPORT_NOT_AVAILABLE', false);
    let transInfo = this.translatePipe.transform('INFO', false);

    if (this.sizingModuleForm.touched) {
      // Simple popup message box
      swal({
        closeOnClickOutside: false, closeOnEsc: false,
        title: transInfo,
        text: transUnableToEportCrm,
        icon: "info",
        dangerMode: true,
      }).then((okButtonClicked?: boolean) => {
        return false;
      });
    }
    else {
      return true;
    }
  }

  createModulePreferencesModel() {

    //In the future re-visit this section and try something more !!! CLEVER !!!
    //Try to play with this:
    //Object.entries(this.modPref).forEach(([key, value]) => /* instead of console bit try to assign values to value based on key*/ console.log(`${key}: ${value}`))
    // or this:
    //for (const [k, v] of Object.entries(modPref)) {
    //k  // Type is string
    //v // Type is any
    // }
    this.modPref = {
      EHgen4_Commission: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_Commission').value,
      EHgen4_CurrencyConversion: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_CurrencyConversion').value,
      EHgen4_CVSplitRange: Boolean(JSON.parse(this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_CVSplitRange').value)),
      EHgen4_DeliveryCost: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_DeliveryCost').value,
      EHgen4_HEAllowHighDesignPressure: Boolean(JSON.parse(this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_HEAllowHighDesignPressure').value)),
      //EHgen4_EHOptionNames: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_EHOptionNames').value,
      //EHgen4_EHOptions: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_EHOptions').value,
      EHgen4_HEM10Discount: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_HEM10Discount').value,
      EHgen4_HEM6Discount: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_HEM10Discount').value,
      EHgen4_HEOverridenP251Markup: Boolean(JSON.parse(this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_HEOverridenP251Markup').value)),
      EHgen4_HEOverridenP251MarkupValue: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_HEOverridenP251MarkupValue').value,
      EHgen4_HEPricingCurrency: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_HEPricingCurrency').value,
      EHgen4_HETS6Discount: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_HETS6Discount').value,
      EHgen4_HideHEmodelFromSizing: Boolean(JSON.parse(this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_HideHEmodelFromSizing').value)),
      EHgen4_LandCostIncrease: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_LandCostIncrease').value,
      EHgen4_Manufacturer: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_Manufacturer').value,
      EHgen4_ManufacturerCurrency: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_ManufacturerCurrency').value,
      EHgen4_MotiveInletPressureAvailable: Boolean(JSON.parse(this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_MotiveInletPressureAvailable').value)),
      EHgen4_PrvAllowed: Boolean(JSON.parse(this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_PrvAllowed').value)),
      EHgen4_SellingCurrency: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_SellingCurrency').value,
      EHgen4_SellingMarkup: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_SellingMarkup').value,
      EHgen4_SellSteamControl: Boolean(JSON.parse(this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_SellSteamControl').value)),
      EHgen4_YearOne: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_YearOne').value,
      EHgen4_YearThree: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_YearThree').value,
      EHgen4_YearTwo: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_YearTwo').value,
      EHgen4_HET10Discount: this.modulePreferenceService.allModulePreferences.find(pref => pref.name == 'EHgen4_HET10Discount').value,
      EHgen4_HideAllPricingUserPref: this.preferenceService.getUserPreferenceByName("EHHideSalesPrice").value === '1',
      EHgen4_HideManufactureCostsUserPref: this.preferenceService.getUserPreferenceByName("EHHideManufactureCosts").value === '1',
      //EHgen4_GasketCode: i'm getting this from translation service enumerations within ngOnInit (at the end of it )
      //EHgen4_GlobalVariance: i'm getting this from translation service enumerations within ngOnInit (at the end of it )
    } as ModulePreferenceModel


    Object.entries(this.modPref).forEach(([key, value]) => console.log("Module Preference Value for " + `${key} is: ${value}`))
  }

  translateUnitsName(unitName: string) {

    switch (unitName) {
      case 'EHDSC':
        this.sizedUnitTypes.push('EH_DHW_SC');
        return;
      case 'EHDDC':
        this.sizedUnitTypes.push('EH_DHW_DC');
        return;
      case 'EHHSC':
        this.sizedUnitTypes.push('EH_HTG_SC');
        return;
      case 'EHHCC':
        this.sizedUnitTypes.push('EH_HTG_CC');
        return;
      case 'EHHDC':
        this.sizedUnitTypes.push('EH_HTG_DC');
        return;
      default:
        this.sizedUnitTypes.push(unitName);
        return;
    }
  }

  // Gasket code in the list isn't the same as that required by Alfa Laval DLL. Change the value to correct one.
  setGasketCode(Gasket: string): string {

    switch (Gasket) {
      case "EPDMP":
      case "G1":
        return "EPDMP";

      case "Heat-seal":
      case "G2":
        return "FKMT";
 
      case "WRAS EPDMW (UK only)":
      case "G3":
        return "EPDMW";
   
    }
  }

  updateGasketOrCondensateAndResize($event) {

    this.resizeUnitIndex = $event.index;
    if ($event.optionName === 'gasketMaterial') {
      this.currentGasketCode = this.setGasketCode($event.value);
    }

    if ($event.optionName === 'condensateRemoval') {
      this.currentCondensateRemoval = $event.value
      this.userCondensate = true;
    }


    this.onCalculateSizing();
  }

  /**
    * Show global sizing messages
    * @param messages sizing messages
    */
  private showSizingMessages(messages: Message[], unit: string) {
    if (unit != null) {
      this.messagesService.addMessageEhGen4(messages.map(message => ({
        messageKey: message.messageKey,
        value: message.value,
        unitKey: message.unitKey,
        severity: +message.severity,
        displayValue: '',
        additionalMessage: '',
        eH_Gen4_Unit: '(' + unit + ') - '
      })));


      this.messagesService.messages.forEach(messageObj => {
        if (messageObj.messageKey == "THE_SOFTWARE_IS_CURRENTY_UNABLE_TO_SIZE_THE_FOLLOWING_METERS_MESSAGE" ||
          messageObj.messageKey == "VIM20_TO_VIEW_A_LINE_SIZE_METER_IN_THE_RESULTS_ENTER_A_FLOW_RATE_GREATER_THAN_MESSAGE") {
          messageObj.additionalMessage = messageObj.value.toString();
        } else {
          messageObj.value = +messageObj.value;
        }
      })
    }
  }

  clearUiMessages() {
    this.messagesService.clearMessages();
  }

  getDataForSpecSheet($event) {
    //if (this.resultsComponent.resultsForm.get(`left.pricingOptions.unitSelected`).value == true || this.resultsComponent.resultsForm.get(`right.pricingOptions.unitSelected`).value == true) {
    //this.jobStatusId = 3;

    this.specificationSheetValuesCollection = new SpecificationSheetValuesCollection();
    this.specificationSheetValuesCollection.processConditions = this.processConditionsComponent.getFormInformation();
    this.specificationSheetValuesCollection.selectedUnit = this.resultsComponent.resultsForm.get(`${$event.side}`).value;
    this.specificationSheetValuesCollection.selectedUnitOptions = this.resultsComponent.getAllUnitOptionsValue($event.side).options;
    this.specificationSheetValuesCollection.energySavingFuelInfo = this.resultsComponent.getFuelInfo();
    this.specificationSheetValuesCollection.specSheetHeaders = this.sizingModuleForm.get('specsheet').value;
    //}
    //else {
    //this.jobStatusId = 2;
    //}
  }

  defaultMaxPressDropValue(): number {
    let value;
    if (this.initialGlobalVariance !== 'AS/UL') {
      value = 25;
    } else {
      value = 5;
    }
    return value;
  }

  convertPressureToBarg(value: number) {
    let pressure: number;

    const unitsToConvert: UnitConvert[] = [];
    unitsToConvert.push({
      propertyName: 'pressure',
      initialValue: value,
      initialUnitId: +this.pressureRef.preference.value,
      targetUnitId: 50, // Barg
      convertedValue: null,
    });

    const unitsConverter: UnitsConverter = {
      unitsConverter: unitsToConvert
    };

    this.unitsService.unitsConverter(unitsConverter).subscribe((unitsConvertedData: UnitsConverter) => {

      if (!!unitsConvertedData) {
        pressure = (unitsConvertedData.unitsConverter.find(u => u.propertyName === 'pressure').convertedValue)
        this.resultsComponent.convertedToBargPressure = +pressure.toFixed(2); // set decimal places

      }
    })
  };

  updateJobStatusId($event) {
    this.jobStatusId = $event.jobStatusId;
    this.sizingModuleForm.markAsTouched();
  }

}

