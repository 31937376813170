import { CommonModule } from '@angular/common';
import { NgModule , ModuleWithProviders} from '@angular/core';
import { SizingSharedLibModule } from 'sizing-shared-lib';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'sizing-shared-lib';
import { UserFeedbackComponent } from './userFeedback.component';

const providers = [];

@NgModule({
  declarations: [
    UserFeedbackComponent    
  ],
  imports: [
    CommonModule,
    SizingSharedLibModule.forRoot(),
    SharedModule,
  ],
  providers: providers,
  bootstrap: [UserFeedbackComponent],
  exports: [UserFeedbackComponent]
})
export class UserFeedbackModule { }

@NgModule({})
export class UserFeedbackSharedModule{
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UserFeedbackModule,
      providers: providers
    }
  }
}
