import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { FormGroupConfig } from './models/FormGroupConfiguration'
import { EnumerationComponent } from "sizing-shared-lib";
import { FeedbackService } from "./userFeedback.service";
import { User } from "../user-profile/user.model";
import { FeedbackFormGroup } from './models/FeedbackForm_FormGroup';
import { IGenericChanges } from '../../../../../sizing-shared-lib/src/lib/modules/generic.changes.interface';

@Component({
  selector: 'userFeedback',
  templateUrl: './userFeedback.component.html',
  styleUrls: ['./userFeedback.component.scss'],
  providers: [FeedbackService]
})
export class UserFeedbackComponent implements OnInit {
  feedbackFormGroup: FormGroup;

  public user: User;
  moduleFilterList: any[] = [];
  showThankyou: boolean = false;
  //theFormGroup: FormGroup; // to drive GenericChangesGuard
  //hasUnsavedDataChanges: boolean; // to drive GenericChangesGuard
  redStatus = ''
  orangeStatus = ''
  yellowStatus = ''
  limeStatus = ''
  greenStatus = ''

  @ViewChild("aboutEnumeration", { static: false }) aboutEnumeration: EnumerationComponent;
  @ViewChild("moduleEnumeration", { static: false }) moduleEnumeration: EnumerationComponent;

  public parentSubmitIsDisabled = true; // Modal parent container component submit button state (enabled/disabled)
  
  showModulesList : boolean = false;
  constructor(
    private fb: FormBuilder,
    private feedbackService: FeedbackService
  ) {

  }

  ngOnInit() {
    this.createFormGroup();
    this.feedbackService.getUserDetails().subscribe(user => {
      this.user = user;
    });

    this.feedbackService.getModuleGroupsForUsers().subscribe(modules => {
      let tempList = [];
      modules.forEach(mod => {
        tempList.push(mod.name);
      });
      this.moduleFilterList = tempList;
    });



    this.setSubmitButtonStatus();
    //this.theFormGroup = this.feedbackFormGroup;  // to drive GenericChangesGuard
  }



  createFormGroup() {
    const config: FormGroupConfig<FeedbackFormGroup> = {
      userId: ['', {
        validators: [Validators.required],
      }],
      feedbackOption: ['', {
        validators: [Validators.required],
        updateOn: 'change'
      }],
      sizingModule: ['', {
        validators: [Validators.nullValidator],
        updateOn: 'change'
      }],
      score: ['', {
        validators: [Validators.required],
        updateOn: 'change'
      }],
      comments: ['', {
        validators: [Validators.nullValidator],
        updateOn: 'change'
      }]
    }

    this.feedbackFormGroup = this.fb.group(config);

    this.feedbackFormGroup.valueChanges.subscribe(val => this.setSubmitButtonStatus());

    return this.feedbackFormGroup;
  }

  recordScore(int) {
    this.feedbackFormGroup.get('userId').setValue(this.user.userId.toString());
    this.feedbackFormGroup.get('score').setValue(int);
    console.log(`User ${this.user.userId.toString()} Scored ${int}`);

    switch (int) {
      case 1:
        this.redStatus = 'redClicked'
        this.orangeStatus = ''
        this.yellowStatus = ''
        this.limeStatus = ''
        this.greenStatus = ''
        break;
      case 2:
        this.redStatus = ''
        this.orangeStatus = 'orangeClicked'
        this.yellowStatus = ''
        this.limeStatus = ''
        this.greenStatus = ''
        break;
      case 3:
        this.redStatus = ''
        this.orangeStatus = ''
        this.yellowStatus = 'yellowClicked'
        this.limeStatus = ''
        this.greenStatus = ''
        break;
      case 4:
        this.redStatus = ''
        this.orangeStatus = ''
        this.yellowStatus = ''
        this.limeStatus = 'limeClicked'
        this.greenStatus = ''
        break;
      case 5:
        this.redStatus = ''
        this.orangeStatus = ''
        this.yellowStatus = ''
        this.limeStatus = ''
        this.greenStatus = 'greenClicked'
        break;
    }
  }

  onEnumerationChanged(optionName, event) {

    if (optionName == 'feedbackOption') {
      this.feedbackFormGroup.get('feedbackOption').patchValue(event.selectedValue);
      if (event.selectedValue == 'AppInGeneral') {
        this.showModulesList = false;
        this.feedbackFormGroup.get('sizingModule').setValidators([Validators.nullValidator]);
        this.feedbackFormGroup.get('sizingModule').patchValue('');
      }
      else if (event.selectedValue == 'SizingModule') {
        this.showModulesList = true;
        this.feedbackFormGroup.get('sizingModule').setValidators([Validators.required]);
        this.feedbackFormGroup.get('sizingModule').patchValue(this.moduleEnumeration.internalValue);
      }
    }
  }

  onSubmit() {
    console.log(this.feedbackFormGroup.value);

    this.feedbackService.saveFeedback(this.feedbackFormGroup.value).subscribe(response => {
      if (response) {

      }

    });
  }



  /*
  * Enable/Disable submit button based on the requird fields validations and projects selection.
  */
  setSubmitButtonStatus() {
    if (this.feedbackFormGroup.valid) {
      this.parentSubmitIsDisabled = false;
    } else {
      this.parentSubmitIsDisabled = true;
    }
  }

  /**
* Support this method is you require Modal Submit button state handling. ie. disable the submit button if any validation errors exist.
*/
  public checkIfSubmitIsDisabled(): boolean {
    return this.parentSubmitIsDisabled;
  }

  modalSubmitCallback() {
    console.log(this.feedbackFormGroup.value);
    let payload = this.feedbackFormGroup.value;
    setTimeout(() => { this.feedbackFormGroup.reset() }, 300);
    this.feedbackService.saveFeedback(payload).subscribe(response => {
      if (response) {
        
      }

    });

    this.showThankyou = true;
  }

}
