import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from "rxjs/Rx";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { User } from "../user-profile/user.model";
import { FeedbackFormGroup } from "./models/FeedbackForm_FormGroup";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private http: HttpClient) { }

  getUserDetails(): Observable<User> {
    return this.http.get<User>("./Api/User/GetUserDetails");
  }

  getModuleGroupsForUsers(): Observable<any[]> {
    return this.http.get<Array<any>>("./Api/Admin/GetModuleGroupsForUser");
  }

  saveFeedback(feedback: FeedbackFormGroup): Observable<boolean> {
    //calculateTotalPrice(): Observable < EHPricingSummaryOutput > {
    return this.http.post<boolean>(`./Api/Admin/SaveUserFeedback`, feedback);
  }


  /**
* Register current user login entry.
*/
  logUserLogin() {
    return this.http.get(`./Api/User/LogUserLogin`);
  }

}

